import React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { ViewOrderScreen } from '../../../../features/account/screens/orders/ViewOrderScreen'

export const Route = createFileRoute('/app/account/orders/view-order/$id')({
  component: () => {
    const { id } = Route.useParams()
    return <ViewOrderScreen id={+id} />
  },
})
