import React, { useLayoutEffect } from 'react'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { LoadingSpinner } from '@edx/react-common'
import { EAnimalType } from '../../../types'

export const Route = createFileRoute('/app/animals/animal/$id')({
  component: Animal,
})

function Animal() {
  const { id } = Route.useParams()
  const navigate = useNavigate()

  // todo: for dev testing
  useLayoutEffect(() => {
    const animalType = id === '123' ? EAnimalType.Horse : EAnimalType.Dog

    if (animalType === EAnimalType.Horse) {
      navigate({
        to: '/app/animals/horses/profile/$id',
        params: { id },
        replace: true,
      })
    } else {
      navigate({
        to: '/app/animals/dogs/profile/$id/$tab',
        params: { id, tab: 'profile' },
        replace: true,
      })
    }
  }, [id])

  return <LoadingSpinner />
}
