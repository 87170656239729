/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
export var AbilityState;
(function (AbilityState) {
    AbilityState["Low"] = "Low";
    AbilityState["Medium"] = "Medium";
    AbilityState["High"] = "High";
})(AbilityState || (AbilityState = {}));
export var AbilityType;
(function (AbilityType) {
    AbilityType["Speed"] = "Speed";
    AbilityType["Gait"] = "Gait";
    AbilityType["Temperament"] = "Temperament";
})(AbilityType || (AbilityType = {}));
export var ActivationCodeStatus;
(function (ActivationCodeStatus) {
    ActivationCodeStatus["Active"] = "Active";
    ActivationCodeStatus["Inactive"] = "Inactive";
    ActivationCodeStatus["Redeemed"] = "Redeemed";
})(ActivationCodeStatus || (ActivationCodeStatus = {}));
export var ActorType;
(function (ActorType) {
    ActorType["Admin"] = "Admin";
    ActorType["PayPal"] = "PayPal";
    ActorType["Stripe"] = "Stripe";
})(ActorType || (ActorType = {}));
export var AdminPermissionOperationType;
(function (AdminPermissionOperationType) {
    AdminPermissionOperationType["Create"] = "Create";
    AdminPermissionOperationType["Read"] = "Read";
    AdminPermissionOperationType["Update"] = "Update";
    AdminPermissionOperationType["Delete"] = "Delete";
})(AdminPermissionOperationType || (AdminPermissionOperationType = {}));
export var AlleleState;
(function (AlleleState) {
    AlleleState["Nn"] = "Nn";
    AlleleState["Xn"] = "Xn";
    AlleleState["Xx"] = "Xx";
    AlleleState["XnOrXx"] = "XnOrXx";
    AlleleState["NotTested"] = "NotTested";
    AlleleState["Inconclusive"] = "Inconclusive";
})(AlleleState || (AlleleState = {}));
export var AnimalProfileType;
(function (AnimalProfileType) {
    AnimalProfileType["Private"] = "Private";
    AnimalProfileType["Public"] = "Public";
})(AnimalProfileType || (AnimalProfileType = {}));
export var AnimalType;
(function (AnimalType) {
    AnimalType["Animal"] = "Animal";
    AnimalType["AssociationAnimal"] = "AssociationAnimal";
    AnimalType["Horse"] = "Horse";
    AnimalType["Dog"] = "Dog";
})(AnimalType || (AnimalType = {}));
export var AssociationPackageStatus;
(function (AssociationPackageStatus) {
    AssociationPackageStatus["Disabled"] = "Disabled";
    AssociationPackageStatus["Active"] = "Active";
    AssociationPackageStatus["Archived"] = "Archived";
})(AssociationPackageStatus || (AssociationPackageStatus = {}));
export var BillingInterval;
(function (BillingInterval) {
    BillingInterval["Day"] = "Day";
    BillingInterval["Week"] = "Week";
    BillingInterval["Month"] = "Month";
    BillingInterval["Year"] = "Year";
})(BillingInterval || (BillingInterval = {}));
export var BooleanOperationType;
(function (BooleanOperationType) {
    BooleanOperationType["And"] = "And";
    BooleanOperationType["Or"] = "Or";
})(BooleanOperationType || (BooleanOperationType = {}));
export var CouponStatus;
(function (CouponStatus) {
    CouponStatus["New"] = "New";
    CouponStatus["Expired"] = "Expired";
    CouponStatus["Disabled"] = "Disabled";
    CouponStatus["Active"] = "Active";
})(CouponStatus || (CouponStatus = {}));
export var DiscountSourceType;
(function (DiscountSourceType) {
    DiscountSourceType["Coupon"] = "Coupon";
    DiscountSourceType["GiftCard"] = "GiftCard";
})(DiscountSourceType || (DiscountSourceType = {}));
export var DiscountType;
(function (DiscountType) {
    DiscountType["Amount"] = "Amount";
    DiscountType["Percentage"] = "Percentage";
})(DiscountType || (DiscountType = {}));
export var DistanceUnit;
(function (DistanceUnit) {
    DistanceUnit["Km"] = "Km";
    DistanceUnit["Mi"] = "Mi";
})(DistanceUnit || (DistanceUnit = {}));
export var DogGender;
(function (DogGender) {
    DogGender["Male"] = "Male";
    DogGender["Female"] = "Female";
})(DogGender || (DogGender = {}));
export var FileType;
(function (FileType) {
    FileType["Pdf"] = "Pdf";
    FileType["Html"] = "Html";
    FileType["Zip"] = "Zip";
    FileType["Xlsx"] = "Xlsx";
})(FileType || (FileType = {}));
export var Gender;
(function (Gender) {
    Gender["Stallion"] = "Stallion";
    Gender["Gelding"] = "Gelding";
    Gender["Mare"] = "Mare";
    Gender["Male"] = "Male";
    Gender["Female"] = "Female";
})(Gender || (Gender = {}));
export var GeneticNoteState;
(function (GeneticNoteState) {
    GeneticNoteState["New"] = "New";
    GeneticNoteState["Current"] = "Current";
})(GeneticNoteState || (GeneticNoteState = {}));
export var GeneticNoteType;
(function (GeneticNoteType) {
    GeneticNoteType["Genotypes"] = "Genotypes";
    GeneticNoteType["Abilities"] = "Abilities";
    GeneticNoteType["Coat"] = "Coat";
    GeneticNoteType["Dangers"] = "Dangers";
    GeneticNoteType["HealthIssues"] = "HealthIssues";
    GeneticNoteType["DangerWithoutProbabilityList"] = "DangerWithoutProbabilityList";
    GeneticNoteType["HealthIssueWithoutProbabilityList"] = "HealthIssueWithoutProbabilityList";
    GeneticNoteType["Traits"] = "Traits";
})(GeneticNoteType || (GeneticNoteType = {}));
export var HeightUnit;
(function (HeightUnit) {
    HeightUnit["Hands"] = "Hands";
    HeightUnit["Meters"] = "Meters";
    HeightUnit["Centimeters"] = "Centimeters";
    HeightUnit["Inch"] = "Inch";
})(HeightUnit || (HeightUnit = {}));
export var HorseSourceType;
(function (HorseSourceType) {
    HorseSourceType["MyHorses"] = "MyHorses";
    HorseSourceType["Farms"] = "Farms";
    HorseSourceType["Registries"] = "Registries";
    HorseSourceType["Favorite"] = "Favorite";
    HorseSourceType["LocationRadius"] = "LocationRadius";
    HorseSourceType["AllPublic"] = "AllPublic";
    HorseSourceType["ExcludedHorses"] = "ExcludedHorses";
})(HorseSourceType || (HorseSourceType = {}));
export var InformationAvailabilityType;
(function (InformationAvailabilityType) {
    InformationAvailabilityType["None"] = "None";
    InformationAvailabilityType["FromOne"] = "FromOne";
    InformationAvailabilityType["FromBoth"] = "FromBoth";
})(InformationAvailabilityType || (InformationAvailabilityType = {}));
export var KinshipDegree;
(function (KinshipDegree) {
    KinshipDegree["Clone"] = "Clone";
    KinshipDegree["First"] = "First";
    KinshipDegree["Second"] = "Second";
    KinshipDegree["Third"] = "Third";
})(KinshipDegree || (KinshipDegree = {}));
export var MediaType;
(function (MediaType) {
    MediaType["Image"] = "Image";
    MediaType["Video"] = "Video";
})(MediaType || (MediaType = {}));
export var OrderOperationStatus;
(function (OrderOperationStatus) {
    OrderOperationStatus["Waiting"] = "Waiting";
    OrderOperationStatus["Ready"] = "Ready";
    OrderOperationStatus["Refunded"] = "Refunded";
    OrderOperationStatus["Canceled"] = "Canceled";
})(OrderOperationStatus || (OrderOperationStatus = {}));
export var OrderingType;
(function (OrderingType) {
    OrderingType["Ascending"] = "Ascending";
    OrderingType["Descending"] = "Descending";
})(OrderingType || (OrderingType = {}));
export var OrganizationStatus;
(function (OrganizationStatus) {
    OrganizationStatus["Disabled"] = "Disabled";
    OrganizationStatus["Activated"] = "Activated";
})(OrganizationStatus || (OrganizationStatus = {}));
export var PackageStatus;
(function (PackageStatus) {
    PackageStatus["Disabled"] = "Disabled";
    PackageStatus["Active"] = "Active";
    PackageStatus["Archived"] = "Archived";
})(PackageStatus || (PackageStatus = {}));
export var Parent;
(function (Parent) {
    Parent["Dam"] = "Dam";
    Parent["Sire"] = "Sire";
})(Parent || (Parent = {}));
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["None"] = "None";
    PaymentMethod["Stripe"] = "Stripe";
    PaymentMethod["Invoice"] = "Invoice";
    PaymentMethod["PreClinical"] = "PreClinical";
    PaymentMethod["PayPal"] = "PayPal";
    PaymentMethod["Square"] = "Square";
    PaymentMethod["Check"] = "Check";
    PaymentMethod["Cash"] = "Cash";
    PaymentMethod["Giftcard"] = "Giftcard";
    PaymentMethod["Complimentary"] = "Complimentary";
    PaymentMethod["Jotform"] = "Jotform";
    PaymentMethod["Coupon"] = "Coupon";
    PaymentMethod["ActivationCode"] = "ActivationCode";
    PaymentMethod["Shopify"] = "Shopify";
})(PaymentMethod || (PaymentMethod = {}));
export var PhenotypeState;
(function (PhenotypeState) {
    PhenotypeState["NotTested"] = "NotTested";
    PhenotypeState["Inconclusive"] = "Inconclusive";
    PhenotypeState["Negative"] = "Negative";
    PhenotypeState["Positive"] = "Positive";
})(PhenotypeState || (PhenotypeState = {}));
export var ProbabilityResultType;
(function (ProbabilityResultType) {
    ProbabilityResultType["Approximate"] = "Approximate";
    ProbabilityResultType["Accurate"] = "Accurate";
})(ProbabilityResultType || (ProbabilityResultType = {}));
export var ProductSource;
(function (ProductSource) {
    ProductSource["Edx"] = "Edx";
    ProductSource["Shopify"] = "Shopify";
    ProductSource["Stripe"] = "Stripe";
})(ProductSource || (ProductSource = {}));
export var PurchasableTestStatus;
(function (PurchasableTestStatus) {
    PurchasableTestStatus["Disabled"] = "Disabled";
    PurchasableTestStatus["Active"] = "Active";
    PurchasableTestStatus["Archived"] = "Archived";
})(PurchasableTestStatus || (PurchasableTestStatus = {}));
export var ReportStatus;
(function (ReportStatus) {
    ReportStatus["NotOrdered"] = "NotOrdered";
    ReportStatus["NotPaid"] = "NotPaid";
    ReportStatus["WaitingSample"] = "WaitingSample";
    ReportStatus["Processing"] = "Processing";
    ReportStatus["Complete"] = "Complete";
})(ReportStatus || (ReportStatus = {}));
export var ReportsType;
(function (ReportsType) {
    ReportsType["Ancestry"] = "Ancestry";
    ReportsType["Diagnostic"] = "Diagnostic";
    ReportsType["Parentage"] = "Parentage";
    ReportsType["Relatives"] = "Relatives";
    ReportsType["ShortTandemRepeat"] = "ShortTandemRepeat";
})(ReportsType || (ReportsType = {}));
export var Salutation;
(function (Salutation) {
    Salutation["Unknown"] = "Unknown";
    Salutation["Mrs"] = "Mrs";
    Salutation["Dr"] = "Dr";
    Salutation["Mr"] = "Mr";
    Salutation["Ms"] = "Ms";
    Salutation["Miss"] = "Miss";
    Salutation["Prof"] = "Prof";
})(Salutation || (Salutation = {}));
export var SampleStatus;
(function (SampleStatus) {
    SampleStatus["Undefined"] = "Undefined";
    SampleStatus["Waiting"] = "Waiting";
    SampleStatus["Received"] = "Received";
    SampleStatus["Broken"] = "Broken";
})(SampleStatus || (SampleStatus = {}));
export var ServiceModeType;
(function (ServiceModeType) {
    ServiceModeType["On"] = "On";
    ServiceModeType["UsersOff"] = "UsersOff";
})(ServiceModeType || (ServiceModeType = {}));
export var SourceApplication;
(function (SourceApplication) {
    SourceApplication["EDX"] = "EDX";
    SourceApplication["DGX"] = "DGX";
})(SourceApplication || (SourceApplication = {}));
export var TransferStatus;
(function (TransferStatus) {
    TransferStatus["Created"] = "Created";
    TransferStatus["Reviewed"] = "Reviewed";
    TransferStatus["Completed"] = "Completed";
    TransferStatus["Rejected"] = "Rejected";
})(TransferStatus || (TransferStatus = {}));
export var TransferType;
(function (TransferType) {
    TransferType["Sale"] = "Sale";
    TransferType["Gift"] = "Gift";
    TransferType["Refund"] = "Refund";
})(TransferType || (TransferType = {}));
export var UserNotificationType;
(function (UserNotificationType) {
    UserNotificationType["Notification"] = "Notification";
    UserNotificationType["Email"] = "Email";
})(UserNotificationType || (UserNotificationType = {}));
export var UserTypes;
(function (UserTypes) {
    UserTypes["User"] = "User";
    UserTypes["Admin"] = "Admin";
    UserTypes["Employee"] = "Employee";
})(UserTypes || (UserTypes = {}));
export var VerificationStatus;
(function (VerificationStatus) {
    VerificationStatus["None"] = "None";
    VerificationStatus["Verified"] = "Verified";
})(VerificationStatus || (VerificationStatus = {}));
import axios from "axios";
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
export class HttpClient {
    instance;
    securityData = null;
    securityWorker;
    secure;
    format;
    constructor({ securityWorker, secure, format, ...axiosConfig } = {}) {
        this.instance = axios.create({
            ...axiosConfig,
            baseURL: axiosConfig.baseURL || "https://api.test.etalondiagnostics.com",
        });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }
    setSecurityData = (data) => {
        this.securityData = data;
    };
    mergeRequestParams(params1, params2) {
        const method = params1.method || (params2 && params2.method);
        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method && this.instance.defaults.headers[method.toLowerCase()]) || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }
    stringifyFormItem(formItem) {
        if (typeof formItem === "object" && formItem !== null) {
            return JSON.stringify(formItem);
        }
        else {
            return `${formItem}`;
        }
    }
    createFormData(input) {
        if (input instanceof FormData) {
            return input;
        }
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent = property instanceof Array ? property : [property];
            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }
            return formData;
        }, new FormData());
    }
    request = async ({ secure, path, type, query, format, body, ...params }) => {
        const secureParams = ((typeof secure === "boolean" ? secure : this.secure) &&
            this.securityWorker &&
            (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;
        if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
            body = this.createFormData(body);
        }
        if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
            body = JSON.stringify(body);
        }
        return this.instance.request({
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type ? { "Content-Type": type } : {}),
            },
            params: query,
            responseType: responseFormat,
            data: body,
            url: path,
        });
    };
}
/**
 * @title EtalonDX
 * @version v1
 * @baseUrl https://api.test.etalondiagnostics.com
 */
export class Api extends HttpClient {
    api = {
        /**
         * No description
         *
         * @tags AbilityGroupAdvanced
         * @name AbilityGroupAdvancedList
         * @request GET:/api/AbilityGroupAdvanced
         * @secure
         */
        abilityGroupAdvancedList: (query, params = {}) => this.request({
            path: `/api/AbilityGroupAdvanced`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AbilityGroupAdvancedCreate
         * @name AbilityGroupAdvancedCreateCreate
         * @request POST:/api/AbilityGroupAdvancedCreate
         * @secure
         */
        abilityGroupAdvancedCreateCreate: (data, params = {}) => this.request({
            path: `/api/AbilityGroupAdvancedCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AbilityGroupAdvancedDelete
         * @name AbilityGroupAdvancedDeleteDelete
         * @request DELETE:/api/AbilityGroupAdvancedDelete
         * @secure
         */
        abilityGroupAdvancedDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AbilityGroupAdvancedDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AbilityGroupAdvancedDetails
         * @name AbilityGroupAdvancedDetailsDetail
         * @request GET:/api/AbilityGroupAdvancedDetails/{entityId}
         * @secure
         */
        abilityGroupAdvancedDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/AbilityGroupAdvancedDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AbilityGroupAdvancedUpdate
         * @name AbilityGroupAdvancedUpdateUpdate
         * @request PUT:/api/AbilityGroupAdvancedUpdate
         * @secure
         */
        abilityGroupAdvancedUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AbilityGroupAdvancedUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesAdminValidateDetail
         * @request GET:/api/activation-codes-admin/{code}/validate
         * @secure
         */
        activationCodesAdminValidateDetail: (code, params = {}) => this.request({
            path: `/api/activation-codes-admin/${code}/validate`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesAdminCreate
         * @request POST:/api/activation-codes-admin
         * @secure
         */
        activationCodesAdminCreate: (data, params = {}) => this.request({
            path: `/api/activation-codes-admin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesAdminList
         * @request GET:/api/activation-codes-admin
         * @secure
         */
        activationCodesAdminList: (query, params = {}) => this.request({
            path: `/api/activation-codes-admin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesAdminUpdate
         * @request PUT:/api/activation-codes-admin
         * @secure
         */
        activationCodesAdminUpdate: (data, params = {}) => this.request({
            path: `/api/activation-codes-admin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesAdminDelete
         * @request DELETE:/api/activation-codes-admin/{activationCodeId}
         * @secure
         */
        activationCodesAdminDelete: (activationCodeId, params = {}) => this.request({
            path: `/api/activation-codes-admin/${activationCodeId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesAdminDetail
         * @request GET:/api/activation-codes-admin/{activationCodeId}
         * @secure
         */
        activationCodesAdminDetail: (activationCodeId, params = {}) => this.request({
            path: `/api/activation-codes-admin/${activationCodeId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesAdminUpdate2
         * @request PUT:/api/activation-codes-admin/{activationCodeId}
         * @originalName activationCodesAdminUpdate
         * @duplicate
         * @secure
         */
        activationCodesAdminUpdate2: (activationCodeId, data, params = {}) => this.request({
            path: `/api/activation-codes-admin/${activationCodeId}`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesAdminGetActiveList
         * @request GET:/api/activation-codes-admin/get-active
         * @secure
         */
        activationCodesAdminGetActiveList: (params = {}) => this.request({
            path: `/api/activation-codes-admin/get-active`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesAdminGetProductsByIdsList
         * @request GET:/api/activation-codes-admin/get-products-by-ids
         * @secure
         */
        activationCodesAdminGetProductsByIdsList: (data, params = {}) => this.request({
            path: `/api/activation-codes-admin/get-products-by-ids`,
            method: "GET",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesAdminRegenerateCodeUpdate
         * @request PUT:/api/activation-codes-admin/{activationCodeId}/regenerate-code
         * @secure
         */
        activationCodesAdminRegenerateCodeUpdate: (activationCodeId, params = {}) => this.request({
            path: `/api/activation-codes-admin/${activationCodeId}/regenerate-code`,
            method: "PUT",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ShopifyProductsList
         * @request GET:/api/shopify-products
         * @secure
         */
        shopifyProductsList: (params = {}) => this.request({
            path: `/api/shopify-products`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesValidateDetail
         * @request GET:/api/activation-codes/{code}/validate
         * @secure
         */
        activationCodesValidateDetail: (code, params = {}) => this.request({
            path: `/api/activation-codes/${code}/validate`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesCreate
         * @request POST:/api/activation-codes
         * @secure
         */
        activationCodesCreate: (data, params = {}) => this.request({
            path: `/api/activation-codes`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ActivationCodes
         * @name ActivationCodesUpdate
         * @request PUT:/api/activation-codes
         * @secure
         */
        activationCodesUpdate: (data, params = {}) => this.request({
            path: `/api/activation-codes`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminAvatarAdmin
         * @name AdminAvatarAdminDetail
         * @request GET:/api/AdminAvatarAdmin/{adminId}
         * @secure
         */
        adminAvatarAdminDetail: (adminId, params = {}) => this.request({
            path: `/api/AdminAvatarAdmin/${adminId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminAvatarDeleteAdmin
         * @name AdminAvatarDeleteAdminDelete
         * @request DELETE:/api/AdminAvatarDeleteAdmin/{adminId}
         * @secure
         */
        adminAvatarDeleteAdminDelete: (adminId, params = {}) => this.request({
            path: `/api/AdminAvatarDeleteAdmin/${adminId}`,
            method: "DELETE",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminAvatarUpdateAdmin
         * @name AdminAvatarUpdateAdminCreate
         * @request POST:/api/AdminAvatarUpdateAdmin
         * @secure
         */
        adminAvatarUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AdminAvatarUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminItemListRead
         * @name AdminItemListReadList
         * @request GET:/api/AdminItemListRead
         * @secure
         */
        adminItemListReadList: (params = {}) => this.request({
            path: `/api/AdminItemListRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminNotificationSettingAdmin
         * @name AdminNotificationSettingAdminList
         * @request GET:/api/AdminNotificationSettingAdmin
         * @secure
         */
        adminNotificationSettingAdminList: (query, params = {}) => this.request({
            path: `/api/AdminNotificationSettingAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminNotificationSettingUpdateAdmin
         * @name AdminNotificationSettingUpdateAdminCreate
         * @request POST:/api/AdminNotificationSettingUpdateAdmin
         * @secure
         */
        adminNotificationSettingUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AdminNotificationSettingUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminPermissionAdmin
         * @name AdminPermissionAdminDetail
         * @request GET:/api/AdminPermissionAdmin/{adminId}
         * @secure
         */
        adminPermissionAdminDetail: (adminId, params = {}) => this.request({
            path: `/api/AdminPermissionAdmin/${adminId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminPermissionDomainsTable
         * @name AdminPermissionDomainsTableList
         * @request GET:/api/AdminPermissionDomainsTable
         * @secure
         */
        adminPermissionDomainsTableList: (query, params = {}) => this.request({
            path: `/api/AdminPermissionDomainsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminPermissionDomainsTableCreate
         * @name AdminPermissionDomainsTableCreateCreate
         * @request POST:/api/AdminPermissionDomainsTableCreate
         * @secure
         */
        adminPermissionDomainsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/AdminPermissionDomainsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminPermissionDomainsTableDelete
         * @name AdminPermissionDomainsTableDeleteDelete
         * @request DELETE:/api/AdminPermissionDomainsTableDelete
         * @secure
         */
        adminPermissionDomainsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AdminPermissionDomainsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminPermissionDomainsTableDetails
         * @name AdminPermissionDomainsTableDetailsDetail
         * @request GET:/api/AdminPermissionDomainsTableDetails/{entityId}
         * @secure
         */
        adminPermissionDomainsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/AdminPermissionDomainsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminPermissionDomainsTableUpdate
         * @name AdminPermissionDomainsTableUpdateUpdate
         * @request PUT:/api/AdminPermissionDomainsTableUpdate
         * @secure
         */
        adminPermissionDomainsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AdminPermissionDomainsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminPermissions
         * @name AdminPermissionsList
         * @request GET:/api/AdminPermissions
         * @secure
         */
        adminPermissionsList: (params = {}) => this.request({
            path: `/api/AdminPermissions`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminPermissionUpdateAdmin
         * @name AdminPermissionUpdateAdminCreate
         * @request POST:/api/AdminPermissionUpdateAdmin
         * @secure
         */
        adminPermissionUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AdminPermissionUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminsAdmin
         * @name AdminsAdminList
         * @request GET:/api/AdminsAdmin
         * @secure
         */
        adminsAdminList: (query, params = {}) => this.request({
            path: `/api/AdminsAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminsCreateAdmin
         * @name AdminsCreateAdminCreate
         * @request POST:/api/AdminsCreateAdmin
         * @secure
         */
        adminsCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AdminsCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminsDetailsAdmin
         * @name AdminsDetailsAdminDetail
         * @request GET:/api/AdminsDetailsAdmin/{adminId}
         * @secure
         */
        adminsDetailsAdminDetail: (adminId, params = {}) => this.request({
            path: `/api/AdminsDetailsAdmin/${adminId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminsPasswordUpdateAdmin
         * @name AdminsPasswordUpdateAdminCreate
         * @request POST:/api/AdminsPasswordUpdateAdmin
         * @secure
         */
        adminsPasswordUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AdminsPasswordUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminsStatusUpdateAdmin
         * @name AdminsStatusUpdateAdminCreate
         * @request POST:/api/AdminsStatusUpdateAdmin
         * @secure
         */
        adminsStatusUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AdminsStatusUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdminsUpdateAdmin
         * @name AdminsUpdateAdminUpdate
         * @request PUT:/api/AdminsUpdateAdmin
         * @secure
         */
        adminsUpdateAdminUpdate: (data, params = {}) => this.request({
            path: `/api/AdminsUpdateAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedAbility
         * @name AdvancedAbilityList
         * @request GET:/api/AdvancedAbility
         * @secure
         */
        advancedAbilityList: (query, params = {}) => this.request({
            path: `/api/AdvancedAbility`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedAbilityCreate
         * @name AdvancedAbilityCreateCreate
         * @request POST:/api/AdvancedAbilityCreate
         * @secure
         */
        advancedAbilityCreateCreate: (data, params = {}) => this.request({
            path: `/api/AdvancedAbilityCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedAbilityDelete
         * @name AdvancedAbilityDeleteDelete
         * @request DELETE:/api/AdvancedAbilityDelete
         * @secure
         */
        advancedAbilityDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AdvancedAbilityDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedAbilityDetails
         * @name AdvancedAbilityDetailsDetail
         * @request GET:/api/AdvancedAbilityDetails/{entityId}
         * @secure
         */
        advancedAbilityDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/AdvancedAbilityDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedAbilityUpdate
         * @name AdvancedAbilityUpdateUpdate
         * @request PUT:/api/AdvancedAbilityUpdate
         * @secure
         */
        advancedAbilityUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AdvancedAbilityUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedDanger
         * @name AdvancedDangerList
         * @request GET:/api/AdvancedDanger
         * @secure
         */
        advancedDangerList: (query, params = {}) => this.request({
            path: `/api/AdvancedDanger`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedDangerCreate
         * @name AdvancedDangerCreateCreate
         * @request POST:/api/AdvancedDangerCreate
         * @secure
         */
        advancedDangerCreateCreate: (data, params = {}) => this.request({
            path: `/api/AdvancedDangerCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedDangerDelete
         * @name AdvancedDangerDeleteDelete
         * @request DELETE:/api/AdvancedDangerDelete
         * @secure
         */
        advancedDangerDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AdvancedDangerDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedDangerDetails
         * @name AdvancedDangerDetailsDetail
         * @request GET:/api/AdvancedDangerDetails/{entityId}
         * @secure
         */
        advancedDangerDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/AdvancedDangerDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedDangerUpdate
         * @name AdvancedDangerUpdateUpdate
         * @request PUT:/api/AdvancedDangerUpdate
         * @secure
         */
        advancedDangerUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AdvancedDangerUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedHealthIssue
         * @name AdvancedHealthIssueList
         * @request GET:/api/AdvancedHealthIssue
         * @secure
         */
        advancedHealthIssueList: (query, params = {}) => this.request({
            path: `/api/AdvancedHealthIssue`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedHealthIssueCreate
         * @name AdvancedHealthIssueCreateCreate
         * @request POST:/api/AdvancedHealthIssueCreate
         * @secure
         */
        advancedHealthIssueCreateCreate: (data, params = {}) => this.request({
            path: `/api/AdvancedHealthIssueCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedHealthIssueDelete
         * @name AdvancedHealthIssueDeleteDelete
         * @request DELETE:/api/AdvancedHealthIssueDelete
         * @secure
         */
        advancedHealthIssueDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AdvancedHealthIssueDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedHealthIssueDetails
         * @name AdvancedHealthIssueDetailsDetail
         * @request GET:/api/AdvancedHealthIssueDetails/{entityId}
         * @secure
         */
        advancedHealthIssueDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/AdvancedHealthIssueDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AdvancedHealthIssueUpdate
         * @name AdvancedHealthIssueUpdateUpdate
         * @request PUT:/api/AdvancedHealthIssueUpdate
         * @secure
         */
        advancedHealthIssueUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AdvancedHealthIssueUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AffectionTypeTable
         * @name AffectionTypeTableList
         * @request GET:/api/AffectionTypeTable
         * @secure
         */
        affectionTypeTableList: (query, params = {}) => this.request({
            path: `/api/AffectionTypeTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AffectionTypeTableCreate
         * @name AffectionTypeTableCreateCreate
         * @request POST:/api/AffectionTypeTableCreate
         * @secure
         */
        affectionTypeTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/AffectionTypeTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AffectionTypeTableDelete
         * @name AffectionTypeTableDeleteDelete
         * @request DELETE:/api/AffectionTypeTableDelete
         * @secure
         */
        affectionTypeTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AffectionTypeTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AffectionTypeTableDetails
         * @name AffectionTypeTableDetailsDetail
         * @request GET:/api/AffectionTypeTableDetails/{entityId}
         * @secure
         */
        affectionTypeTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/AffectionTypeTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AffectionTypeTableUpdate
         * @name AffectionTypeTableUpdateUpdate
         * @request PUT:/api/AffectionTypeTableUpdate
         * @secure
         */
        affectionTypeTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AffectionTypeTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AggregatedAbility
         * @name AggregatedAbilityList
         * @request GET:/api/AggregatedAbility
         * @secure
         */
        aggregatedAbilityList: (query, params = {}) => this.request({
            path: `/api/AggregatedAbility`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AggregatedAbilityCreate
         * @name AggregatedAbilityCreateCreate
         * @request POST:/api/AggregatedAbilityCreate
         * @secure
         */
        aggregatedAbilityCreateCreate: (data, params = {}) => this.request({
            path: `/api/AggregatedAbilityCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AggregatedAbilityDelete
         * @name AggregatedAbilityDeleteDelete
         * @request DELETE:/api/AggregatedAbilityDelete
         * @secure
         */
        aggregatedAbilityDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AggregatedAbilityDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AggregatedAbilityDetails
         * @name AggregatedAbilityDetailsDetail
         * @request GET:/api/AggregatedAbilityDetails/{entityId}
         * @secure
         */
        aggregatedAbilityDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/AggregatedAbilityDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AggregatedAbilityUpdate
         * @name AggregatedAbilityUpdateUpdate
         * @request PUT:/api/AggregatedAbilityUpdate
         * @secure
         */
        aggregatedAbilityUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AggregatedAbilityUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AggregatedHealthIssue
         * @name AggregatedHealthIssueList
         * @request GET:/api/AggregatedHealthIssue
         * @secure
         */
        aggregatedHealthIssueList: (query, params = {}) => this.request({
            path: `/api/AggregatedHealthIssue`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AggregatedHealthIssueCreate
         * @name AggregatedHealthIssueCreateCreate
         * @request POST:/api/AggregatedHealthIssueCreate
         * @secure
         */
        aggregatedHealthIssueCreateCreate: (data, params = {}) => this.request({
            path: `/api/AggregatedHealthIssueCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AggregatedHealthIssueDelete
         * @name AggregatedHealthIssueDeleteDelete
         * @request DELETE:/api/AggregatedHealthIssueDelete
         * @secure
         */
        aggregatedHealthIssueDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AggregatedHealthIssueDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AggregatedHealthIssueDetails
         * @name AggregatedHealthIssueDetailsDetail
         * @request GET:/api/AggregatedHealthIssueDetails/{entityId}
         * @secure
         */
        aggregatedHealthIssueDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/AggregatedHealthIssueDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AggregatedHealthIssueUpdate
         * @name AggregatedHealthIssueUpdateUpdate
         * @request PUT:/api/AggregatedHealthIssueUpdate
         * @secure
         */
        aggregatedHealthIssueUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AggregatedHealthIssueUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryBreeds
         * @name AncestryBreedsDetail
         * @request GET:/api/AncestryBreeds/{animalId}
         * @secure
         */
        ancestryBreedsDetail: (animalId, params = {}) => this.request({
            path: `/api/AncestryBreeds/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryBreedsAdmin
         * @name AncestryBreedsAdminDetail
         * @request GET:/api/AncestryBreedsAdmin/{horseId}
         * @secure
         */
        ancestryBreedsAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryBreedsAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryBreedsAssociation
         * @name AncestryBreedsAssociationDetail
         * @request GET:/api/AncestryBreedsAssociation/{horseId}
         * @secure
         */
        ancestryBreedsAssociationDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryBreedsAssociation/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryBreedsReviewAdmin
         * @name AncestryBreedsReviewAdminDetail
         * @request GET:/api/AncestryBreedsReviewAdmin/{horseId}
         * @secure
         */
        ancestryBreedsReviewAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryBreedsReviewAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryKinshipAdmin
         * @name AncestryKinshipAdminDetail
         * @request GET:/api/AncestryKinshipAdmin/{horseId}
         * @secure
         */
        ancestryKinshipAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryKinshipAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryKinshipAssociation
         * @name AncestryKinshipAssociationDetail
         * @request GET:/api/AncestryKinshipAssociation/{horseId}
         * @secure
         */
        ancestryKinshipAssociationDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryKinshipAssociation/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryKinshipReviewAdmin
         * @name AncestryKinshipReviewAdminDetail
         * @request GET:/api/AncestryKinshipReviewAdmin/{horseId}
         * @secure
         */
        ancestryKinshipReviewAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryKinshipReviewAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryKinshipUser
         * @name AncestryKinshipUserDetail
         * @request GET:/api/AncestryKinshipUser/{animalId}
         * @secure
         */
        ancestryKinshipUserDetail: (animalId, params = {}) => this.request({
            path: `/api/AncestryKinshipUser/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryPcaPoint
         * @name AncestryPcaPointDetail
         * @request GET:/api/AncestryPcaPoint/{animalId}
         * @secure
         */
        ancestryPcaPointDetail: (animalId, params = {}) => this.request({
            path: `/api/AncestryPcaPoint/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryPcaPointAdmin
         * @name AncestryPcaPointAdminDetail
         * @request GET:/api/AncestryPcaPointAdmin/{horseId}
         * @secure
         */
        ancestryPcaPointAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryPcaPointAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryPcaPointAssociation
         * @name AncestryPcaPointAssociationDetail
         * @request GET:/api/AncestryPcaPointAssociation/{horseId}
         * @secure
         */
        ancestryPcaPointAssociationDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryPcaPointAssociation/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryPcaPointReviewAdmin
         * @name AncestryPcaPointReviewAdminDetail
         * @request GET:/api/AncestryPcaPointReviewAdmin/{horseId}
         * @secure
         */
        ancestryPcaPointReviewAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryPcaPointReviewAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryPcaReferencePoint
         * @name AncestryPcaReferencePointList
         * @request GET:/api/AncestryPcaReferencePoint
         * @secure
         */
        ancestryPcaReferencePointList: (params = {}) => this.request({
            path: `/api/AncestryPcaReferencePoint`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryPcaReferencePointAdmin
         * @name AncestryPcaReferencePointAdminList
         * @request GET:/api/AncestryPcaReferencePointAdmin
         * @secure
         */
        ancestryPcaReferencePointAdminList: (params = {}) => this.request({
            path: `/api/AncestryPcaReferencePointAdmin`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryPcaReferencePointAssociation
         * @name AncestryPcaReferencePointAssociationList
         * @request GET:/api/AncestryPcaReferencePointAssociation
         * @secure
         */
        ancestryPcaReferencePointAssociationList: (params = {}) => this.request({
            path: `/api/AncestryPcaReferencePointAssociation`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryPrivacyValidateEmployee
         * @name AncestryPrivacyValidateEmployeeList
         * @request GET:/api/AncestryPrivacyValidateEmployee
         * @secure
         */
        ancestryPrivacyValidateEmployeeList: (query, params = {}) => this.request({
            path: `/api/AncestryPrivacyValidateEmployee`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryPrivacyValidateUser
         * @name AncestryPrivacyValidateUserList
         * @request GET:/api/AncestryPrivacyValidateUser
         * @secure
         */
        ancestryPrivacyValidateUserList: (query, params = {}) => this.request({
            path: `/api/AncestryPrivacyValidateUser`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryProbabilities
         * @name AncestryProbabilitiesDetail
         * @request GET:/api/AncestryProbabilities/{animalId}
         * @secure
         */
        ancestryProbabilitiesDetail: (animalId, params = {}) => this.request({
            path: `/api/AncestryProbabilities/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryProbabilitiesAdmin
         * @name AncestryProbabilitiesAdminDetail
         * @request GET:/api/AncestryProbabilitiesAdmin/{horseId}
         * @secure
         */
        ancestryProbabilitiesAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryProbabilitiesAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryProbabilitiesAssociation
         * @name AncestryProbabilitiesAssociationDetail
         * @request GET:/api/AncestryProbabilitiesAssociation/{horseId}
         * @secure
         */
        ancestryProbabilitiesAssociationDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryProbabilitiesAssociation/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryProbabilitiesReviewAdmin
         * @name AncestryProbabilitiesReviewAdminDetail
         * @request GET:/api/AncestryProbabilitiesReviewAdmin/{horseId}
         * @secure
         */
        ancestryProbabilitiesReviewAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryProbabilitiesReviewAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryRelatedAdmin
         * @name AncestryRelatedAdminDetail
         * @request GET:/api/AncestryRelatedAdmin/{horseId}
         * @secure
         */
        ancestryRelatedAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryRelatedAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryRelatedAssociation
         * @name AncestryRelatedAssociationDetail
         * @request GET:/api/AncestryRelatedAssociation/{horseId}
         * @secure
         */
        ancestryRelatedAssociationDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryRelatedAssociation/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryRelatedReviewAdmin
         * @name AncestryRelatedReviewAdminDetail
         * @request GET:/api/AncestryRelatedReviewAdmin/{horseId}
         * @secure
         */
        ancestryRelatedReviewAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AncestryRelatedReviewAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryRelatedUser
         * @name AncestryRelatedUserDetail
         * @request GET:/api/AncestryRelatedUser/{animalId}
         * @secure
         */
        ancestryRelatedUserDetail: (animalId, params = {}) => this.request({
            path: `/api/AncestryRelatedUser/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryRelativesUpdate
         * @name AncestryRelativesUpdateCreate
         * @request POST:/api/AncestryRelativesUpdate
         * @secure
         */
        ancestryRelativesUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AncestryRelativesUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryUpdate
         * @name AncestryUpdateCreate
         * @request POST:/api/AncestryUpdate
         * @secure
         */
        ancestryUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AncestryUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryUpdateDetails
         * @name AncestryUpdateDetailsList
         * @request GET:/api/AncestryUpdateDetails
         * @secure
         */
        ancestryUpdateDetailsList: (params = {}) => this.request({
            path: `/api/AncestryUpdateDetails`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AncestryUpdatePcaReferencePoint
         * @name AncestryUpdatePcaReferencePointUpdate
         * @request PUT:/api/AncestryUpdatePcaReferencePoint
         * @secure
         */
        ancestryUpdatePcaReferencePointUpdate: (data, params = {}) => this.request({
            path: `/api/AncestryUpdatePcaReferencePoint`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalAbilities
         * @name AnimalAbilitiesDetail
         * @request GET:/api/AnimalAbilities/{animalId}
         * @secure
         */
        animalAbilitiesDetail: (animalId, params = {}) => this.request({
            path: `/api/AnimalAbilities/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalAbilitiesAdmin
         * @name AnimalAbilitiesAdminDetail
         * @request GET:/api/AnimalAbilitiesAdmin/{horseId}
         * @secure
         */
        animalAbilitiesAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalAbilitiesAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalAddressMigrationAdmin
         * @name AnimalAddressMigrationAdminCreate
         * @request POST:/api/AnimalAddressMigrationAdmin
         * @secure
         */
        animalAddressMigrationAdminCreate: (params = {}) => this.request({
            path: `/api/AnimalAddressMigrationAdmin`,
            method: "POST",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalAncestryProfileTypes
         * @name AnimalAncestryProfileTypesPartialUpdate
         * @request PATCH:/api/AnimalAncestryProfileTypes
         * @secure
         */
        animalAncestryProfileTypesPartialUpdate: (data, params = {}) => this.request({
            path: `/api/AnimalAncestryProfileTypes`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalAncestryReportVisibilityAdmin
         * @name AnimalAncestryReportVisibilityAdminCreate
         * @request POST:/api/AnimalAncestryReportVisibilityAdmin
         * @secure
         */
        animalAncestryReportVisibilityAdminCreate: (data, params = {}) => this.request({
            path: `/api/AnimalAncestryReportVisibilityAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalArchiveAdmin
         * @name AnimalArchiveAdminCreate
         * @request POST:/api/AnimalArchiveAdmin
         * @secure
         */
        animalArchiveAdminCreate: (data, params = {}) => this.request({
            path: `/api/AnimalArchiveAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalAvatarDeleteAdmin
         * @name AnimalAvatarDeleteAdminDelete
         * @request DELETE:/api/AnimalAvatarDeleteAdmin/{horseId}
         * @secure
         */
        animalAvatarDeleteAdminDelete: (horseId, params = {}) => this.request({
            path: `/api/AnimalAvatarDeleteAdmin/${horseId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalAvatarUpdateAdmin
         * @name AnimalAvatarUpdateAdminCreate
         * @request POST:/api/AnimalAvatarUpdateAdmin
         * @secure
         */
        animalAvatarUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AnimalAvatarUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalGeneticDataVisibilityAdmin
         * @name AnimalGeneticDataVisibilityAdminCreate
         * @request POST:/api/AnimalGeneticDataVisibilityAdmin
         * @secure
         */
        animalGeneticDataVisibilityAdminCreate: (data, params = {}) => this.request({
            path: `/api/AnimalGeneticDataVisibilityAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalGenotypeGroups
         * @name AnimalGenotypeGroupsDetail
         * @request GET:/api/AnimalGenotypeGroups/{animalId}
         * @secure
         */
        animalGenotypeGroupsDetail: (animalId, params = {}) => this.request({
            path: `/api/AnimalGenotypeGroups/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalGenotypeGroupsAdmin
         * @name AnimalGenotypeGroupsAdminDetail
         * @request GET:/api/AnimalGenotypeGroupsAdmin/{horseId}
         * @secure
         */
        animalGenotypeGroupsAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalGenotypeGroupsAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalMedia
         * @name AnimalAvatarDeleteDelete
         * @request DELETE:/api/AnimalAvatarDelete/{animalId}
         * @secure
         */
        animalAvatarDeleteDelete: (animalId, params = {}) => this.request({
            path: `/api/AnimalAvatarDelete/${animalId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalMedia
         * @name AnimalAvatarUpdateCreate
         * @request POST:/api/AnimalAvatarUpdate
         * @secure
         */
        animalAvatarUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AnimalAvatarUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalMedia
         * @name AnimalImagesUpdateCreate
         * @request POST:/api/AnimalImagesUpdate
         * @secure
         */
        animalImagesUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AnimalImagesUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalMedia
         * @name AnimalMediaDetail
         * @request GET:/api/AnimalMedia/{animalId}
         * @secure
         */
        animalMediaDetail: (animalId, params = {}) => this.request({
            path: `/api/AnimalMedia/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalMedia
         * @name AnimalMediaDeleteDelete
         * @request DELETE:/api/AnimalMediaDelete
         * @secure
         */
        animalMediaDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AnimalMediaDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalMedia
         * @name AnimalVideosUpdateCreate
         * @request POST:/api/AnimalVideosUpdate
         * @secure
         */
        animalVideosUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AnimalVideosUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalMediaAdmin
         * @name AnimalImagesUpdateAdminCreate
         * @request POST:/api/AnimalImagesUpdateAdmin
         * @secure
         */
        animalImagesUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AnimalImagesUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalMediaAdmin
         * @name AnimalMediaAdminDetail
         * @request GET:/api/AnimalMediaAdmin/{horseId}
         * @secure
         */
        animalMediaAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalMediaAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalMediaAdmin
         * @name AnimalMediaDeleteAdminDelete
         * @request DELETE:/api/AnimalMediaDeleteAdmin
         * @secure
         */
        animalMediaDeleteAdminDelete: (data, params = {}) => this.request({
            path: `/api/AnimalMediaDeleteAdmin`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalOldAddressAdmin
         * @name AnimalOldAddressAdminDetail
         * @request GET:/api/AnimalOldAddressAdmin/{horseId}
         * @secure
         */
        animalOldAddressAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalOldAddressAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalOrders
         * @name AnimalOrdersLastDetail
         * @request GET:/api/AnimalOrders/Last/{animalId}
         * @secure
         */
        animalOrdersLastDetail: (animalId, params = {}) => this.request({
            path: `/api/AnimalOrders/Last/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalOrdersAdmin
         * @name AnimalOrdersAdminList
         * @request GET:/api/AnimalOrdersAdmin
         * @secure
         */
        animalOrdersAdminList: (query, params = {}) => this.request({
            path: `/api/AnimalOrdersAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalOrdersAdmin
         * @name AnimalOrdersAdminLastDetail
         * @request GET:/api/AnimalOrdersAdmin/Last/{horseId}
         * @secure
         */
        animalOrdersAdminLastDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalOrdersAdmin/Last/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalOwner
         * @name AnimalOwnerDetail
         * @request GET:/api/AnimalOwner/{animalId}
         * @secure
         */
        animalOwnerDetail: (animalId, params = {}) => this.request({
            path: `/api/AnimalOwner/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalOwnerAdmin
         * @name AnimalOwnerAdminDetail
         * @request GET:/api/AnimalOwnerAdmin/{horseId}
         * @secure
         */
        animalOwnerAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalOwnerAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalPanels
         * @name AnimalPanelsDetail
         * @request GET:/api/AnimalPanels/{animalId}
         * @secure
         */
        animalPanelsDetail: (animalId, params = {}) => this.request({
            path: `/api/AnimalPanels/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalPanelsAdmin
         * @name AnimalPanelsAdminDetail
         * @request GET:/api/AnimalPanelsAdmin/{horseId}
         * @secure
         */
        animalPanelsAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalPanelsAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalPanelsReviewAdmin
         * @name AnimalPanelsReviewAdminList
         * @request GET:/api/AnimalPanelsReviewAdmin
         * @secure
         */
        animalPanelsReviewAdminList: (query, params = {}) => this.request({
            path: `/api/AnimalPanelsReviewAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalParentageProfileTypes
         * @name AnimalParentageProfileTypesPartialUpdate
         * @request PATCH:/api/AnimalParentageProfileTypes
         * @secure
         */
        animalParentageProfileTypesPartialUpdate: (data, params = {}) => this.request({
            path: `/api/AnimalParentageProfileTypes`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalParentageReport
         * @name AnimalParentageReportDetail
         * @request GET:/api/AnimalParentageReport/{animalId}
         * @secure
         */
        animalParentageReportDetail: (animalId, params = {}) => this.request({
            path: `/api/AnimalParentageReport/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalParentageReportAdmin
         * @name AnimalParentageReportAdminDetail
         * @request GET:/api/AnimalParentageReportAdmin/{horseId}
         * @secure
         */
        animalParentageReportAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalParentageReportAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalProfileAdmin
         * @name AnimalProfileAdminDetail
         * @request GET:/api/AnimalProfileAdmin/{horseId}
         * @secure
         */
        animalProfileAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalProfileAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalProfileAnimals
         * @name AnimalProfileAnimalsDetail
         * @request GET:/api/AnimalProfileAnimals/{animalId}
         * @secure
         */
        animalProfileAnimalsDetail: (animalId, params = {}) => this.request({
            path: `/api/AnimalProfileAnimals/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalProfileAnimalsAdmin
         * @name AnimalProfileAnimalsAdminDetail
         * @request GET:/api/AnimalProfileAnimalsAdmin/{horseId}
         * @secure
         */
        animalProfileAnimalsAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalProfileAnimalsAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalProfileTypes
         * @name AnimalProfileTypesPartialUpdate
         * @request PATCH:/api/AnimalProfileTypes
         * @secure
         */
        animalProfileTypesPartialUpdate: (data, params = {}) => this.request({
            path: `/api/AnimalProfileTypes`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalPublicityRequest
         * @name AnimalPublicityRequestCreate
         * @request POST:/api/AnimalPublicityRequest
         * @secure
         */
        animalPublicityRequestCreate: (data, params = {}) => this.request({
            path: `/api/AnimalPublicityRequest`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalReports
         * @name AnimalReportsDetail
         * @request GET:/api/AnimalReports/{animalId}
         * @secure
         */
        animalReportsDetail: (animalId, params = {}) => this.request({
            path: `/api/AnimalReports/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalReportsDeleteAdmin
         * @name AnimalReportsDeleteAdminDelete
         * @request DELETE:/api/AnimalReportsDeleteAdmin
         * @secure
         */
        animalReportsDeleteAdminDelete: (data, params = {}) => this.request({
            path: `/api/AnimalReportsDeleteAdmin`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalReportsUpdateAdmin
         * @name AnimalReportsUpdateAdminCreate
         * @request POST:/api/AnimalReportsUpdateAdmin
         * @secure
         */
        animalReportsUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AnimalReportsUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalRestoreAdmin
         * @name AnimalRestoreAdminCreate
         * @request POST:/api/AnimalRestoreAdmin
         * @secure
         */
        animalRestoreAdminCreate: (data, params = {}) => this.request({
            path: `/api/AnimalRestoreAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name AnimalsDiagnosticsReportDataCreate
         * @request POST:/api/animals/diagnostics-report-data/{animalId}
         * @secure
         */
        animalsDiagnosticsReportDataCreate: (animalId, data, params = {}) => this.request({
            path: `/api/animals/diagnostics-report-data/${animalId}`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name AnimalsDiagnosticsReportDataDetail
         * @request GET:/api/animals/diagnostics-report-data/{animalId}
         * @secure
         */
        animalsDiagnosticsReportDataDetail: (animalId, params = {}) => this.request({
            path: `/api/animals/diagnostics-report-data/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name AnimalsDiagnosticsReportDataUploadCreate
         * @request POST:/api/animals/diagnostics-report-data/{animalId}/upload
         * @secure
         */
        animalsDiagnosticsReportDataUploadCreate: (animalId, data, params = {}) => this.request({
            path: `/api/animals/diagnostics-report-data/${animalId}/upload`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name AnimalDiagnosticsReportDataUploadAdminUpdate
         * @request PUT:/api/AnimalDiagnosticsReportDataUploadAdmin
         * @secure
         */
        animalDiagnosticsReportDataUploadAdminUpdate: (data, params = {}) => this.request({
            path: `/api/AnimalDiagnosticsReportDataUploadAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name UserHorsesList
         * @request GET:/api/UserHorses
         * @secure
         */
        userHorsesList: (query, params = {}) => this.request({
            path: `/api/UserHorses`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name AnimalsDetail
         * @request GET:/api/animals/{animalId}
         * @secure
         */
        animalsDetail: (animalId, params = {}) => this.request({
            path: `/api/animals/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name AnimalsArchiveDelete
         * @request DELETE:/api/AnimalsArchive/{animalId}
         * @secure
         */
        animalsArchiveDelete: (animalId, params = {}) => this.request({
            path: `/api/AnimalsArchive/${animalId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name AnimalsList
         * @request GET:/api/animals
         * @secure
         */
        animalsList: (query, params = {}) => this.request({
            path: `/api/animals`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name AnimalsCreateCreate
         * @request POST:/api/AnimalsCreate
         * @secure
         */
        animalsCreateCreate: (data, params = {}) => this.request({
            path: `/api/AnimalsCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name AnimalsUpdateUpdate
         * @request PUT:/api/AnimalsUpdate
         * @secure
         */
        animalsUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AnimalsUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name AnimalTransferRequestsCreateUserCreate
         * @request POST:/api/AnimalTransferRequestsCreateUser
         * @secure
         */
        animalTransferRequestsCreateUserCreate: (data, params = {}) => this.request({
            path: `/api/AnimalTransferRequestsCreateUser`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Animals
         * @name GetAnimalsList
         * @request GET:/api/GetAnimals
         * @secure
         */
        getAnimalsList: (query, params = {}) => this.request({
            path: `/api/GetAnimals`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalsAdmin
         * @name AnimalsAdminList
         * @request GET:/api/AnimalsAdmin
         * @secure
         */
        animalsAdminList: (query, params = {}) => this.request({
            path: `/api/AnimalsAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalsAdmin
         * @name AnimalsCreateAdminCreate
         * @request POST:/api/AnimalsCreateAdmin
         * @secure
         */
        animalsCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AnimalsCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalsAdmin
         * @name AnimalsDeleteAdminDelete
         * @request DELETE:/api/AnimalsDeleteAdmin
         * @secure
         */
        animalsDeleteAdminDelete: (data, params = {}) => this.request({
            path: `/api/AnimalsDeleteAdmin`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalsAdmin
         * @name AnimalsDetailsAdminDetail
         * @request GET:/api/AnimalsDetailsAdmin/{horseId}
         * @secure
         */
        animalsDetailsAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalsDetailsAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalsAdmin
         * @name AnimalsDetailsInfoAdminDetail
         * @request GET:/api/AnimalsDetailsInfoAdmin/{horseId}
         * @secure
         */
        animalsDetailsInfoAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AnimalsDetailsInfoAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalsAdmin
         * @name AnimalsUpdateAdminUpdate
         * @request PUT:/api/AnimalsUpdateAdmin
         * @secure
         */
        animalsUpdateAdminUpdate: (data, params = {}) => this.request({
            path: `/api/AnimalsUpdateAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalTransferRequestDetails
         * @name AnimalTransferRequestDetailsDetail
         * @request GET:/api/AnimalTransferRequestDetails/{animalId}
         * @secure
         */
        animalTransferRequestDetailsDetail: (animalId, params = {}) => this.request({
            path: `/api/AnimalTransferRequestDetails/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalTransfersAdmin
         * @name AnimalTransferAdminCreate
         * @request POST:/api/AnimalTransferAdmin
         * @secure
         */
        animalTransferAdminCreate: (data, params = {}) => this.request({
            path: `/api/AnimalTransferAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalTransfersAdmin
         * @name AnimalTransferRequestCommentAdminDetail
         * @request GET:/api/AnimalTransferRequestCommentAdmin/{requestId}
         * @secure
         */
        animalTransferRequestCommentAdminDetail: (requestId, params = {}) => this.request({
            path: `/api/AnimalTransferRequestCommentAdmin/${requestId}`,
            method: "GET",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalTransfersAdmin
         * @name AnimalTransferRequestDetailsAdminDetail
         * @request GET:/api/AnimalTransferRequestDetailsAdmin/{requestId}
         * @secure
         */
        animalTransferRequestDetailsAdminDetail: (requestId, params = {}) => this.request({
            path: `/api/AnimalTransferRequestDetailsAdmin/${requestId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalTransfersAdmin
         * @name AnimalTransferRequestRejectedAdminCreate
         * @request POST:/api/AnimalTransferRequestRejectedAdmin
         * @secure
         */
        animalTransferRequestRejectedAdminCreate: (data, params = {}) => this.request({
            path: `/api/AnimalTransferRequestRejectedAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalTransfersAdmin
         * @name AnimalTransferRequestsAdminList
         * @request GET:/api/AnimalTransferRequestsAdmin
         * @secure
         */
        animalTransferRequestsAdminList: (query, params = {}) => this.request({
            path: `/api/AnimalTransferRequestsAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AnimalTransfersAdmin
         * @name AnimalTransferRequestsCreateAdminCreate
         * @request POST:/api/AnimalTransferRequestsCreateAdmin
         * @secure
         */
        animalTransferRequestsCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AnimalTransferRequestsCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationActivePackages
         * @name AssociationActivePackagesList
         * @request GET:/api/AssociationActivePackages
         * @secure
         */
        associationActivePackagesList: (params = {}) => this.request({
            path: `/api/AssociationActivePackages`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationActivePackagesAdmin
         * @name AssociationActivePackagesAdminDetail
         * @request GET:/api/AssociationActivePackagesAdmin/{organizationId}
         * @secure
         */
        associationActivePackagesAdminDetail: (organizationId, params = {}) => this.request({
            path: `/api/AssociationActivePackagesAdmin/${organizationId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationActivePurchasableTests
         * @name AssociationActivePurchasableTestsList
         * @request GET:/api/AssociationActivePurchasableTests
         * @secure
         */
        associationActivePurchasableTestsList: (params = {}) => this.request({
            path: `/api/AssociationActivePurchasableTests`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationActivePurchasableTestsAdmin
         * @name AssociationActivePurchasableTestsAdminDetail
         * @request GET:/api/AssociationActivePurchasableTestsAdmin/{organizationId}
         * @secure
         */
        associationActivePurchasableTestsAdminDetail: (organizationId, params = {}) => this.request({
            path: `/api/AssociationActivePurchasableTestsAdmin/${organizationId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationAllActivePackagesAdmin
         * @name AssociationAllActivePackagesAdminList
         * @request GET:/api/AssociationAllActivePackagesAdmin
         * @secure
         */
        associationAllActivePackagesAdminList: (params = {}) => this.request({
            path: `/api/AssociationAllActivePackagesAdmin`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationAllActivePurchasableTestsAdmin
         * @name AssociationAllActivePurchasableTestsAdminList
         * @request GET:/api/AssociationAllActivePurchasableTestsAdmin
         * @secure
         */
        associationAllActivePurchasableTestsAdminList: (params = {}) => this.request({
            path: `/api/AssociationAllActivePurchasableTestsAdmin`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationContactInformation
         * @name AssociationContactInformationList
         * @request GET:/api/AssociationContactInformation
         * @secure
         */
        associationContactInformationList: (params = {}) => this.request({
            path: `/api/AssociationContactInformation`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationContactInformationUpdate
         * @name AssociationContactInformationUpdateUpdate
         * @request PUT:/api/AssociationContactInformationUpdate
         * @secure
         */
        associationContactInformationUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationContactInformationUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationCouponCheck
         * @name AssociationCouponCheckCreate
         * @request POST:/api/AssociationCouponCheck
         * @secure
         */
        associationCouponCheckCreate: (data, params = {}) => this.request({
            path: `/api/AssociationCouponCheck`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationCouponCheckAdmin
         * @name AssociationCouponCheckAdminCreate
         * @request POST:/api/AssociationCouponCheckAdmin
         * @secure
         */
        associationCouponCheckAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationCouponCheckAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployee
         * @name AssociationEmployeeList
         * @request GET:/api/AssociationEmployee
         * @secure
         */
        associationEmployeeList: (params = {}) => this.request({
            path: `/api/AssociationEmployee`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeAdmin
         * @name AssociationEmployeeAdminList
         * @request GET:/api/AssociationEmployeeAdmin
         * @secure
         */
        associationEmployeeAdminList: (query, params = {}) => this.request({
            path: `/api/AssociationEmployeeAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeAgreement
         * @name AssociationEmployeeAgreementCreate
         * @request POST:/api/AssociationEmployeeAgreement
         * @secure
         */
        associationEmployeeAgreementCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeAgreement`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeAvatarDeleteAdmin
         * @name AssociationEmployeeAvatarDeleteAdminDelete
         * @request DELETE:/api/AssociationEmployeeAvatarDeleteAdmin/{employeeId}
         * @secure
         */
        associationEmployeeAvatarDeleteAdminDelete: (employeeId, params = {}) => this.request({
            path: `/api/AssociationEmployeeAvatarDeleteAdmin/${employeeId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeAvatarUpdateAdmin
         * @name AssociationEmployeeAvatarUpdateAdminCreate
         * @request POST:/api/AssociationEmployeeAvatarUpdateAdmin
         * @secure
         */
        associationEmployeeAvatarUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeAvatarUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeCreateAdmin
         * @name AssociationEmployeeCreateAdminCreate
         * @request POST:/api/AssociationEmployeeCreateAdmin
         * @secure
         */
        associationEmployeeCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeDetailsAdmin
         * @name AssociationEmployeeDetailsAdminDetail
         * @request GET:/api/AssociationEmployeeDetailsAdmin/{employeeId}
         * @secure
         */
        associationEmployeeDetailsAdminDetail: (employeeId, params = {}) => this.request({
            path: `/api/AssociationEmployeeDetailsAdmin/${employeeId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeEmailUpdateAdmin
         * @name AssociationEmployeeEmailUpdateAdminCreate
         * @request POST:/api/AssociationEmployeeEmailUpdateAdmin
         * @secure
         */
        associationEmployeeEmailUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeEmailUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeGeoPoint
         * @name AssociationEmployeeGeoPointList
         * @request GET:/api/AssociationEmployeeGeoPoint
         * @secure
         */
        associationEmployeeGeoPointList: (params = {}) => this.request({
            path: `/api/AssociationEmployeeGeoPoint`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeGeoPointAdmin
         * @name AssociationEmployeeGeoPointAdminDetail
         * @request GET:/api/AssociationEmployeeGeoPointAdmin/{employeeId}
         * @secure
         */
        associationEmployeeGeoPointAdminDetail: (employeeId, params = {}) => this.request({
            path: `/api/AssociationEmployeeGeoPointAdmin/${employeeId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeGeoPointUpdate
         * @name AssociationEmployeeGeoPointUpdateCreate
         * @request POST:/api/AssociationEmployeeGeoPointUpdate
         * @secure
         */
        associationEmployeeGeoPointUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeGeoPointUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeGeoPointUpdateAdmin
         * @name AssociationEmployeeGeoPointUpdateAdminCreate
         * @request POST:/api/AssociationEmployeeGeoPointUpdateAdmin
         * @secure
         */
        associationEmployeeGeoPointUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeGeoPointUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeHorsePackageBucket
         * @name AssociationEmployeeHorsePackageBucketClearCreate
         * @request POST:/api/AssociationEmployeeHorsePackageBucketClear
         * @secure
         */
        associationEmployeeHorsePackageBucketClearCreate: (params = {}) => this.request({
            path: `/api/AssociationEmployeeHorsePackageBucketClear`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeHorsePackageBucket
         * @name AssociationEmployeeHorsePackageBucketList
         * @request GET:/api/AssociationEmployeeHorsePackageBucket
         * @secure
         */
        associationEmployeeHorsePackageBucketList: (params = {}) => this.request({
            path: `/api/AssociationEmployeeHorsePackageBucket`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeHorsePackageBucket
         * @name AssociationEmployeeHorsePackageBucketCreateCreate
         * @request POST:/api/AssociationEmployeeHorsePackageBucketCreate
         * @secure
         */
        associationEmployeeHorsePackageBucketCreateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeHorsePackageBucketCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeHorsePackageBucket
         * @name AssociationEmployeeHorsePackageBucketDeleteCreate
         * @request POST:/api/AssociationEmployeeHorsePackageBucketDelete
         * @secure
         */
        associationEmployeeHorsePackageBucketDeleteCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeHorsePackageBucketDelete`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeHorsePurchasableTestBucket
         * @name AssociationEmployeeHorsePurchasableTestBucketClearCreate
         * @request POST:/api/AssociationEmployeeHorsePurchasableTestBucketClear
         * @secure
         */
        associationEmployeeHorsePurchasableTestBucketClearCreate: (params = {}) => this.request({
            path: `/api/AssociationEmployeeHorsePurchasableTestBucketClear`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeHorsePurchasableTestBucket
         * @name AssociationEmployeeHorsePurchasableTestBucketList
         * @request GET:/api/AssociationEmployeeHorsePurchasableTestBucket
         * @secure
         */
        associationEmployeeHorsePurchasableTestBucketList: (params = {}) => this.request({
            path: `/api/AssociationEmployeeHorsePurchasableTestBucket`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeHorsePurchasableTestBucket
         * @name AssociationEmployeeHorsePurchasableTestBucketCreateCreate
         * @request POST:/api/AssociationEmployeeHorsePurchasableTestBucketCreate
         * @secure
         */
        associationEmployeeHorsePurchasableTestBucketCreateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeHorsePurchasableTestBucketCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeHorsePurchasableTestBucket
         * @name AssociationEmployeeHorsePurchasableTestBucketDeleteCreate
         * @request POST:/api/AssociationEmployeeHorsePurchasableTestBucketDelete
         * @secure
         */
        associationEmployeeHorsePurchasableTestBucketDeleteCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeHorsePurchasableTestBucketDelete`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeListAdmin
         * @name AssociationEmployeeListAdminDetail
         * @request GET:/api/AssociationEmployeeListAdmin/{organizationId}
         * @secure
         */
        associationEmployeeListAdminDetail: (organizationId, params = {}) => this.request({
            path: `/api/AssociationEmployeeListAdmin/${organizationId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeePasswordUpdateAdmin
         * @name AssociationEmployeePasswordUpdateAdminCreate
         * @request POST:/api/AssociationEmployeePasswordUpdateAdmin
         * @secure
         */
        associationEmployeePasswordUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeePasswordUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeePermission
         * @name AssociationEmployeePermissionList
         * @request GET:/api/AssociationEmployeePermission
         * @secure
         */
        associationEmployeePermissionList: (params = {}) => this.request({
            path: `/api/AssociationEmployeePermission`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeePermissionsAdmin
         * @name AssociationEmployeePermissionsAdminDetail
         * @request GET:/api/AssociationEmployeePermissionsAdmin/{userId}
         * @secure
         */
        associationEmployeePermissionsAdminDetail: (userId, params = {}) => this.request({
            path: `/api/AssociationEmployeePermissionsAdmin/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeePermissionsUpdateAdmin
         * @name AssociationEmployeePermissionsUpdateAdminCreate
         * @request POST:/api/AssociationEmployeePermissionsUpdateAdmin
         * @secure
         */
        associationEmployeePermissionsUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeePermissionsUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeProfile
         * @name AssociationEmployeeProfileList
         * @request GET:/api/AssociationEmployeeProfile
         * @secure
         */
        associationEmployeeProfileList: (params = {}) => this.request({
            path: `/api/AssociationEmployeeProfile`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeProfile
         * @name AssociationEmployeeProfileEmailUpdateCreate
         * @request POST:/api/AssociationEmployeeProfileEmailUpdate/{email}
         * @secure
         */
        associationEmployeeProfileEmailUpdateCreate: (email, params = {}) => this.request({
            path: `/api/AssociationEmployeeProfileEmailUpdate/${email}`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeProfile
         * @name AssociationEmployeeProfilePasswordUpdateCreate
         * @request POST:/api/AssociationEmployeeProfilePasswordUpdate
         * @secure
         */
        associationEmployeeProfilePasswordUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeProfilePasswordUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeProfile
         * @name AssociationEmployeeProfileUpdateCreate
         * @request POST:/api/AssociationEmployeeProfileUpdate
         * @secure
         */
        associationEmployeeProfileUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeProfileUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeStatusUpdateAdmin
         * @name AssociationEmployeeStatusUpdateAdminCreate
         * @request POST:/api/AssociationEmployeeStatusUpdateAdmin
         * @secure
         */
        associationEmployeeStatusUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeStatusUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationEmployeeUpdateAdmin
         * @name AssociationEmployeeUpdateAdminUpdate
         * @request PUT:/api/AssociationEmployeeUpdateAdmin
         * @secure
         */
        associationEmployeeUpdateAdminUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationEmployeeUpdateAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationExcludedHorses
         * @name AssociationExcludedHorsesList
         * @request GET:/api/AssociationExcludedHorses
         * @secure
         */
        associationExcludedHorsesList: (query, params = {}) => this.request({
            path: `/api/AssociationExcludedHorses`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationExcludedHorsesAdd
         * @name AssociationExcludedHorsesAddCreate
         * @request POST:/api/AssociationExcludedHorsesAdd/{horseId}
         * @secure
         */
        associationExcludedHorsesAddCreate: (horseId, params = {}) => this.request({
            path: `/api/AssociationExcludedHorsesAdd/${horseId}`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationExcludedHorsesDelete
         * @name AssociationExcludedHorsesDeleteDelete
         * @request DELETE:/api/AssociationExcludedHorsesDelete/{horseId}
         * @secure
         */
        associationExcludedHorsesDeleteDelete: (horseId, params = {}) => this.request({
            path: `/api/AssociationExcludedHorsesDelete/${horseId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationFavoriteHorses
         * @name AssociationFavoriteHorsesList
         * @request GET:/api/AssociationFavoriteHorses
         * @secure
         */
        associationFavoriteHorsesList: (query, params = {}) => this.request({
            path: `/api/AssociationFavoriteHorses`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationFavoriteHorsesAdd
         * @name AssociationFavoriteHorsesAddCreate
         * @request POST:/api/AssociationFavoriteHorsesAdd/{horseId}
         * @secure
         */
        associationFavoriteHorsesAddCreate: (horseId, params = {}) => this.request({
            path: `/api/AssociationFavoriteHorsesAdd/${horseId}`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationFavoriteHorsesDelete
         * @name AssociationFavoriteHorsesDeleteDelete
         * @request DELETE:/api/AssociationFavoriteHorsesDelete/{horseId}
         * @secure
         */
        associationFavoriteHorsesDeleteDelete: (horseId, params = {}) => this.request({
            path: `/api/AssociationFavoriteHorsesDelete/${horseId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationGeneralInformation
         * @name AssociationGeneralInformationList
         * @request GET:/api/AssociationGeneralInformation
         * @secure
         */
        associationGeneralInformationList: (params = {}) => this.request({
            path: `/api/AssociationGeneralInformation`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationGeneralInformationUpdate
         * @name AssociationGeneralInformationUpdateUpdate
         * @request PUT:/api/AssociationGeneralInformationUpdate
         * @secure
         */
        associationGeneralInformationUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationGeneralInformationUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseAbilities
         * @name AssociationHorseAbilitiesDetail
         * @request GET:/api/AssociationHorseAbilities/{horseId}
         * @secure
         */
        associationHorseAbilitiesDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseAbilities/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseAbilitiesAdmin
         * @name AssociationHorseAbilitiesAdminDetail
         * @request GET:/api/AssociationHorseAbilitiesAdmin/{horseId}
         * @secure
         */
        associationHorseAbilitiesAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseAbilitiesAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseAdmin
         * @name AssociationHorseAdminList
         * @request GET:/api/AssociationHorseAdmin
         * @secure
         */
        associationHorseAdminList: (query, params = {}) => this.request({
            path: `/api/AssociationHorseAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseAdminCreate
         * @name AssociationHorseAdminCreateCreate
         * @request POST:/api/AssociationHorseAdminCreate
         * @secure
         */
        associationHorseAdminCreateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseAdminCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseAdminUpdate
         * @name AssociationHorseAdminUpdateUpdate
         * @request PUT:/api/AssociationHorseAdminUpdate
         * @secure
         */
        associationHorseAdminUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseAdminUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseAvatarDelete
         * @name AssociationHorseAvatarDeleteDelete
         * @request DELETE:/api/AssociationHorseAvatarDelete/{horseId}
         * @secure
         */
        associationHorseAvatarDeleteDelete: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseAvatarDelete/${horseId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseAvatarDeleteAdmin
         * @name AssociationHorseAvatarDeleteAdminDelete
         * @request DELETE:/api/AssociationHorseAvatarDeleteAdmin/{horseId}
         * @secure
         */
        associationHorseAvatarDeleteAdminDelete: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseAvatarDeleteAdmin/${horseId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseAvatarUpdate
         * @name AssociationHorseAvatarUpdateCreate
         * @request POST:/api/AssociationHorseAvatarUpdate
         * @secure
         */
        associationHorseAvatarUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseAvatarUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseAvatarUpdateAdmin
         * @name AssociationHorseAvatarUpdateAdminCreate
         * @request POST:/api/AssociationHorseAvatarUpdateAdmin
         * @secure
         */
        associationHorseAvatarUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseAvatarUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseCreate
         * @name AssociationHorseCreateCreate
         * @request POST:/api/AssociationHorseCreate
         * @secure
         */
        associationHorseCreateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseDelete
         * @name AssociationHorseDeleteDelete
         * @request DELETE:/api/AssociationHorseDelete/{horseId}
         * @secure
         */
        associationHorseDeleteDelete: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseDelete/${horseId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseDeleteAdmin
         * @name AssociationHorseDeleteAdminDelete
         * @request DELETE:/api/AssociationHorseDeleteAdmin/{horseId}
         * @secure
         */
        associationHorseDeleteAdminDelete: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseDeleteAdmin/${horseId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseDetails
         * @name AssociationHorseDetailsDetail
         * @request GET:/api/AssociationHorseDetails/{horseId}
         * @secure
         */
        associationHorseDetailsDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseDetails/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseDetailsAdmin
         * @name AssociationHorseDetailsAdminDetail
         * @request GET:/api/AssociationHorseDetailsAdmin/{horseId}
         * @secure
         */
        associationHorseDetailsAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseDetailsAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseGenotypeGroups
         * @name AssociationHorseGenotypeGroupsDetail
         * @request GET:/api/AssociationHorseGenotypeGroups/{horseId}
         * @secure
         */
        associationHorseGenotypeGroupsDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseGenotypeGroups/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseGenotypeGroupsAdmin
         * @name AssociationHorseGenotypeGroupsAdminDetail
         * @request GET:/api/AssociationHorseGenotypeGroupsAdmin/{horseId}
         * @secure
         */
        associationHorseGenotypeGroupsAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseGenotypeGroupsAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseHorseOwnerAdminUpdate
         * @name AssociationHorseHorseOwnerAdminUpdateUpdate
         * @request PUT:/api/AssociationHorseHorseOwnerAdminUpdate
         * @secure
         */
        associationHorseHorseOwnerAdminUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseHorseOwnerAdminUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseHorseOwnerUpdate
         * @name AssociationHorseHorseOwnerUpdateUpdate
         * @request PUT:/api/AssociationHorseHorseOwnerUpdate
         * @secure
         */
        associationHorseHorseOwnerUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseHorseOwnerUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseImagesUpdate
         * @name AssociationHorseImagesUpdateCreate
         * @request POST:/api/AssociationHorseImagesUpdate
         * @secure
         */
        associationHorseImagesUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseImagesUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseImagesUpdateAdmin
         * @name AssociationHorseImagesUpdateAdminCreate
         * @request POST:/api/AssociationHorseImagesUpdateAdmin
         * @secure
         */
        associationHorseImagesUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseImagesUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseInconclusiveHealthIssues
         * @name AssociationHorseInconclusiveHealthIssuesDetail
         * @request GET:/api/AssociationHorseInconclusiveHealthIssues/{horseId}
         * @secure
         */
        associationHorseInconclusiveHealthIssuesDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseInconclusiveHealthIssues/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseInconclusiveHealthIssuesAdmin
         * @name AssociationHorseInconclusiveHealthIssuesAdminDetail
         * @request GET:/api/AssociationHorseInconclusiveHealthIssuesAdmin/{horseId}
         * @secure
         */
        associationHorseInconclusiveHealthIssuesAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseInconclusiveHealthIssuesAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseInconclusiveHealthIssuesAdminUpdate
         * @name AssociationHorseInconclusiveHealthIssuesAdminUpdateCreate
         * @request POST:/api/AssociationHorseInconclusiveHealthIssuesAdminUpdate
         * @secure
         */
        associationHorseInconclusiveHealthIssuesAdminUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseInconclusiveHealthIssuesAdminUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseInconclusiveHealthIssuesUpdate
         * @name AssociationHorseInconclusiveHealthIssuesUpdateCreate
         * @request POST:/api/AssociationHorseInconclusiveHealthIssuesUpdate
         * @secure
         */
        associationHorseInconclusiveHealthIssuesUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseInconclusiveHealthIssuesUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseLastOrder
         * @name AssociationHorseLastOrderDetail
         * @request GET:/api/AssociationHorseLastOrder/{horseId}
         * @secure
         */
        associationHorseLastOrderDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseLastOrder/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseLastOrderAdmin
         * @name AssociationHorseLastOrderAdminDetail
         * @request GET:/api/AssociationHorseLastOrderAdmin/{horseId}
         * @secure
         */
        associationHorseLastOrderAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseLastOrderAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseMedia
         * @name AssociationHorseMediaDetail
         * @request GET:/api/AssociationHorseMedia/{horseId}
         * @secure
         */
        associationHorseMediaDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseMedia/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseMediaAdmin
         * @name AssociationHorseMediaAdminDetail
         * @request GET:/api/AssociationHorseMediaAdmin/{horseId}
         * @secure
         */
        associationHorseMediaAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseMediaAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseMediaDelete
         * @name AssociationHorseMediaDeleteDelete
         * @request DELETE:/api/AssociationHorseMediaDelete
         * @secure
         */
        associationHorseMediaDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AssociationHorseMediaDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseMediaDeleteAdmin
         * @name AssociationHorseMediaDeleteAdminDelete
         * @request DELETE:/api/AssociationHorseMediaDeleteAdmin
         * @secure
         */
        associationHorseMediaDeleteAdminDelete: (data, params = {}) => this.request({
            path: `/api/AssociationHorseMediaDeleteAdmin`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwner
         * @name AssociationHorseOwnerList
         * @request GET:/api/AssociationHorseOwner
         * @secure
         */
        associationHorseOwnerList: (query, params = {}) => this.request({
            path: `/api/AssociationHorseOwner`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerAdmin
         * @name AssociationHorseOwnerAdminList
         * @request GET:/api/AssociationHorseOwnerAdmin
         * @secure
         */
        associationHorseOwnerAdminList: (query, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerAdminDelete
         * @name AssociationHorseOwnerAdminDeleteDelete
         * @request DELETE:/api/AssociationHorseOwnerAdminDelete/{horseOwnerId}
         * @secure
         */
        associationHorseOwnerAdminDeleteDelete: (horseOwnerId, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerAdminDelete/${horseOwnerId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerAvatarDelete
         * @name AssociationHorseOwnerAvatarDeleteDelete
         * @request DELETE:/api/AssociationHorseOwnerAvatarDelete/{horseOwnerId}
         * @secure
         */
        associationHorseOwnerAvatarDeleteDelete: (horseOwnerId, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerAvatarDelete/${horseOwnerId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerAvatarDeleteAdmin
         * @name AssociationHorseOwnerAvatarDeleteAdminDelete
         * @request DELETE:/api/AssociationHorseOwnerAvatarDeleteAdmin/{horseOwnerId}
         * @secure
         */
        associationHorseOwnerAvatarDeleteAdminDelete: (horseOwnerId, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerAvatarDeleteAdmin/${horseOwnerId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerAvatarUpdate
         * @name AssociationHorseOwnerAvatarUpdateCreate
         * @request POST:/api/AssociationHorseOwnerAvatarUpdate
         * @secure
         */
        associationHorseOwnerAvatarUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerAvatarUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerAvatarUpdateAdmin
         * @name AssociationHorseOwnerAvatarUpdateAdminCreate
         * @request POST:/api/AssociationHorseOwnerAvatarUpdateAdmin
         * @secure
         */
        associationHorseOwnerAvatarUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerAvatarUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerByHorseId
         * @name AssociationHorseOwnerByHorseIdDetail
         * @request GET:/api/AssociationHorseOwnerByHorseId/{horseId}
         * @secure
         */
        associationHorseOwnerByHorseIdDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerByHorseId/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerByHorseIdAdmin
         * @name AssociationHorseOwnerByHorseIdAdminDetail
         * @request GET:/api/AssociationHorseOwnerByHorseIdAdmin/{horseId}
         * @secure
         */
        associationHorseOwnerByHorseIdAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerByHorseIdAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerByName
         * @name AssociationHorseOwnerByNameList
         * @request GET:/api/AssociationHorseOwnerByName
         * @secure
         */
        associationHorseOwnerByNameList: (query, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerByName`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerByNameAndOrganizationAdmin
         * @name AssociationHorseOwnerByNameAndOrganizationAdminList
         * @request GET:/api/AssociationHorseOwnerByNameAndOrganizationAdmin
         * @secure
         */
        associationHorseOwnerByNameAndOrganizationAdminList: (query, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerByNameAndOrganizationAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerCreate
         * @name AssociationHorseOwnerCreateCreate
         * @request POST:/api/AssociationHorseOwnerCreate
         * @secure
         */
        associationHorseOwnerCreateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerCreateAdmin
         * @name AssociationHorseOwnerCreateAdminCreate
         * @request POST:/api/AssociationHorseOwnerCreateAdmin
         * @secure
         */
        associationHorseOwnerCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerDelete
         * @name AssociationHorseOwnerDeleteDelete
         * @request DELETE:/api/AssociationHorseOwnerDelete/{horseOwnerId}
         * @secure
         */
        associationHorseOwnerDeleteDelete: (horseOwnerId, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerDelete/${horseOwnerId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerDetails
         * @name AssociationHorseOwnerDetailsDetail
         * @request GET:/api/AssociationHorseOwnerDetails/{ownerId}
         * @secure
         */
        associationHorseOwnerDetailsDetail: (ownerId, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerDetails/${ownerId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerDetailsAdmin
         * @name AssociationHorseOwnerDetailsAdminDetail
         * @request GET:/api/AssociationHorseOwnerDetailsAdmin/{ownerId}
         * @secure
         */
        associationHorseOwnerDetailsAdminDetail: (ownerId, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerDetailsAdmin/${ownerId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerUpdate
         * @name AssociationHorseOwnerUpdateUpdate
         * @request PUT:/api/AssociationHorseOwnerUpdate
         * @secure
         */
        associationHorseOwnerUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseOwnerUpdateAdmin
         * @name AssociationHorseOwnerUpdateAdminUpdate
         * @request PUT:/api/AssociationHorseOwnerUpdateAdmin
         * @secure
         */
        associationHorseOwnerUpdateAdminUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseOwnerUpdateAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorsePanels
         * @name AssociationHorsePanelsDetail
         * @request GET:/api/AssociationHorsePanels/{horseId}
         * @secure
         */
        associationHorsePanelsDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorsePanels/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorsePanelsAdmin
         * @name AssociationHorsePanelsAdminDetail
         * @request GET:/api/AssociationHorsePanelsAdmin/{horseId}
         * @secure
         */
        associationHorsePanelsAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorsePanelsAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseParentage
         * @name AssociationHorseParentageDetail
         * @request GET:/api/AssociationHorseParentage/{horseId}
         * @secure
         */
        associationHorseParentageDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseParentage/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseParentageAdmin
         * @name AssociationHorseParentageAdminDetail
         * @request GET:/api/AssociationHorseParentageAdmin/{horseId}
         * @secure
         */
        associationHorseParentageAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseParentageAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseParentageAdminUpdate
         * @name AssociationHorseParentageAdminUpdateUpdate
         * @request PUT:/api/AssociationHorseParentageAdminUpdate
         * @secure
         */
        associationHorseParentageAdminUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseParentageAdminUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseParentageUpdate
         * @name AssociationHorseParentageUpdateUpdate
         * @request PUT:/api/AssociationHorseParentageUpdate
         * @secure
         */
        associationHorseParentageUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseParentageUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseRegistries
         * @name AssociationHorseRegistriesDetail
         * @request GET:/api/AssociationHorseRegistries/{horseId}
         * @secure
         */
        associationHorseRegistriesDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseRegistries/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseRegistriesAdmin
         * @name AssociationHorseRegistriesAdminDetail
         * @request GET:/api/AssociationHorseRegistriesAdmin/{horseId}
         * @secure
         */
        associationHorseRegistriesAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseRegistriesAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseRegistriesAdminUpdate
         * @name AssociationHorseRegistriesAdminUpdateUpdate
         * @request PUT:/api/AssociationHorseRegistriesAdminUpdate
         * @secure
         */
        associationHorseRegistriesAdminUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseRegistriesAdminUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseRegistriesUpdate
         * @name AssociationHorseRegistriesUpdateUpdate
         * @request PUT:/api/AssociationHorseRegistriesUpdate
         * @secure
         */
        associationHorseRegistriesUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseRegistriesUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseReportsAdmin
         * @name AssociationHorseReportsAdminDetail
         * @request GET:/api/AssociationHorseReportsAdmin/{horseId}
         * @secure
         */
        associationHorseReportsAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationHorseReportsAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorses
         * @name AssociationHorsesList
         * @request GET:/api/AssociationHorses
         * @secure
         */
        associationHorsesList: (query, params = {}) => this.request({
            path: `/api/AssociationHorses`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseUpdate
         * @name AssociationHorseUpdateUpdate
         * @request PUT:/api/AssociationHorseUpdate
         * @secure
         */
        associationHorseUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseVideosUpdate
         * @name AssociationHorseVideosUpdateCreate
         * @request POST:/api/AssociationHorseVideosUpdate
         * @secure
         */
        associationHorseVideosUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseVideosUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationHorseVideosUpdateAdmin
         * @name AssociationHorseVideosUpdateAdminCreate
         * @request POST:/api/AssociationHorseVideosUpdateAdmin
         * @secure
         */
        associationHorseVideosUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationHorseVideosUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReport
         * @name AssociationOnlineReportAbilitiesDetail
         * @request GET:/api/AssociationOnlineReport/Abilities/{horseId}
         * @secure
         */
        associationOnlineReportAbilitiesDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationOnlineReport/Abilities/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReport
         * @name AssociationOnlineReportHealthIssuesDetail
         * @request GET:/api/AssociationOnlineReport/HealthIssues/{horseId}
         * @secure
         */
        associationOnlineReportHealthIssuesDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationOnlineReport/HealthIssues/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReport
         * @name AssociationOnlineReportHorseDetail
         * @request GET:/api/AssociationOnlineReport/Horse/{horseId}
         * @secure
         */
        associationOnlineReportHorseDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationOnlineReport/Horse/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReport
         * @name AssociationOnlineReportHorseDetailsDetail
         * @request GET:/api/AssociationOnlineReport/HorseDetails/{horseId}
         * @secure
         */
        associationOnlineReportHorseDetailsDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationOnlineReport/HorseDetails/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReport
         * @name AssociationOnlineReportOwnerDetail
         * @request GET:/api/AssociationOnlineReport/Owner/{horseId}
         * @secure
         */
        associationOnlineReportOwnerDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationOnlineReport/Owner/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReport
         * @name AssociationOnlineReportPanelsDetail
         * @request GET:/api/AssociationOnlineReport/Panels/{horseId}
         * @secure
         */
        associationOnlineReportPanelsDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationOnlineReport/Panels/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReport
         * @name AssociationOnlineReportPartialColorsDetail
         * @request GET:/api/AssociationOnlineReport/PartialColors/{horseId}
         * @secure
         */
        associationOnlineReportPartialColorsDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationOnlineReport/PartialColors/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReport
         * @name AssociationOnlineReportSummaryAbilitiesDetail
         * @request GET:/api/AssociationOnlineReport/Summary/Abilities/{horseId}
         * @secure
         */
        associationOnlineReportSummaryAbilitiesDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationOnlineReport/Summary/Abilities/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReport
         * @name AssociationOnlineReportSummaryColorsDetail
         * @request GET:/api/AssociationOnlineReport/Summary/Colors/{horseId}
         * @secure
         */
        associationOnlineReportSummaryColorsDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationOnlineReport/Summary/Colors/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReport
         * @name AssociationOnlineReportGeneticVariantsDetail
         * @request GET:/api/AssociationOnlineReport/GeneticVariants/{horseId}
         * @secure
         */
        associationOnlineReportGeneticVariantsDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationOnlineReport/GeneticVariants/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReport
         * @name AssociationOnlineReportSummaryHealthIssuesDetail
         * @request GET:/api/AssociationOnlineReport/Summary/HealthIssues/{horseId}
         * @secure
         */
        associationOnlineReportSummaryHealthIssuesDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationOnlineReport/Summary/HealthIssues/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportAbilitiesDetail
         * @request GET:/api/Admin/AssociationOnlineReport/Abilities/{horseId}
         * @secure
         */
        adminAssociationOnlineReportAbilitiesDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/Abilities/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportHealthIssuesDetail
         * @request GET:/api/Admin/AssociationOnlineReport/HealthIssues/{horseId}
         * @secure
         */
        adminAssociationOnlineReportHealthIssuesDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/HealthIssues/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportHorseDetailsDetail
         * @request GET:/api/Admin/AssociationOnlineReport/HorseDetails/{horseId}
         * @secure
         */
        adminAssociationOnlineReportHorseDetailsDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/HorseDetails/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportPanelsDetail
         * @request GET:/api/Admin/AssociationOnlineReport/Panels/{horseId}
         * @secure
         */
        adminAssociationOnlineReportPanelsDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/Panels/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportPartialColorsDetail
         * @request GET:/api/Admin/AssociationOnlineReport/PartialColors/{horseId}
         * @secure
         */
        adminAssociationOnlineReportPartialColorsDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/PartialColors/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportSummaryAbilitiesDetail
         * @request GET:/api/Admin/AssociationOnlineReport/Summary/Abilities/{horseId}
         * @secure
         */
        adminAssociationOnlineReportSummaryAbilitiesDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/Summary/Abilities/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportSummaryColorsDetail
         * @request GET:/api/Admin/AssociationOnlineReport/Summary/Colors/{horseId}
         * @secure
         */
        adminAssociationOnlineReportSummaryColorsDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/Summary/Colors/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportGeneticVariantsDetail
         * @request GET:/api/Admin/AssociationOnlineReport/GeneticVariants/{horseId}
         * @secure
         */
        adminAssociationOnlineReportGeneticVariantsDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/GeneticVariants/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportSummaryHealthIssuesDetail
         * @request GET:/api/Admin/AssociationOnlineReport/Summary/HealthIssues/{horseId}
         * @secure
         */
        adminAssociationOnlineReportSummaryHealthIssuesDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/Summary/HealthIssues/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportHorseDetail
         * @request GET:/api/Admin/AssociationOnlineReport/Horse/{horseId}
         * @secure
         */
        adminAssociationOnlineReportHorseDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/Horse/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportOwnerDetail
         * @request GET:/api/Admin/AssociationOnlineReport/Owner/{horseId}
         * @secure
         */
        adminAssociationOnlineReportOwnerDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/Owner/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportGeneticNotesDelete
         * @request DELETE:/api/Admin/AssociationOnlineReport/GeneticNotes
         * @secure
         */
        adminAssociationOnlineReportGeneticNotesDelete: (data, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/GeneticNotes`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportAdmin
         * @name AdminAssociationOnlineReportGeneticNotesCreate
         * @request POST:/api/Admin/AssociationOnlineReport/GeneticNotes
         * @secure
         */
        adminAssociationOnlineReportGeneticNotesCreate: (data, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReport/GeneticNotes`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportReviewAdmin
         * @name AdminAssociationOnlineReportReviewAbilitiesDetail
         * @request GET:/api/Admin/AssociationOnlineReportReview/Abilities/{orderId}
         * @secure
         */
        adminAssociationOnlineReportReviewAbilitiesDetail: (orderId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReportReview/Abilities/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportReviewAdmin
         * @name AdminAssociationOnlineReportReviewGeneticNotesDetail
         * @request GET:/api/Admin/AssociationOnlineReportReview/GeneticNotes/{horseId}
         * @secure
         */
        adminAssociationOnlineReportReviewGeneticNotesDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReportReview/GeneticNotes/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportReviewAdmin
         * @name AdminAssociationOnlineReportReviewHealthIssuesDetail
         * @request GET:/api/Admin/AssociationOnlineReportReview/HealthIssues/{orderId}
         * @secure
         */
        adminAssociationOnlineReportReviewHealthIssuesDetail: (orderId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReportReview/HealthIssues/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportReviewAdmin
         * @name AdminAssociationOnlineReportReviewPanelsDetail
         * @request GET:/api/Admin/AssociationOnlineReportReview/Panels/{orderId}
         * @secure
         */
        adminAssociationOnlineReportReviewPanelsDetail: (orderId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReportReview/Panels/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportReviewAdmin
         * @name AdminAssociationOnlineReportReviewPartialColorsDetail
         * @request GET:/api/Admin/AssociationOnlineReportReview/PartialColors/{orderId}
         * @secure
         */
        adminAssociationOnlineReportReviewPartialColorsDetail: (orderId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReportReview/PartialColors/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportReviewAdmin
         * @name AdminAssociationOnlineReportReviewRequiredTestsDetail
         * @request GET:/api/Admin/AssociationOnlineReportReview/RequiredTests/{orderId}
         * @secure
         */
        adminAssociationOnlineReportReviewRequiredTestsDetail: (orderId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReportReview/RequiredTests/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportReviewAdmin
         * @name AdminAssociationOnlineReportReviewStatusCreate
         * @request POST:/api/Admin/AssociationOnlineReportReview/Status
         * @secure
         */
        adminAssociationOnlineReportReviewStatusCreate: (data, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReportReview/Status`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportReviewAdmin
         * @name AdminAssociationOnlineReportReviewSummaryAbilitiesDetail
         * @request GET:/api/Admin/AssociationOnlineReportReview/Summary/Abilities/{orderId}
         * @secure
         */
        adminAssociationOnlineReportReviewSummaryAbilitiesDetail: (orderId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReportReview/Summary/Abilities/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportReviewAdmin
         * @name AdminAssociationOnlineReportReviewSummaryColorsDetail
         * @request GET:/api/Admin/AssociationOnlineReportReview/Summary/Colors/{orderId}
         * @secure
         */
        adminAssociationOnlineReportReviewSummaryColorsDetail: (orderId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReportReview/Summary/Colors/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportReviewAdmin
         * @name AdminAssociationOnlineReportReviewGeneticVariantsDetail
         * @request GET:/api/Admin/AssociationOnlineReportReview/GeneticVariants/{orderId}
         * @secure
         */
        adminAssociationOnlineReportReviewGeneticVariantsDetail: (orderId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReportReview/GeneticVariants/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOnlineReportReviewAdmin
         * @name AdminAssociationOnlineReportReviewSummaryHealthIssuesDetail
         * @request GET:/api/Admin/AssociationOnlineReportReview/Summary/HealthIssues/{orderId}
         * @secure
         */
        adminAssociationOnlineReportReviewSummaryHealthIssuesDetail: (orderId, params = {}) => this.request({
            path: `/api/Admin/AssociationOnlineReportReview/Summary/HealthIssues/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderCreate
         * @name AssociationOrderCreateCreate
         * @request POST:/api/AssociationOrderCreate
         * @secure
         */
        associationOrderCreateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationOrderCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderCreateAdmin
         * @name AssociationOrderCreateAdminCreate
         * @request POST:/api/AssociationOrderCreateAdmin
         * @secure
         */
        associationOrderCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationOrderCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderDelete
         * @name AssociationOrderDeleteDelete
         * @request DELETE:/api/AssociationOrderDelete/{orderId}
         * @secure
         */
        associationOrderDeleteDelete: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderDelete/${orderId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderDeleteAdmin
         * @name AssociationOrderDeleteAdminDelete
         * @request DELETE:/api/AssociationOrderDeleteAdmin/{orderId}
         * @secure
         */
        associationOrderDeleteAdminDelete: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderDeleteAdmin/${orderId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderDetails
         * @name AssociationOrderDetailsDetail
         * @request GET:/api/AssociationOrderDetails/{orderId}
         * @secure
         */
        associationOrderDetailsDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderDetails/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderDetailsAdmin
         * @name AssociationOrderDetailsAdminDetail
         * @request GET:/api/AssociationOrderDetailsAdmin/{orderId}
         * @secure
         */
        associationOrderDetailsAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderDetailsAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderGroupSubmissionForm
         * @name AssociationOrderGroupSubmissionFormDetail
         * @request GET:/api/AssociationOrderGroupSubmissionForm/{token}
         * @secure
         */
        associationOrderGroupSubmissionFormDetail: (token, params = {}) => this.request({
            path: `/api/AssociationOrderGroupSubmissionForm/${token}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderHorseReportStatuses
         * @name AssociationOrderHorseReportStatusesDetail
         * @request GET:/api/AssociationOrderHorseReportStatuses/{orderId}
         * @secure
         */
        associationOrderHorseReportStatusesDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderHorseReportStatuses/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderHorses
         * @name AssociationOrderHorsesList
         * @request GET:/api/AssociationOrderHorses
         * @secure
         */
        associationOrderHorsesList: (query, params = {}) => this.request({
            path: `/api/AssociationOrderHorses`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderHorsesAdmin
         * @name AssociationOrderHorsesAdminList
         * @request GET:/api/AssociationOrderHorsesAdmin
         * @secure
         */
        associationOrderHorsesAdminList: (query, params = {}) => this.request({
            path: `/api/AssociationOrderHorsesAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderPackageAndTestDetails
         * @name AssociationOrderPackageAndTestDetailsDetail
         * @request GET:/api/AssociationOrderPackageAndTestDetails/{orderId}
         * @secure
         */
        associationOrderPackageAndTestDetailsDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderPackageAndTestDetails/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderPayment
         * @name AssociationOrderPaymentCreate
         * @request POST:/api/AssociationOrderPayment
         * @secure
         */
        associationOrderPaymentCreate: (data, params = {}) => this.request({
            path: `/api/AssociationOrderPayment`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderPaymentDetailsAdmin
         * @name AssociationOrderPaymentDetailsAdminDetail
         * @request GET:/api/AssociationOrderPaymentDetailsAdmin/{orderId}
         * @secure
         */
        associationOrderPaymentDetailsAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderPaymentDetailsAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderPaymentStatusDetailsAdmin
         * @name AssociationOrderPaymentStatusDetailsAdminDetail
         * @request GET:/api/AssociationOrderPaymentStatusDetailsAdmin/{orderId}
         * @secure
         */
        associationOrderPaymentStatusDetailsAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderPaymentStatusDetailsAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderPaymentStatusUpdate
         * @name AssociationOrderPaymentStatusUpdateCreate
         * @request POST:/api/AssociationOrderPaymentStatusUpdate
         * @secure
         */
        associationOrderPaymentStatusUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationOrderPaymentStatusUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderReleaseAdmin
         * @name AssociationOrderReleaseAdminCreate
         * @request POST:/api/AssociationOrderReleaseAdmin
         * @secure
         */
        associationOrderReleaseAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationOrderReleaseAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderReportDelete
         * @name AssociationOrderReportDeleteDelete
         * @request DELETE:/api/AssociationOrderReportDelete
         * @secure
         */
        associationOrderReportDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AssociationOrderReportDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderReportDetailsAdmin
         * @name AssociationOrderReportDetailsAdminDetail
         * @request GET:/api/AssociationOrderReportDetailsAdmin/{orderId}
         * @secure
         */
        associationOrderReportDetailsAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderReportDetailsAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderReportUpdate
         * @name AssociationOrderReportUpdateCreate
         * @request POST:/api/AssociationOrderReportUpdate
         * @secure
         */
        associationOrderReportUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationOrderReportUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderRequiredTestsAdmin
         * @name AssociationOrderRequiredTestsAdminDetail
         * @request GET:/api/AssociationOrderRequiredTestsAdmin/{orderId}
         * @secure
         */
        associationOrderRequiredTestsAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderRequiredTestsAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrders
         * @name AssociationOrdersList
         * @request GET:/api/AssociationOrders
         * @secure
         */
        associationOrdersList: (query, params = {}) => this.request({
            path: `/api/AssociationOrders`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrdersAdmin
         * @name AssociationOrdersAdminList
         * @request GET:/api/AssociationOrdersAdmin
         * @secure
         */
        associationOrdersAdminList: (query, params = {}) => this.request({
            path: `/api/AssociationOrdersAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderSampleDetailsAdmin
         * @name AssociationOrderSampleDetailsAdminDetail
         * @request GET:/api/AssociationOrderSampleDetailsAdmin/{orderId}
         * @secure
         */
        associationOrderSampleDetailsAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderSampleDetailsAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrdersPaymentSummary
         * @name AssociationOrdersPaymentSummaryDetail
         * @request GET:/api/AssociationOrdersPaymentSummary/{token}
         * @secure
         */
        associationOrdersPaymentSummaryDetail: (token, params = {}) => this.request({
            path: `/api/AssociationOrdersPaymentSummary/${token}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrdersSummary
         * @name AssociationOrdersSummaryCreate
         * @request POST:/api/AssociationOrdersSummary
         * @secure
         */
        associationOrdersSummaryCreate: (data, params = {}) => this.request({
            path: `/api/AssociationOrdersSummary`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderStatusesDetails
         * @name AssociationOrderStatusesDetailsDetail
         * @request GET:/api/AssociationOrderStatusesDetails/{orderId}
         * @secure
         */
        associationOrderStatusesDetailsDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderStatusesDetails/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderStatusesDetailsAdmin
         * @name AssociationOrderStatusesDetailsAdminDetail
         * @request GET:/api/AssociationOrderStatusesDetailsAdmin/{orderId}
         * @secure
         */
        associationOrderStatusesDetailsAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderStatusesDetailsAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderSubmissionForm
         * @name AssociationOrderSubmissionFormDetail
         * @request GET:/api/AssociationOrderSubmissionForm/{orderId}
         * @secure
         */
        associationOrderSubmissionFormDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderSubmissionForm/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderSubmissionFormAdmin
         * @name AssociationOrderSubmissionFormAdminDetail
         * @request GET:/api/AssociationOrderSubmissionFormAdmin/{orderId}
         * @secure
         */
        associationOrderSubmissionFormAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderSubmissionFormAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderSummaryAdmin
         * @name AssociationOrderSummaryAdminCreate
         * @request POST:/api/AssociationOrderSummaryAdmin
         * @secure
         */
        associationOrderSummaryAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationOrderSummaryAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrderTestsDetailsAdmin
         * @name AssociationOrderTestsDetailsAdminDetail
         * @request GET:/api/AssociationOrderTestsDetailsAdmin/{orderId}
         * @secure
         */
        associationOrderTestsDetailsAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationOrderTestsDetailsAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrganizationAvatar
         * @name AssociationOrganizationAvatarList
         * @request GET:/api/AssociationOrganizationAvatar
         * @secure
         */
        associationOrganizationAvatarList: (params = {}) => this.request({
            path: `/api/AssociationOrganizationAvatar`,
            method: "GET",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrganizationAvatarAdmin
         * @name AssociationOrganizationAvatarAdminDetail
         * @request GET:/api/AssociationOrganizationAvatarAdmin/{organizationId}
         * @secure
         */
        associationOrganizationAvatarAdminDetail: (organizationId, params = {}) => this.request({
            path: `/api/AssociationOrganizationAvatarAdmin/${organizationId}`,
            method: "GET",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrganizationAvatarUpdate
         * @name AssociationOrganizationAvatarUpdateCreate
         * @request POST:/api/AssociationOrganizationAvatarUpdate
         * @secure
         */
        associationOrganizationAvatarUpdateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationOrganizationAvatarUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOrganizationAvatarUpdateAdmin
         * @name AssociationOrganizationAvatarUpdateAdminCreate
         * @request POST:/api/AssociationOrganizationAvatarUpdateAdmin
         * @secure
         */
        associationOrganizationAvatarUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationOrganizationAvatarUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOwnerProfile
         * @name AssociationOwnerProfileDetail
         * @request GET:/api/AssociationOwnerProfile/{id}
         * @secure
         */
        associationOwnerProfileDetail: (id, params = {}) => this.request({
            path: `/api/AssociationOwnerProfile/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOwnerProfileAdmin
         * @name AssociationOwnerProfileAdminDetail
         * @request GET:/api/AssociationOwnerProfileAdmin/{id}
         * @secure
         */
        associationOwnerProfileAdminDetail: (id, params = {}) => this.request({
            path: `/api/AssociationOwnerProfileAdmin/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOwnerProfileHorses
         * @name AssociationOwnerProfileHorsesList
         * @request GET:/api/AssociationOwnerProfileHorses
         * @secure
         */
        associationOwnerProfileHorsesList: (query, params = {}) => this.request({
            path: `/api/AssociationOwnerProfileHorses`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationOwnerProfileHorsesAdmin
         * @name AssociationOwnerProfileHorsesAdminList
         * @request GET:/api/AssociationOwnerProfileHorsesAdmin
         * @secure
         */
        associationOwnerProfileHorsesAdminList: (query, params = {}) => this.request({
            path: `/api/AssociationOwnerProfileHorsesAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackageBundleUpdateAdmin
         * @name AssociationPackageBundleUpdateAdminPartialUpdate
         * @request PATCH:/api/AssociationPackageBundleUpdateAdmin
         * @secure
         */
        associationPackageBundleUpdateAdminPartialUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationPackageBundleUpdateAdmin`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackageDetailsTable
         * @name AssociationPackageDetailsTableDetail
         * @request GET:/api/AssociationPackageDetailsTable/{packageId}
         * @secure
         */
        associationPackageDetailsTableDetail: (packageId, params = {}) => this.request({
            path: `/api/AssociationPackageDetailsTable/${packageId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackageGroupsTable
         * @name AssociationPackageGroupsTableList
         * @request GET:/api/AssociationPackageGroupsTable
         * @secure
         */
        associationPackageGroupsTableList: (query, params = {}) => this.request({
            path: `/api/AssociationPackageGroupsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackageGroupsTableCreate
         * @name AssociationPackageGroupsTableCreateCreate
         * @request POST:/api/AssociationPackageGroupsTableCreate
         * @secure
         */
        associationPackageGroupsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationPackageGroupsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackageGroupsTableDelete
         * @name AssociationPackageGroupsTableDeleteDelete
         * @request DELETE:/api/AssociationPackageGroupsTableDelete
         * @secure
         */
        associationPackageGroupsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AssociationPackageGroupsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackageGroupsTableDetails
         * @name AssociationPackageGroupsTableDetailsDetail
         * @request GET:/api/AssociationPackageGroupsTableDetails/{entityId}
         * @secure
         */
        associationPackageGroupsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/AssociationPackageGroupsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackageGroupsTableUpdate
         * @name AssociationPackageGroupsTableUpdateUpdate
         * @request PUT:/api/AssociationPackageGroupsTableUpdate
         * @secure
         */
        associationPackageGroupsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationPackageGroupsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackageSetStatusAdmin
         * @name AssociationPackageSetStatusAdminPartialUpdate
         * @request PATCH:/api/AssociationPackageSetStatusAdmin
         * @secure
         */
        associationPackageSetStatusAdminPartialUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationPackageSetStatusAdmin`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackagesTable
         * @name AssociationPackagesTableList
         * @request GET:/api/AssociationPackagesTable
         * @secure
         */
        associationPackagesTableList: (query, params = {}) => this.request({
            path: `/api/AssociationPackagesTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackagesTableCreate
         * @name AssociationPackagesTableCreateCreate
         * @request POST:/api/AssociationPackagesTableCreate
         * @secure
         */
        associationPackagesTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationPackagesTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackagesTableDelete
         * @name AssociationPackagesTableDeleteDelete
         * @request DELETE:/api/AssociationPackagesTableDelete
         * @secure
         */
        associationPackagesTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AssociationPackagesTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPackagesTableUpdate
         * @name AssociationPackagesTableUpdateUpdate
         * @request PUT:/api/AssociationPackagesTableUpdate
         * @secure
         */
        associationPackagesTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationPackagesTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPaymentHorses
         * @name AssociationPaymentHorsesDetail
         * @request GET:/api/AssociationPaymentHorses/{token}
         * @secure
         */
        associationPaymentHorsesDetail: (token, params = {}) => this.request({
            path: `/api/AssociationPaymentHorses/${token}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPublicProfileAdmin
         * @name AssociationPublicProfileAdminDetail
         * @request GET:/api/AssociationPublicProfileAdmin/{associationId}
         * @secure
         */
        associationPublicProfileAdminDetail: (associationId, params = {}) => this.request({
            path: `/api/AssociationPublicProfileAdmin/${associationId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPublicProfileAssociation
         * @name AssociationPublicProfileAssociationDetail
         * @request GET:/api/AssociationPublicProfileAssociation/{associationId}
         * @secure
         */
        associationPublicProfileAssociationDetail: (associationId, params = {}) => this.request({
            path: `/api/AssociationPublicProfileAssociation/${associationId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPublicProfileHorsesAdmin
         * @name AssociationPublicProfileHorsesAdminList
         * @request GET:/api/AssociationPublicProfileHorsesAdmin
         * @secure
         */
        associationPublicProfileHorsesAdminList: (query, params = {}) => this.request({
            path: `/api/AssociationPublicProfileHorsesAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPublicProfileHorsesAssociation
         * @name AssociationPublicProfileHorsesAssociationList
         * @request GET:/api/AssociationPublicProfileHorsesAssociation
         * @secure
         */
        associationPublicProfileHorsesAssociationList: (query, params = {}) => this.request({
            path: `/api/AssociationPublicProfileHorsesAssociation`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPublicProfileHorsesUser
         * @name AssociationPublicProfileHorsesUserList
         * @request GET:/api/AssociationPublicProfileHorsesUser
         * @secure
         */
        associationPublicProfileHorsesUserList: (query, params = {}) => this.request({
            path: `/api/AssociationPublicProfileHorsesUser`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationPublicProfileUser
         * @name AssociationPublicProfileUserDetail
         * @request GET:/api/AssociationPublicProfileUser/{associationId}
         * @secure
         */
        associationPublicProfileUserDetail: (associationId, params = {}) => this.request({
            path: `/api/AssociationPublicProfileUser/${associationId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationRelatedOrdersAdmin
         * @name AssociationRelatedOrdersAdminDetail
         * @request GET:/api/AssociationRelatedOrdersAdmin/{orderId}
         * @secure
         */
        associationRelatedOrdersAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/AssociationRelatedOrdersAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationSampleAdmin
         * @name AssociationSampleAdminDetail
         * @request GET:/api/AssociationSampleAdmin/{horseId}
         * @secure
         */
        associationSampleAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/AssociationSampleAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationSampleResubmitAdmin
         * @name AssociationSampleResubmitAdminCreate
         * @request POST:/api/AssociationSampleResubmitAdmin
         * @secure
         */
        associationSampleResubmitAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationSampleResubmitAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationSampleStatusUpdateAdmin
         * @name AssociationSampleStatusUpdateAdminCreate
         * @request POST:/api/AssociationSampleStatusUpdateAdmin
         * @secure
         */
        associationSampleStatusUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/AssociationSampleStatusUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationSampleUpdate
         * @name AssociationSampleUpdateChangeSidCreate
         * @request POST:/api/AssociationSampleUpdate/ChangeSID
         * @secure
         */
        associationSampleUpdateChangeSidCreate: (data, params = {}) => this.request({
            path: `/api/AssociationSampleUpdate/ChangeSID`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationsTable
         * @name AssociationsTableList
         * @request GET:/api/AssociationsTable
         * @secure
         */
        associationsTableList: (query, params = {}) => this.request({
            path: `/api/AssociationsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationsTableCreate
         * @name AssociationsTableCreateCreate
         * @request POST:/api/AssociationsTableCreate
         * @secure
         */
        associationsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/AssociationsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationsTableDelete
         * @name AssociationsTableDeleteDelete
         * @request DELETE:/api/AssociationsTableDelete
         * @secure
         */
        associationsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/AssociationsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationsTableDetails
         * @name AssociationsTableDetailsDetail
         * @request GET:/api/AssociationsTableDetails/{entityId}
         * @secure
         */
        associationsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/AssociationsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags AssociationsTableUpdate
         * @name AssociationsTableUpdateUpdate
         * @request PUT:/api/AssociationsTableUpdate
         * @secure
         */
        associationsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/AssociationsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Authentication
         * @name AuthenticationCreate
         * @request POST:/api/Authentication
         * @secure
         */
        authenticationCreate: (data, params = {}) => this.request({
            path: `/api/Authentication`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Authentication
         * @name ForgotPasswordCreate
         * @request POST:/api/ForgotPassword
         * @secure
         */
        forgotPasswordCreate: (data, params = {}) => this.request({
            path: `/api/ForgotPassword`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Authentication
         * @name RefreshTokenCreate
         * @request POST:/api/RefreshToken
         * @secure
         */
        refreshTokenCreate: (data, params = {}) => this.request({
            path: `/api/RefreshToken`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Authentication
         * @name ResetPasswordCreate
         * @request POST:/api/ResetPassword
         * @secure
         */
        resetPasswordCreate: (data, params = {}) => this.request({
            path: `/api/ResetPassword`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags BlockedUsers
         * @name BlockedUsersCreateCreate
         * @request POST:/api/BlockedUsersCreate/{userId}
         * @secure
         */
        blockedUsersCreateCreate: (userId, params = {}) => this.request({
            path: `/api/BlockedUsersCreate/${userId}`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags BlockedUsers
         * @name BlockedUsersDeleteDelete
         * @request DELETE:/api/BlockedUsersDelete/{userId}
         * @secure
         */
        blockedUsersDeleteDelete: (userId, params = {}) => this.request({
            path: `/api/BlockedUsersDelete/${userId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags BlockedUsers
         * @name BlockedUsersReadList
         * @request GET:/api/BlockedUsersRead
         * @secure
         */
        blockedUsersReadList: (params = {}) => this.request({
            path: `/api/BlockedUsersRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BreedsTable
         * @name BreedsTableList
         * @request GET:/api/BreedsTable
         * @secure
         */
        breedsTableList: (query, params = {}) => this.request({
            path: `/api/BreedsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BreedsTableCreate
         * @name BreedsTableCreateCreate
         * @request POST:/api/BreedsTableCreate
         * @secure
         */
        breedsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/BreedsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BreedsTableDelete
         * @name BreedsTableDeleteDelete
         * @request DELETE:/api/BreedsTableDelete
         * @secure
         */
        breedsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/BreedsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BreedsTableDetails
         * @name BreedsTableDetailsDetail
         * @request GET:/api/BreedsTableDetails/{entityId}
         * @secure
         */
        breedsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/BreedsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BreedsTableUpdate
         * @name BreedsTableUpdateUpdate
         * @request PUT:/api/BreedsTableUpdate
         * @secure
         */
        breedsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/BreedsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildFoal
         * @name BuildFoalList
         * @request GET:/api/BuildFoal
         * @secure
         */
        buildFoalList: (query, params = {}) => this.request({
            path: `/api/BuildFoal`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildFoal
         * @name BuildFoalFullParentDetail
         * @request GET:/api/BuildFoalFullParent/{animalId}
         * @secure
         */
        buildFoalFullParentDetail: (animalId, params = {}) => this.request({
            path: `/api/BuildFoalFullParent/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildFoalAssociation
         * @name BuildFoalAssociationList
         * @request GET:/api/BuildFoalAssociation
         * @secure
         */
        buildFoalAssociationList: (query, params = {}) => this.request({
            path: `/api/BuildFoalAssociation`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildFoalFullParentAssociation
         * @name BuildFoalFullParentAssociationDetail
         * @request GET:/api/BuildFoalFullParentAssociation/{horseId}
         * @secure
         */
        buildFoalFullParentAssociationDetail: (horseId, params = {}) => this.request({
            path: `/api/BuildFoalFullParentAssociation/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildHorseDefaultConfigAdmin
         * @name BuildHorseDefaultConfigAdminList
         * @request GET:/api/BuildHorseDefaultConfigAdmin
         * @secure
         */
        buildHorseDefaultConfigAdminList: (params = {}) => this.request({
            path: `/api/BuildHorseDefaultConfigAdmin`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildHorseDefaultConfigUpdateAdmin
         * @name BuildHorseDefaultConfigUpdateAdminUpdate
         * @request PUT:/api/BuildHorseDefaultConfigUpdateAdmin
         * @secure
         */
        buildHorseDefaultConfigUpdateAdminUpdate: (data, params = {}) => this.request({
            path: `/api/BuildHorseDefaultConfigUpdateAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildHorses
         * @name BuildHorsesCreateCreate
         * @request POST:/api/BuildHorsesCreate
         * @secure
         */
        buildHorsesCreateCreate: (data, params = {}) => this.request({
            path: `/api/BuildHorsesCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildHorses
         * @name BuildHorsesDeleteDelete
         * @request DELETE:/api/BuildHorsesDelete/{requestId}
         * @secure
         */
        buildHorsesDeleteDelete: (requestId, query, params = {}) => this.request({
            path: `/api/BuildHorsesDelete/${requestId}`,
            method: "DELETE",
            query: query,
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildHorses
         * @name BuildHorsesDetailsDetail
         * @request GET:/api/BuildHorsesDetails/{requestId}
         * @secure
         */
        buildHorsesDetailsDetail: (requestId, query, params = {}) => this.request({
            path: `/api/BuildHorsesDetails/${requestId}`,
            method: "GET",
            query: query,
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildHorses
         * @name BuildHorsesReadList
         * @request GET:/api/BuildHorsesRead
         * @secure
         */
        buildHorsesReadList: (params = {}) => this.request({
            path: `/api/BuildHorsesRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildHorses
         * @name BuildHorsesResultsCreate
         * @request POST:/api/BuildHorsesResults
         * @secure
         */
        buildHorsesResultsCreate: (data, params = {}) => this.request({
            path: `/api/BuildHorsesResults`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildHorses
         * @name BuildHorsesCreate
         * @request POST:/api/BuildHorses
         * @secure
         */
        buildHorsesCreate: (data, params = {}) => this.request({
            path: `/api/BuildHorses`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildHorsesAssociation
         * @name BuildHorsesAssociationCreate
         * @request POST:/api/BuildHorsesAssociation
         * @secure
         */
        buildHorsesAssociationCreate: (data, params = {}) => this.request({
            path: `/api/BuildHorsesAssociation`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags BuildHorseTestList
         * @name BuildHorseTestListList
         * @request GET:/api/BuildHorseTestList
         * @secure
         */
        buildHorseTestListList: (params = {}) => this.request({
            path: `/api/BuildHorseTestList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ColorAdmin
         * @name ColorAdminImageCreate
         * @request POST:/api/ColorAdmin/Image
         * @secure
         */
        colorAdminImageCreate: (data, params = {}) => this.request({
            path: `/api/ColorAdmin/Image`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ColorCombinationsTable
         * @name ColorCombinationsTableList
         * @request GET:/api/ColorCombinationsTable
         * @secure
         */
        colorCombinationsTableList: (query, params = {}) => this.request({
            path: `/api/ColorCombinationsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ColorCombinationsTableCreate
         * @name ColorCombinationsTableCreateCreate
         * @request POST:/api/ColorCombinationsTableCreate
         * @secure
         */
        colorCombinationsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/ColorCombinationsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ColorCombinationsTableDelete
         * @name ColorCombinationsTableDeleteDelete
         * @request DELETE:/api/ColorCombinationsTableDelete
         * @secure
         */
        colorCombinationsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/ColorCombinationsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ColorCombinationsTableDetails
         * @name ColorCombinationsTableDetailsDetail
         * @request GET:/api/ColorCombinationsTableDetails/{entityId}
         * @secure
         */
        colorCombinationsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/ColorCombinationsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ColorCombinationsTableUpdate
         * @name ColorCombinationsTableUpdateUpdate
         * @request PUT:/api/ColorCombinationsTableUpdate
         * @secure
         */
        colorCombinationsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/ColorCombinationsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ColorsTable
         * @name ColorsTableList
         * @request GET:/api/ColorsTable
         * @secure
         */
        colorsTableList: (query, params = {}) => this.request({
            path: `/api/ColorsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ColorsTableCreate
         * @name ColorsTableCreateCreate
         * @request POST:/api/ColorsTableCreate
         * @secure
         */
        colorsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/ColorsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ColorsTableDelete
         * @name ColorsTableDeleteDelete
         * @request DELETE:/api/ColorsTableDelete
         * @secure
         */
        colorsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/ColorsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ColorsTableDetails
         * @name ColorsTableDetailsDetail
         * @request GET:/api/ColorsTableDetails/{entityId}
         * @secure
         */
        colorsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/ColorsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ColorsTableUpdate
         * @name ColorsTableUpdateUpdate
         * @request PUT:/api/ColorsTableUpdate
         * @secure
         */
        colorsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/ColorsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags CommercialTypesTable
         * @name CommercialTypesTableList
         * @request GET:/api/CommercialTypesTable
         * @secure
         */
        commercialTypesTableList: (query, params = {}) => this.request({
            path: `/api/CommercialTypesTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags CommercialTypesTableCreate
         * @name CommercialTypesTableCreateCreate
         * @request POST:/api/CommercialTypesTableCreate
         * @secure
         */
        commercialTypesTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/CommercialTypesTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags CommercialTypesTableDelete
         * @name CommercialTypesTableDeleteDelete
         * @request DELETE:/api/CommercialTypesTableDelete
         * @secure
         */
        commercialTypesTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/CommercialTypesTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags CommercialTypesTableDetails
         * @name CommercialTypesTableDetailsDetail
         * @request GET:/api/CommercialTypesTableDetails/{entityId}
         * @secure
         */
        commercialTypesTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/CommercialTypesTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags CommercialTypesTableUpdate
         * @name CommercialTypesTableUpdateUpdate
         * @request PUT:/api/CommercialTypesTableUpdate
         * @secure
         */
        commercialTypesTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/CommercialTypesTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ConfirmUserEmailAdmin
         * @name ConfirmUserEmailAdminUpdate
         * @request PUT:/api/ConfirmUserEmailAdmin/{userId}
         * @secure
         */
        confirmUserEmailAdminUpdate: (userId, params = {}) => this.request({
            path: `/api/ConfirmUserEmailAdmin/${userId}`,
            method: "PUT",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags CouponAdmin
         * @name CouponAdminList
         * @request GET:/api/CouponAdmin
         * @secure
         */
        couponAdminList: (query, params = {}) => this.request({
            path: `/api/CouponAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags CouponCreateAdmin
         * @name CouponCreateAdminCreate
         * @request POST:/api/CouponCreateAdmin
         * @secure
         */
        couponCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/CouponCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags CouponDeleteAdmin
         * @name CouponDeleteAdminDelete
         * @request DELETE:/api/CouponDeleteAdmin
         * @secure
         */
        couponDeleteAdminDelete: (data, params = {}) => this.request({
            path: `/api/CouponDeleteAdmin`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags CouponDetailsAdmin
         * @name CouponDetailsAdminDetail
         * @request GET:/api/CouponDetailsAdmin/{couponId}
         * @secure
         */
        couponDetailsAdminDetail: (couponId, params = {}) => this.request({
            path: `/api/CouponDetailsAdmin/${couponId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags CouponStatusUpdateAdmin
         * @name CouponStatusUpdateAdminPartialUpdate
         * @request PATCH:/api/CouponStatusUpdateAdmin
         * @secure
         */
        couponStatusUpdateAdminPartialUpdate: (data, params = {}) => this.request({
            path: `/api/CouponStatusUpdateAdmin`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags CouponUpdateAdmin
         * @name CouponUpdateAdminUpdate
         * @request PUT:/api/CouponUpdateAdmin
         * @secure
         */
        couponUpdateAdminUpdate: (data, params = {}) => this.request({
            path: `/api/CouponUpdateAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags CurrentAdmin
         * @name CurrentAdminList
         * @request GET:/api/CurrentAdmin
         * @secure
         */
        currentAdminList: (params = {}) => this.request({
            path: `/api/CurrentAdmin`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags CurrentModeService
         * @name CurrentModeServiceList
         * @request GET:/api/CurrentModeService
         * @secure
         */
        currentModeServiceList: (params = {}) => this.request({
            path: `/api/CurrentModeService`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DangerGroupAdvanced
         * @name DangerGroupAdvancedList
         * @request GET:/api/DangerGroupAdvanced
         * @secure
         */
        dangerGroupAdvancedList: (query, params = {}) => this.request({
            path: `/api/DangerGroupAdvanced`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DangerGroupAdvancedCreate
         * @name DangerGroupAdvancedCreateCreate
         * @request POST:/api/DangerGroupAdvancedCreate
         * @secure
         */
        dangerGroupAdvancedCreateCreate: (data, params = {}) => this.request({
            path: `/api/DangerGroupAdvancedCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DangerGroupAdvancedDelete
         * @name DangerGroupAdvancedDeleteDelete
         * @request DELETE:/api/DangerGroupAdvancedDelete
         * @secure
         */
        dangerGroupAdvancedDeleteDelete: (data, params = {}) => this.request({
            path: `/api/DangerGroupAdvancedDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DangerGroupAdvancedDetails
         * @name DangerGroupAdvancedDetailsDetail
         * @request GET:/api/DangerGroupAdvancedDetails/{entityId}
         * @secure
         */
        dangerGroupAdvancedDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/DangerGroupAdvancedDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DangerGroupAdvancedUpdate
         * @name DangerGroupAdvancedUpdateUpdate
         * @request PUT:/api/DangerGroupAdvancedUpdate
         * @secure
         */
        dangerGroupAdvancedUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/DangerGroupAdvancedUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DemographicsReportAdmin
         * @name DemographicsReportAdminList
         * @request GET:/api/DemographicsReportAdmin
         * @secure
         */
        demographicsReportAdminList: (query, params = {}) => this.request({
            path: `/api/DemographicsReportAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name AbilityGroupItemListList
         * @request GET:/api/AbilityGroupItemList
         * @secure
         */
        abilityGroupItemListList: (params = {}) => this.request({
            path: `/api/AbilityGroupItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name AbilityItemListList
         * @request GET:/api/AbilityItemList
         * @secure
         */
        abilityItemListList: (params = {}) => this.request({
            path: `/api/AbilityItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name ActivePackageItemListList
         * @request GET:/api/ActivePackageItemList
         * @secure
         */
        activePackageItemListList: (params = {}) => this.request({
            path: `/api/ActivePackageItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name ActiveProductListList
         * @request GET:/api/ActiveProductList
         * @secure
         */
        activeProductListList: (params = {}) => this.request({
            path: `/api/ActiveProductList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name AdminPermissionDomainItemListList
         * @request GET:/api/AdminPermissionDomainItemList
         * @secure
         */
        adminPermissionDomainItemListList: (params = {}) => this.request({
            path: `/api/AdminPermissionDomainItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name AffectionTypeItemListList
         * @request GET:/api/AffectionTypeItemList
         * @secure
         */
        affectionTypeItemListList: (params = {}) => this.request({
            path: `/api/AffectionTypeItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name AggregatedAbilityItemListList
         * @request GET:/api/AggregatedAbilityItemList
         * @secure
         */
        aggregatedAbilityItemListList: (params = {}) => this.request({
            path: `/api/AggregatedAbilityItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name AggregatedHealthIssueItemListList
         * @request GET:/api/AggregatedHealthIssueItemList
         * @secure
         */
        aggregatedHealthIssueItemListList: (params = {}) => this.request({
            path: `/api/AggregatedHealthIssueItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name AssociationItemListList
         * @request GET:/api/AssociationItemList
         * @secure
         */
        associationItemListList: (query, params = {}) => this.request({
            path: `/api/AssociationItemList`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name AssociationPackageGroupItemListList
         * @request GET:/api/AssociationPackageGroupItemList
         * @secure
         */
        associationPackageGroupItemListList: (params = {}) => this.request({
            path: `/api/AssociationPackageGroupItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name BreedItemListList
         * @request GET:/api/BreedItemList
         * @secure
         */
        breedItemListList: (query, params = {}) => this.request({
            path: `/api/BreedItemList`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name ColorCombinationItemListList
         * @request GET:/api/ColorCombinationItemList
         * @secure
         */
        colorCombinationItemListList: (params = {}) => this.request({
            path: `/api/ColorCombinationItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name ColorItemListList
         * @request GET:/api/ColorItemList
         * @secure
         */
        colorItemListList: (params = {}) => this.request({
            path: `/api/ColorItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name CommercialTypeItemListList
         * @request GET:/api/CommercialTypeItemList
         * @secure
         */
        commercialTypeItemListList: (params = {}) => this.request({
            path: `/api/CommercialTypeItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name DangerGroupItemListList
         * @request GET:/api/DangerGroupItemList
         * @secure
         */
        dangerGroupItemListList: (params = {}) => this.request({
            path: `/api/DangerGroupItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name DisciplineItemListList
         * @request GET:/api/DisciplineItemList
         * @secure
         */
        disciplineItemListList: (query, params = {}) => this.request({
            path: `/api/DisciplineItemList`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name GeneGroupItemListList
         * @request GET:/api/GeneGroupItemList
         * @secure
         */
        geneGroupItemListList: (params = {}) => this.request({
            path: `/api/GeneGroupItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name GeneItemListList
         * @request GET:/api/GeneItemList
         * @secure
         */
        geneItemListList: (params = {}) => this.request({
            path: `/api/GeneItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name GenotypeItemListList
         * @request GET:/api/GenotypeItemList
         * @secure
         */
        genotypeItemListList: (params = {}) => this.request({
            path: `/api/GenotypeItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name HealthIssueGroupItemListList
         * @request GET:/api/HealthIssueGroupItemList
         * @secure
         */
        healthIssueGroupItemListList: (params = {}) => this.request({
            path: `/api/HealthIssueGroupItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name HealthIssueItemListList
         * @request GET:/api/HealthIssueItemList
         * @secure
         */
        healthIssueItemListList: (params = {}) => this.request({
            path: `/api/HealthIssueItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name HealthIssuesToAvoidItemListList
         * @request GET:/api/HealthIssuesToAvoidItemList
         * @secure
         */
        healthIssuesToAvoidItemListList: (params = {}) => this.request({
            path: `/api/HealthIssuesToAvoidItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name InconclusiveHealthIssueItemListList
         * @request GET:/api/InconclusiveHealthIssueItemList
         * @secure
         */
        inconclusiveHealthIssueItemListList: (params = {}) => this.request({
            path: `/api/InconclusiveHealthIssueItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name MarkingItemListList
         * @request GET:/api/MarkingItemList
         * @secure
         */
        markingItemListList: (params = {}) => this.request({
            path: `/api/MarkingItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name ModifierCombinationItemListList
         * @request GET:/api/ModifierCombinationItemList
         * @secure
         */
        modifierCombinationItemListList: (params = {}) => this.request({
            path: `/api/ModifierCombinationItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name NotificationTypeItemListList
         * @request GET:/api/NotificationTypeItemList
         * @secure
         */
        notificationTypeItemListList: (params = {}) => this.request({
            path: `/api/NotificationTypeItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name PackageGroupItemListList
         * @request GET:/api/PackageGroupItemList
         * @secure
         */
        packageGroupItemListList: (params = {}) => this.request({
            path: `/api/PackageGroupItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name PackageItemListList
         * @request GET:/api/PackageItemList
         * @secure
         */
        packageItemListList: (params = {}) => this.request({
            path: `/api/PackageItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name PackageRuleItemListList
         * @request GET:/api/PackageRuleItemList
         * @secure
         */
        packageRuleItemListList: (params = {}) => this.request({
            path: `/api/PackageRuleItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name PartialColorGroupItemListList
         * @request GET:/api/PartialColorGroupItemList
         * @secure
         */
        partialColorGroupItemListList: (params = {}) => this.request({
            path: `/api/PartialColorGroupItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name PermissionItemListList
         * @request GET:/api/PermissionItemList
         * @secure
         */
        permissionItemListList: (params = {}) => this.request({
            path: `/api/PermissionItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name ProductListList
         * @request GET:/api/ProductList
         * @secure
         */
        productListList: (params = {}) => this.request({
            path: `/api/ProductList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name ReportTypeItemListList
         * @request GET:/api/ReportTypeItemList
         * @secure
         */
        reportTypeItemListList: (params = {}) => this.request({
            path: `/api/ReportTypeItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name StripeSubscriptionProductItemListList
         * @request GET:/api/StripeSubscriptionProductItemList
         * @secure
         */
        stripeSubscriptionProductItemListList: (params = {}) => this.request({
            path: `/api/StripeSubscriptionProductItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name TestItemListList
         * @request GET:/api/TestItemList
         * @secure
         */
        testItemListList: (params = {}) => this.request({
            path: `/api/TestItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name TraitItemListList
         * @request GET:/api/TraitItemList
         * @secure
         */
        traitItemListList: (params = {}) => this.request({
            path: `/api/TraitItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dictionaries
         * @name UserNotificationEventItemListList
         * @request GET:/api/UserNotificationEventItemList
         * @secure
         */
        userNotificationEventItemListList: (params = {}) => this.request({
            path: `/api/UserNotificationEventItemList`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DictionaryUpdate
         * @name DictionaryUpdateCreate
         * @request POST:/api/DictionaryUpdate
         * @secure
         */
        dictionaryUpdateCreate: (params = {}) => this.request({
            path: `/api/DictionaryUpdate`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags DisciplinesTable
         * @name DisciplinesTableList
         * @request GET:/api/DisciplinesTable
         * @secure
         */
        disciplinesTableList: (query, params = {}) => this.request({
            path: `/api/DisciplinesTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DisciplinesTableCreate
         * @name DisciplinesTableCreateCreate
         * @request POST:/api/DisciplinesTableCreate
         * @secure
         */
        disciplinesTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/DisciplinesTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DisciplinesTableDelete
         * @name DisciplinesTableDeleteDelete
         * @request DELETE:/api/DisciplinesTableDelete
         * @secure
         */
        disciplinesTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/DisciplinesTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DisciplinesTableDetails
         * @name DisciplinesTableDetailsDetail
         * @request GET:/api/DisciplinesTableDetails/{entityId}
         * @secure
         */
        disciplinesTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/DisciplinesTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DisciplinesTableUpdate
         * @name DisciplinesTableUpdateUpdate
         * @request PUT:/api/DisciplinesTableUpdate
         * @secure
         */
        disciplinesTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/DisciplinesTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DiscountValidateAdmin
         * @name DiscountValidateAdminCreate
         * @request POST:/api/DiscountValidateAdmin
         * @secure
         */
        discountValidateAdminCreate: (data, params = {}) => this.request({
            path: `/api/DiscountValidateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DogAvatarDeleteAdmin
         * @name DogsadminAvatarDelete
         * @request DELETE:/api/dogsadmin-avatar/{dogId}
         * @secure
         */
        dogsadminAvatarDelete: (dogId, params = {}) => this.request({
            path: `/api/dogsadmin-avatar/${dogId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags DogAvatarUpdateAdmin
         * @name DogsadminAvatarCreate
         * @request POST:/api/dogsadmin-avatar/{dogId}
         * @secure
         */
        dogsadminAvatarCreate: (dogId, data, params = {}) => this.request({
            path: `/api/dogsadmin-avatar/${dogId}`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags DogAvatarUpdate
         * @name DogsAvatarCreate
         * @request POST:/api/dogs-avatar/{dogId}
         * @secure
         */
        dogsAvatarCreate: (dogId, data, params = {}) => this.request({
            path: `/api/dogs-avatar/${dogId}`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsAvatarDelete
         * @request DELETE:/api/dogs-avatar/{dogId}
         * @secure
         */
        dogsAvatarDelete: (dogId, params = {}) => this.request({
            path: `/api/dogs-avatar/${dogId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsadminCreate
         * @request POST:/api/dogsadmin
         * @secure
         */
        dogsadminCreate: (data, params = {}) => this.request({
            path: `/api/dogsadmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsadminList
         * @request GET:/api/dogsadmin
         * @secure
         */
        dogsadminList: (query, params = {}) => this.request({
            path: `/api/dogsadmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsadminDelete
         * @request DELETE:/api/dogsadmin/{dogId}
         * @secure
         */
        dogsadminDelete: (dogId, params = {}) => this.request({
            path: `/api/dogsadmin/${dogId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsadminDetail
         * @request GET:/api/dogsadmin/{dogId}
         * @secure
         */
        dogsadminDetail: (dogId, params = {}) => this.request({
            path: `/api/dogsadmin/${dogId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsadminUpdate
         * @request PUT:/api/dogsadmin/{dogId}
         * @secure
         */
        dogsadminUpdate: (dogId, data, params = {}) => this.request({
            path: `/api/dogsadmin/${dogId}`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags DogsUploadAdmin
         * @name DogsadminCreate2
         * @request POST:/api/dogsadmin/{dogId}
         * @originalName dogsadminCreate
         * @duplicate
         * @secure
         */
        dogsadminCreate2: (dogId, data, params = {}) => this.request({
            path: `/api/dogsadmin/${dogId}`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsGetByIdsAdminList
         * @request GET:/api/DogsGetByIdsAdmin
         * @secure
         */
        dogsGetByIdsAdminList: (data, params = {}) => this.request({
            path: `/api/DogsGetByIdsAdmin`,
            method: "GET",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name UserDogsList
         * @request GET:/api/UserDogs
         * @secure
         */
        userDogsList: (query, params = {}) => this.request({
            path: `/api/UserDogs`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsCreate
         * @request POST:/api/dogs
         * @secure
         */
        dogsCreate: (data, params = {}) => this.request({
            path: `/api/dogs`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsList
         * @request GET:/api/dogs
         * @secure
         */
        dogsList: (params = {}) => this.request({
            path: `/api/dogs`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsDelete
         * @request DELETE:/api/dogs/{dogId}
         * @secure
         */
        dogsDelete: (dogId, params = {}) => this.request({
            path: `/api/dogs/${dogId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsDetail
         * @request GET:/api/dogs/{dogId}
         * @secure
         */
        dogsDetail: (dogId, params = {}) => this.request({
            path: `/api/dogs/${dogId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsUpdate
         * @request PUT:/api/dogs/{dogId}
         * @secure
         */
        dogsUpdate: (dogId, data, params = {}) => this.request({
            path: `/api/dogs/${dogId}`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Dogs
         * @name DogsGetByIdsList
         * @request GET:/api/DogsGetByIds
         * @secure
         */
        dogsGetByIdsList: (data, params = {}) => this.request({
            path: `/api/DogsGetByIds`,
            method: "GET",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags DownloadRulesBackup
         * @name DownloadRulesBackupList
         * @request GET:/api/DownloadRulesBackup
         * @secure
         */
        downloadRulesBackupList: (params = {}) => this.request({
            path: `/api/DownloadRulesBackup`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ErrorDescriptionTable
         * @name ErrorDescriptionTableList
         * @request GET:/api/ErrorDescriptionTable
         * @secure
         */
        errorDescriptionTableList: (query, params = {}) => this.request({
            path: `/api/ErrorDescriptionTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ErrorDescriptionTableCreate
         * @name ErrorDescriptionTableCreateCreate
         * @request POST:/api/ErrorDescriptionTableCreate
         * @secure
         */
        errorDescriptionTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/ErrorDescriptionTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ErrorDescriptionTableDelete
         * @name ErrorDescriptionTableDeleteDelete
         * @request DELETE:/api/ErrorDescriptionTableDelete
         * @secure
         */
        errorDescriptionTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/ErrorDescriptionTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ErrorDescriptionTableDetails
         * @name ErrorDescriptionTableDetailsDetail
         * @request GET:/api/ErrorDescriptionTableDetails/{entityId}
         * @secure
         */
        errorDescriptionTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/ErrorDescriptionTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ErrorDescriptionTableUpdate
         * @name ErrorDescriptionTableUpdateUpdate
         * @request PUT:/api/ErrorDescriptionTableUpdate
         * @secure
         */
        errorDescriptionTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/ErrorDescriptionTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ExcludedHorses
         * @name ExcludedHorsesAddCreate
         * @request POST:/api/ExcludedHorsesAdd/{animalId}
         * @secure
         */
        excludedHorsesAddCreate: (animalId, params = {}) => this.request({
            path: `/api/ExcludedHorsesAdd/${animalId}`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags ExcludedHorses
         * @name ExcludedHorsesList
         * @request GET:/api/ExcludedHorses
         * @secure
         */
        excludedHorsesList: (query, params = {}) => this.request({
            path: `/api/ExcludedHorses`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ExcludedHorses
         * @name ExcludedHorsesRemoveDelete
         * @request DELETE:/api/ExcludedHorsesRemove/{animalId}
         * @secure
         */
        excludedHorsesRemoveDelete: (animalId, params = {}) => this.request({
            path: `/api/ExcludedHorsesRemove/${animalId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags ExcludedHorses
         * @name ExcludedHorsesSaveCreate
         * @request POST:/api/ExcludedHorsesSave
         * @secure
         */
        excludedHorsesSaveCreate: (data, params = {}) => this.request({
            path: `/api/ExcludedHorsesSave`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags ExcludedHorses
         * @name ExcludedHorsesSimpleList
         * @request GET:/api/ExcludedHorsesSimple
         * @secure
         */
        excludedHorsesSimpleList: (params = {}) => this.request({
            path: `/api/ExcludedHorsesSimple`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ExcludedHorsesListAssociation
         * @name ExcludedHorsesListAssociationList
         * @request GET:/api/ExcludedHorsesListAssociation
         * @secure
         */
        excludedHorsesListAssociationList: (params = {}) => this.request({
            path: `/api/ExcludedHorsesListAssociation`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags FavoriteHorses
         * @name FavoriteHorsesAddCreate
         * @request POST:/api/FavoriteHorsesAdd/{animalId}
         * @secure
         */
        favoriteHorsesAddCreate: (animalId, params = {}) => this.request({
            path: `/api/FavoriteHorsesAdd/${animalId}`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags FavoriteHorses
         * @name FavoriteHorsesList
         * @request GET:/api/FavoriteHorses
         * @secure
         */
        favoriteHorsesList: (query, params = {}) => this.request({
            path: `/api/FavoriteHorses`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags FavoriteHorses
         * @name FavoriteHorsesRemoveDelete
         * @request DELETE:/api/FavoriteHorsesRemove/{animalId}
         * @secure
         */
        favoriteHorsesRemoveDelete: (animalId, params = {}) => this.request({
            path: `/api/FavoriteHorsesRemove/${animalId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags FilterPropertyOperationsAdmin
         * @name FilterPropertyOperationsAdminList
         * @request GET:/api/FilterPropertyOperationsAdmin
         * @secure
         */
        filterPropertyOperationsAdminList: (query, params = {}) => this.request({
            path: `/api/FilterPropertyOperationsAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags FindHorseDefaultConfigAdmin
         * @name FindHorseDefaultConfigAdminList
         * @request GET:/api/FindHorseDefaultConfigAdmin
         * @secure
         */
        findHorseDefaultConfigAdminList: (params = {}) => this.request({
            path: `/api/FindHorseDefaultConfigAdmin`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags FindHorseDefaultConfigUpdateAdmin
         * @name FindHorseDefaultConfigUpdateAdminUpdate
         * @request PUT:/api/FindHorseDefaultConfigUpdateAdmin
         * @secure
         */
        findHorseDefaultConfigUpdateAdminUpdate: (data, params = {}) => this.request({
            path: `/api/FindHorseDefaultConfigUpdateAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags FindHorses
         * @name FindHorsesCreate
         * @request POST:/api/FindHorses
         * @secure
         */
        findHorsesCreate: (data, params = {}) => this.request({
            path: `/api/FindHorses`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags FindHorsesAssociation
         * @name FindHorsesAssociationCreate
         * @request POST:/api/FindHorsesAssociation
         * @secure
         */
        findHorsesAssociationCreate: (data, params = {}) => this.request({
            path: `/api/FindHorsesAssociation`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags FindUserAdmin
         * @name FindUserAdminList
         * @request GET:/api/FindUserAdmin
         * @secure
         */
        findUserAdminList: (query, params = {}) => this.request({
            path: `/api/FindUserAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GeneGroupImageAdmin
         * @name GeneGroupImageAdminDetail
         * @request GET:/api/GeneGroupImageAdmin/{entityId}
         * @secure
         */
        geneGroupImageAdminDetail: (entityId, params = {}) => this.request({
            path: `/api/GeneGroupImageAdmin/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GeneGroupImageCreateAdmin
         * @name GeneGroupImageCreateAdminCreate
         * @request POST:/api/GeneGroupImageCreateAdmin
         * @secure
         */
        geneGroupImageCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/GeneGroupImageCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags GeneGroupImageDeleteAdmin
         * @name GeneGroupImageDeleteAdminDelete
         * @request DELETE:/api/GeneGroupImageDeleteAdmin
         * @secure
         */
        geneGroupImageDeleteAdminDelete: (data, params = {}) => this.request({
            path: `/api/GeneGroupImageDeleteAdmin`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags GeneGroupsTable
         * @name GeneGroupsTableList
         * @request GET:/api/GeneGroupsTable
         * @secure
         */
        geneGroupsTableList: (query, params = {}) => this.request({
            path: `/api/GeneGroupsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GeneGroupsTableCreate
         * @name GeneGroupsTableCreateCreate
         * @request POST:/api/GeneGroupsTableCreate
         * @secure
         */
        geneGroupsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/GeneGroupsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GeneGroupsTableDelete
         * @name GeneGroupsTableDeleteDelete
         * @request DELETE:/api/GeneGroupsTableDelete
         * @secure
         */
        geneGroupsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/GeneGroupsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GeneGroupsTableDetails
         * @name GeneGroupsTableDetailsDetail
         * @request GET:/api/GeneGroupsTableDetails/{entityId}
         * @secure
         */
        geneGroupsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/GeneGroupsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GeneGroupsTableUpdate
         * @name GeneGroupsTableUpdateUpdate
         * @request PUT:/api/GeneGroupsTableUpdate
         * @secure
         */
        geneGroupsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/GeneGroupsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenePools
         * @name GenePoolsCreate
         * @request POST:/api/GenePools
         * @secure
         */
        genePoolsCreate: (data, params = {}) => this.request({
            path: `/api/GenePools`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenerateCouponCodeAdmin
         * @name GenerateCouponCodeAdminList
         * @request GET:/api/GenerateCouponCodeAdmin
         * @secure
         */
        generateCouponCodeAdminList: (params = {}) => this.request({
            path: `/api/GenerateCouponCodeAdmin`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenesTable
         * @name GenesTableList
         * @request GET:/api/GenesTable
         * @secure
         */
        genesTableList: (query, params = {}) => this.request({
            path: `/api/GenesTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenesTableCreate
         * @name GenesTableCreateCreate
         * @request POST:/api/GenesTableCreate
         * @secure
         */
        genesTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/GenesTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenesTableDelete
         * @name GenesTableDeleteDelete
         * @request DELETE:/api/GenesTableDelete
         * @secure
         */
        genesTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/GenesTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenesTableDetails
         * @name GenesTableDetailsDetail
         * @request GET:/api/GenesTableDetails/{entityId}
         * @secure
         */
        genesTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/GenesTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenesTableUpdate
         * @name GenesTableUpdateUpdate
         * @request PUT:/api/GenesTableUpdate
         * @secure
         */
        genesTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/GenesTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenotypesCombinationsTable
         * @name GenotypesCombinationsTableList
         * @request GET:/api/GenotypesCombinationsTable
         * @secure
         */
        genotypesCombinationsTableList: (query, params = {}) => this.request({
            path: `/api/GenotypesCombinationsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenotypesCombinationsTableCreate
         * @name GenotypesCombinationsTableCreateCreate
         * @request POST:/api/GenotypesCombinationsTableCreate
         * @secure
         */
        genotypesCombinationsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/GenotypesCombinationsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenotypesCombinationsTableDelete
         * @name GenotypesCombinationsTableDeleteDelete
         * @request DELETE:/api/GenotypesCombinationsTableDelete
         * @secure
         */
        genotypesCombinationsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/GenotypesCombinationsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenotypesCombinationsTableDetails
         * @name GenotypesCombinationsTableDetailsDetail
         * @request GET:/api/GenotypesCombinationsTableDetails/{entityId}
         * @secure
         */
        genotypesCombinationsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/GenotypesCombinationsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenotypesCombinationsTableUpdate
         * @name GenotypesCombinationsTableUpdateUpdate
         * @request PUT:/api/GenotypesCombinationsTableUpdate
         * @secure
         */
        genotypesCombinationsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/GenotypesCombinationsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenotypesTable
         * @name GenotypesTableList
         * @request GET:/api/GenotypesTable
         * @secure
         */
        genotypesTableList: (query, params = {}) => this.request({
            path: `/api/GenotypesTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenotypesTableCreate
         * @name GenotypesTableCreateCreate
         * @request POST:/api/GenotypesTableCreate
         * @secure
         */
        genotypesTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/GenotypesTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenotypesTableDelete
         * @name GenotypesTableDeleteDelete
         * @request DELETE:/api/GenotypesTableDelete
         * @secure
         */
        genotypesTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/GenotypesTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenotypesTableDetails
         * @name GenotypesTableDetailsDetail
         * @request GET:/api/GenotypesTableDetails/{entityId}
         * @secure
         */
        genotypesTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/GenotypesTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GenotypesTableUpdate
         * @name GenotypesTableUpdateUpdate
         * @request PUT:/api/GenotypesTableUpdate
         * @secure
         */
        genotypesTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/GenotypesTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags GetAnimalsAdmin
         * @name GetAnimalsAdminList
         * @request GET:/api/GetAnimalsAdmin
         * @secure
         */
        getAnimalsAdminList: (query, params = {}) => this.request({
            path: `/api/GetAnimalsAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HealthIssueGroupAdvanced
         * @name HealthIssueGroupAdvancedList
         * @request GET:/api/HealthIssueGroupAdvanced
         * @secure
         */
        healthIssueGroupAdvancedList: (query, params = {}) => this.request({
            path: `/api/HealthIssueGroupAdvanced`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HealthIssueGroupAdvancedCreate
         * @name HealthIssueGroupAdvancedCreateCreate
         * @request POST:/api/HealthIssueGroupAdvancedCreate
         * @secure
         */
        healthIssueGroupAdvancedCreateCreate: (data, params = {}) => this.request({
            path: `/api/HealthIssueGroupAdvancedCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HealthIssueGroupAdvancedDelete
         * @name HealthIssueGroupAdvancedDeleteDelete
         * @request DELETE:/api/HealthIssueGroupAdvancedDelete
         * @secure
         */
        healthIssueGroupAdvancedDeleteDelete: (data, params = {}) => this.request({
            path: `/api/HealthIssueGroupAdvancedDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HealthIssueGroupAdvancedDetails
         * @name HealthIssueGroupAdvancedDetailsDetail
         * @request GET:/api/HealthIssueGroupAdvancedDetails/{entityId}
         * @secure
         */
        healthIssueGroupAdvancedDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/HealthIssueGroupAdvancedDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HealthIssueGroupAdvancedUpdate
         * @name HealthIssueGroupAdvancedUpdateUpdate
         * @request PUT:/api/HealthIssueGroupAdvancedUpdate
         * @secure
         */
        healthIssueGroupAdvancedUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/HealthIssueGroupAdvancedUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseAncestryProfileTypesAssociation
         * @name HorseAncestryProfileTypesAssociationPartialUpdate
         * @request PATCH:/api/HorseAncestryProfileTypesAssociation
         * @secure
         */
        horseAncestryProfileTypesAssociationPartialUpdate: (data, params = {}) => this.request({
            path: `/api/HorseAncestryProfileTypesAssociation`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseListItemSearch
         * @name HorseListItemSearchDetail
         * @request GET:/api/HorseListItemSearch/{animalId}
         * @secure
         */
        horseListItemSearchDetail: (animalId, params = {}) => this.request({
            path: `/api/HorseListItemSearch/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseListItemSearchAssociation
         * @name HorseListItemSearchAssociationDetail
         * @request GET:/api/HorseListItemSearchAssociation/{horseId}
         * @secure
         */
        horseListItemSearchAssociationDetail: (horseId, params = {}) => this.request({
            path: `/api/HorseListItemSearchAssociation/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseListSearch
         * @name HorseListSearchList
         * @request GET:/api/HorseListSearch
         * @secure
         */
        horseListSearchList: (query, params = {}) => this.request({
            path: `/api/HorseListSearch`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseListSearchAssociation
         * @name HorseListSearchAssociationList
         * @request GET:/api/HorseListSearchAssociation
         * @secure
         */
        horseListSearchAssociationList: (query, params = {}) => this.request({
            path: `/api/HorseListSearchAssociation`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseOwnerAssociation
         * @name HorseOwnerAssociationDetail
         * @request GET:/api/HorseOwnerAssociation/{horseId}
         * @secure
         */
        horseOwnerAssociationDetail: (horseId, params = {}) => this.request({
            path: `/api/HorseOwnerAssociation/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseParentageProfileTypesAssociation
         * @name HorseParentageProfileTypesAssociationPartialUpdate
         * @request PATCH:/api/HorseParentageProfileTypesAssociation
         * @secure
         */
        horseParentageProfileTypesAssociationPartialUpdate: (data, params = {}) => this.request({
            path: `/api/HorseParentageProfileTypesAssociation`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseParentageReportAssociation
         * @name HorseParentageReportAssociationDetail
         * @request GET:/api/HorseParentageReportAssociation/{horseId}
         * @secure
         */
        horseParentageReportAssociationDetail: (horseId, params = {}) => this.request({
            path: `/api/HorseParentageReportAssociation/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseProfileHorsesAssociation
         * @name HorseProfileHorsesAssociationDetail
         * @request GET:/api/HorseProfileHorsesAssociation/{horseId}
         * @secure
         */
        horseProfileHorsesAssociationDetail: (horseId, params = {}) => this.request({
            path: `/api/HorseProfileHorsesAssociation/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseProfileTypesAssociation
         * @name HorseProfileTypesAssociationPartialUpdate
         * @request PATCH:/api/HorseProfileTypesAssociation
         * @secure
         */
        horseProfileTypesAssociationPartialUpdate: (data, params = {}) => this.request({
            path: `/api/HorseProfileTypesAssociation`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorsePublicityRequestAssociation
         * @name HorsePublicityRequestAssociationCreate
         * @request POST:/api/HorsePublicityRequestAssociation
         * @secure
         */
        horsePublicityRequestAssociationCreate: (data, params = {}) => this.request({
            path: `/api/HorsePublicityRequestAssociation`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseReportsAssociation
         * @name HorseReportsAssociationDetail
         * @request GET:/api/HorseReportsAssociation/{horseId}
         * @secure
         */
        horseReportsAssociationDetail: (horseId, params = {}) => this.request({
            path: `/api/HorseReportsAssociation/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseSubmissionFormAdmin
         * @name HorseSubmissionFormAdminList
         * @request GET:/api/HorseSubmissionFormAdmin
         * @secure
         */
        horseSubmissionFormAdminList: (query, params = {}) => this.request({
            path: `/api/HorseSubmissionFormAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HorseTraitsUpdateAdmin
         * @name HorseTraitsUpdateAdminCreate
         * @request POST:/api/HorseTraitsUpdateAdmin
         * @secure
         */
        horseTraitsUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/HorseTraitsUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags HtmlTemplatesAdmin
         * @name HtmlTemplatesAdminList
         * @request GET:/api/HtmlTemplatesAdmin
         * @secure
         */
        htmlTemplatesAdminList: (query, params = {}) => this.request({
            path: `/api/HtmlTemplatesAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HtmlTemplatesByTypeAdmin
         * @name HtmlTemplatesByTypeAdminList
         * @request GET:/api/HtmlTemplatesByTypeAdmin
         * @secure
         */
        htmlTemplatesByTypeAdminList: (query, params = {}) => this.request({
            path: `/api/HtmlTemplatesByTypeAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags HtmlTemplatesDeleteAdmin
         * @name HtmlTemplatesDeleteAdminDelete
         * @request DELETE:/api/HtmlTemplatesDeleteAdmin
         * @secure
         */
        htmlTemplatesDeleteAdminDelete: (data, params = {}) => this.request({
            path: `/api/HtmlTemplatesDeleteAdmin`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags HtmlTemplatesUpdateAdmin
         * @name HtmlTemplatesUpdateAdminCreate
         * @request POST:/api/HtmlTemplatesUpdateAdmin
         * @secure
         */
        htmlTemplatesUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/HtmlTemplatesUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags IdentityVisitor
         * @name IdentityVisitorDetail
         * @request GET:/api/IdentityVisitor/{email}
         * @secure
         */
        identityVisitorDetail: (email, params = {}) => this.request({
            path: `/api/IdentityVisitor/${email}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Images
         * @name ImagesDetail
         * @request GET:/api/Images/{id}
         * @secure
         */
        imagesDetail: (id, params = {}) => this.request({
            path: `/api/Images/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Images
         * @name ImagesDeleteDelete
         * @request DELETE:/api/ImagesDelete/{id}
         * @secure
         */
        imagesDeleteDelete: (id, params = {}) => this.request({
            path: `/api/ImagesDelete/${id}`,
            method: "DELETE",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Images
         * @name ImagesUpdateCreate
         * @request POST:/api/ImagesUpdate
         * @secure
         */
        imagesUpdateCreate: (data, params = {}) => this.request({
            path: `/api/ImagesUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ImagesAdmin
         * @name ImagesAdminDetail
         * @request GET:/api/ImagesAdmin/{id}
         * @secure
         */
        imagesAdminDetail: (id, params = {}) => this.request({
            path: `/api/ImagesAdmin/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ImagesAdmin
         * @name ImagesDeleteAdminDelete
         * @request DELETE:/api/ImagesDeleteAdmin
         * @secure
         */
        imagesDeleteAdminDelete: (data, params = {}) => this.request({
            path: `/api/ImagesDeleteAdmin`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ImagesAdmin
         * @name ImagesUpdateAdminCreate
         * @request POST:/api/ImagesUpdateAdmin
         * @secure
         */
        imagesUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/ImagesUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags InbreedingCoefficient
         * @name InbreedingCoefficientList
         * @request GET:/api/InbreedingCoefficient
         * @secure
         */
        inbreedingCoefficientList: (query, params = {}) => this.request({
            path: `/api/InbreedingCoefficient`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags InconclusiveHealthIssuesTable
         * @name InconclusiveHealthIssuesTableList
         * @request GET:/api/InconclusiveHealthIssuesTable
         * @secure
         */
        inconclusiveHealthIssuesTableList: (query, params = {}) => this.request({
            path: `/api/InconclusiveHealthIssuesTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags InconclusiveHealthIssuesTableCreate
         * @name InconclusiveHealthIssuesTableCreateCreate
         * @request POST:/api/InconclusiveHealthIssuesTableCreate
         * @secure
         */
        inconclusiveHealthIssuesTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/InconclusiveHealthIssuesTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags InconclusiveHealthIssuesTableDelete
         * @name InconclusiveHealthIssuesTableDeleteDelete
         * @request DELETE:/api/InconclusiveHealthIssuesTableDelete
         * @secure
         */
        inconclusiveHealthIssuesTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/InconclusiveHealthIssuesTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags InconclusiveHealthIssuesTableDetails
         * @name InconclusiveHealthIssuesTableDetailsDetail
         * @request GET:/api/InconclusiveHealthIssuesTableDetails/{entityId}
         * @secure
         */
        inconclusiveHealthIssuesTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/InconclusiveHealthIssuesTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags InconclusiveHealthIssuesTableUpdate
         * @name InconclusiveHealthIssuesTableUpdateUpdate
         * @request PUT:/api/InconclusiveHealthIssuesTableUpdate
         * @secure
         */
        inconclusiveHealthIssuesTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/InconclusiveHealthIssuesTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags MainPage
         * @name MainPageHorsesList
         * @request GET:/api/MainPage/Horses
         * @secure
         */
        mainPageHorsesList: (query, params = {}) => this.request({
            path: `/api/MainPage/Horses`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Marketplace
         * @name AdminMarketplaceProductsDetail
         * @request GET:/api/admin/marketplace/products/{itemId}
         * @secure
         */
        adminMarketplaceProductsDetail: (itemId, params = {}) => this.request({
            path: `/api/admin/marketplace/products/${itemId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Marketplace
         * @name AdminMarketplaceProductsCreate
         * @request POST:/api/admin/marketplace/products
         * @secure
         */
        adminMarketplaceProductsCreate: (data, params = {}) => this.request({
            path: `/api/admin/marketplace/products`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Marketplace
         * @name AdminMarketplaceProductsList
         * @request GET:/api/admin/marketplace/products
         * @secure
         */
        adminMarketplaceProductsList: (query, params = {}) => this.request({
            path: `/api/admin/marketplace/products`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Marketplace
         * @name AdminMarketplaceProductsUpdate
         * @request PUT:/api/admin/marketplace/products/{id}
         * @secure
         */
        adminMarketplaceProductsUpdate: (id, data, params = {}) => this.request({
            path: `/api/admin/marketplace/products/${id}`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Marketplace
         * @name AdminMarketplaceProductsImagesCreate
         * @request POST:/api/admin/marketplace/products/images
         * @secure
         */
        adminMarketplaceProductsImagesCreate: (data, params = {}) => this.request({
            path: `/api/admin/marketplace/products/images`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Marketplace
         * @name MarketplaceProductsList
         * @request GET:/api/marketplace/products
         * @secure
         */
        marketplaceProductsList: (query, params = {}) => this.request({
            path: `/api/marketplace/products`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Marketplace
         * @name MarketplaceProductsDetail
         * @request GET:/api/marketplace/products/{itemId}
         * @secure
         */
        marketplaceProductsDetail: (itemId, params = {}) => this.request({
            path: `/api/marketplace/products/${itemId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags MarkingsTable
         * @name MarkingsTableList
         * @request GET:/api/MarkingsTable
         * @secure
         */
        markingsTableList: (query, params = {}) => this.request({
            path: `/api/MarkingsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags MarkingsTableCreate
         * @name MarkingsTableCreateCreate
         * @request POST:/api/MarkingsTableCreate
         * @secure
         */
        markingsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/MarkingsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags MarkingsTableDelete
         * @name MarkingsTableDeleteDelete
         * @request DELETE:/api/MarkingsTableDelete
         * @secure
         */
        markingsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/MarkingsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags MarkingsTableDetails
         * @name MarkingsTableDetailsDetail
         * @request GET:/api/MarkingsTableDetails/{entityId}
         * @secure
         */
        markingsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/MarkingsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags MarkingsTableUpdate
         * @name MarkingsTableUpdateUpdate
         * @request PUT:/api/MarkingsTableUpdate
         * @secure
         */
        markingsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/MarkingsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ModifierCombinationsTable
         * @name ModifierCombinationsTableList
         * @request GET:/api/ModifierCombinationsTable
         * @secure
         */
        modifierCombinationsTableList: (query, params = {}) => this.request({
            path: `/api/ModifierCombinationsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ModifierCombinationsTableCreate
         * @name ModifierCombinationsTableCreateCreate
         * @request POST:/api/ModifierCombinationsTableCreate
         * @secure
         */
        modifierCombinationsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/ModifierCombinationsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ModifierCombinationsTableDelete
         * @name ModifierCombinationsTableDeleteDelete
         * @request DELETE:/api/ModifierCombinationsTableDelete
         * @secure
         */
        modifierCombinationsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/ModifierCombinationsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ModifierCombinationsTableDetails
         * @name ModifierCombinationsTableDetailsDetail
         * @request GET:/api/ModifierCombinationsTableDetails/{entityId}
         * @secure
         */
        modifierCombinationsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/ModifierCombinationsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ModifierCombinationsTableUpdate
         * @name ModifierCombinationsTableUpdateUpdate
         * @request PUT:/api/ModifierCombinationsTableUpdate
         * @secure
         */
        modifierCombinationsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/ModifierCombinationsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags NotificationTypes
         * @name NotificationTypesList
         * @request GET:/api/NotificationTypes
         * @secure
         */
        notificationTypesList: (query, params = {}) => this.request({
            path: `/api/NotificationTypes`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags NotificationTypesCreate
         * @name NotificationTypesCreateCreate
         * @request POST:/api/NotificationTypesCreate
         * @secure
         */
        notificationTypesCreateCreate: (data, params = {}) => this.request({
            path: `/api/NotificationTypesCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags NotificationTypesDelete
         * @name NotificationTypesDeleteDelete
         * @request DELETE:/api/NotificationTypesDelete
         * @secure
         */
        notificationTypesDeleteDelete: (data, params = {}) => this.request({
            path: `/api/NotificationTypesDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags NotificationTypesDetails
         * @name NotificationTypesDetailsDetail
         * @request GET:/api/NotificationTypesDetails/{entityId}
         * @secure
         */
        notificationTypesDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/NotificationTypesDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags NotificationTypesUpdate
         * @name NotificationTypesUpdateUpdate
         * @request PUT:/api/NotificationTypesUpdate
         * @secure
         */
        notificationTypesUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/NotificationTypesUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name ShortTandemRepeatReportDetail
         * @request GET:/api/ShortTandemRepeatReport/{animalId}
         * @secure
         */
        shortTandemRepeatReportDetail: (animalId, params = {}) => this.request({
            path: `/api/ShortTandemRepeatReport/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name OnlineReportAbilitiesDetail
         * @request GET:/api/OnlineReport/Abilities/{animalId}
         * @secure
         */
        onlineReportAbilitiesDetail: (animalId, params = {}) => this.request({
            path: `/api/OnlineReport/Abilities/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name OnlineReportHealthIssuesDetail
         * @request GET:/api/OnlineReport/HealthIssues/{animalId}
         * @secure
         */
        onlineReportHealthIssuesDetail: (animalId, params = {}) => this.request({
            path: `/api/OnlineReport/HealthIssues/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name OnlineReportHorseDetail
         * @request GET:/api/OnlineReport/Horse/{animalId}
         * @secure
         */
        onlineReportHorseDetail: (animalId, params = {}) => this.request({
            path: `/api/OnlineReport/Horse/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name OnlineReportHorseDetailsDetail
         * @request GET:/api/OnlineReport/HorseDetails/{animalId}
         * @secure
         */
        onlineReportHorseDetailsDetail: (animalId, params = {}) => this.request({
            path: `/api/OnlineReport/HorseDetails/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name OnlineReportOwnerDetail
         * @request GET:/api/OnlineReport/Owner/{animalId}
         * @secure
         */
        onlineReportOwnerDetail: (animalId, params = {}) => this.request({
            path: `/api/OnlineReport/Owner/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name OnlineReportPanelsDetail
         * @request GET:/api/OnlineReport/Panels/{animalId}
         * @secure
         */
        onlineReportPanelsDetail: (animalId, params = {}) => this.request({
            path: `/api/OnlineReport/Panels/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name OnlineReportPartialColorsDetail
         * @request GET:/api/OnlineReport/PartialColors/{animalId}
         * @secure
         */
        onlineReportPartialColorsDetail: (animalId, params = {}) => this.request({
            path: `/api/OnlineReport/PartialColors/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name OnlineReportSummaryAbilitiesDetail
         * @request GET:/api/OnlineReport/Summary/Abilities/{animalId}
         * @secure
         */
        onlineReportSummaryAbilitiesDetail: (animalId, params = {}) => this.request({
            path: `/api/OnlineReport/Summary/Abilities/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name OnlineReportSummaryColorsDetail
         * @request GET:/api/OnlineReport/Summary/Colors/{animalId}
         * @secure
         */
        onlineReportSummaryColorsDetail: (animalId, params = {}) => this.request({
            path: `/api/OnlineReport/Summary/Colors/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name OnlineReportGeneticVariantsDetail
         * @request GET:/api/OnlineReport/GeneticVariants/{animalId}
         * @secure
         */
        onlineReportGeneticVariantsDetail: (animalId, params = {}) => this.request({
            path: `/api/OnlineReport/GeneticVariants/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReport
         * @name OnlineReportSummaryHealthIssuesDetail
         * @request GET:/api/OnlineReport/Summary/HealthIssues/{animalId}
         * @secure
         */
        onlineReportSummaryHealthIssuesDetail: (animalId, params = {}) => this.request({
            path: `/api/OnlineReport/Summary/HealthIssues/${animalId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAbilities
         * @name OnlineReportAbilitiesDetail2
         * @request GET:/api/OnlineReportAbilities/{abilityId}
         * @originalName onlineReportAbilitiesDetail
         * @duplicate
         * @secure
         */
        onlineReportAbilitiesDetail2: (abilityId, params = {}) => this.request({
            path: `/api/OnlineReportAbilities/${abilityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAbilityAggregators
         * @name OnlineReportAbilityAggregatorsDetail
         * @request GET:/api/OnlineReportAbilityAggregators/{abilityId}
         * @secure
         */
        onlineReportAbilityAggregatorsDetail: (abilityId, params = {}) => this.request({
            path: `/api/OnlineReportAbilityAggregators/${abilityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportAbilitiesDetail
         * @request GET:/api/Admin/OnlineReport/Abilities/{horseId}
         * @secure
         */
        adminOnlineReportAbilitiesDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/Abilities/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportHealthIssuesDetail
         * @request GET:/api/Admin/OnlineReport/HealthIssues/{horseId}
         * @secure
         */
        adminOnlineReportHealthIssuesDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/HealthIssues/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportHorseDetailsDetail
         * @request GET:/api/Admin/OnlineReport/HorseDetails/{horseId}
         * @secure
         */
        adminOnlineReportHorseDetailsDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/HorseDetails/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportPanelsDetail
         * @request GET:/api/Admin/OnlineReport/Panels/{horseId}
         * @secure
         */
        adminOnlineReportPanelsDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/Panels/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportPartialColorsDetail
         * @request GET:/api/Admin/OnlineReport/PartialColors/{horseId}
         * @secure
         */
        adminOnlineReportPartialColorsDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/PartialColors/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportSummaryAbilitiesDetail
         * @request GET:/api/Admin/OnlineReport/Summary/Abilities/{horseId}
         * @secure
         */
        adminOnlineReportSummaryAbilitiesDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/Summary/Abilities/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportSummaryColorsDetail
         * @request GET:/api/Admin/OnlineReport/Summary/Colors/{horseId}
         * @secure
         */
        adminOnlineReportSummaryColorsDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/Summary/Colors/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportGeneticVariantsDetail
         * @request GET:/api/Admin/OnlineReport/GeneticVariants/{horseId}
         * @secure
         */
        adminOnlineReportGeneticVariantsDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/GeneticVariants/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportSummaryHealthIssuesDetail
         * @request GET:/api/Admin/OnlineReport/Summary/HealthIssues/{horseId}
         * @secure
         */
        adminOnlineReportSummaryHealthIssuesDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/Summary/HealthIssues/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportHorseDetail
         * @request GET:/api/Admin/OnlineReport/Horse/{horseId}
         * @secure
         */
        adminOnlineReportHorseDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/Horse/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportOwnerDetail
         * @request GET:/api/Admin/OnlineReport/Owner/{horseId}
         * @secure
         */
        adminOnlineReportOwnerDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/Owner/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportGeneticNotesDelete
         * @request DELETE:/api/Admin/OnlineReport/GeneticNotes
         * @secure
         */
        adminOnlineReportGeneticNotesDelete: (data, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/GeneticNotes`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportAdmin
         * @name AdminOnlineReportGeneticNotesCreate
         * @request POST:/api/Admin/OnlineReport/GeneticNotes
         * @secure
         */
        adminOnlineReportGeneticNotesCreate: (data, params = {}) => this.request({
            path: `/api/Admin/OnlineReport/GeneticNotes`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportHealthIssueAggregators
         * @name OnlineReportHealthIssueAggregatorsDetail
         * @request GET:/api/OnlineReportHealthIssueAggregators/{healthIssueId}
         * @secure
         */
        onlineReportHealthIssueAggregatorsDetail: (healthIssueId, params = {}) => this.request({
            path: `/api/OnlineReportHealthIssueAggregators/${healthIssueId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportHealthIssues
         * @name OnlineReportHealthIssuesDetail2
         * @request GET:/api/OnlineReportHealthIssues/{healthIssueId}
         * @originalName onlineReportHealthIssuesDetail
         * @duplicate
         * @secure
         */
        onlineReportHealthIssuesDetail2: (healthIssueId, params = {}) => this.request({
            path: `/api/OnlineReportHealthIssues/${healthIssueId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportPartialColor
         * @name OnlineReportPartialColorDetailsDetail
         * @request GET:/api/OnlineReport/PartialColorDetails/{partialColorId}
         * @secure
         */
        onlineReportPartialColorDetailsDetail: (partialColorId, params = {}) => this.request({
            path: `/api/OnlineReport/PartialColorDetails/${partialColorId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportReviewAdmin
         * @name AdminOnlineReportReviewAbilitiesList
         * @request GET:/api/Admin/OnlineReportReview/Abilities
         * @secure
         */
        adminOnlineReportReviewAbilitiesList: (query, params = {}) => this.request({
            path: `/api/Admin/OnlineReportReview/Abilities`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportReviewAdmin
         * @name AdminOnlineReportReviewGeneticNotesDetail
         * @request GET:/api/Admin/OnlineReportReview/GeneticNotes/{horseId}
         * @secure
         */
        adminOnlineReportReviewGeneticNotesDetail: (horseId, params = {}) => this.request({
            path: `/api/Admin/OnlineReportReview/GeneticNotes/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportReviewAdmin
         * @name AdminOnlineReportReviewHealthIssuesList
         * @request GET:/api/Admin/OnlineReportReview/HealthIssues
         * @secure
         */
        adminOnlineReportReviewHealthIssuesList: (query, params = {}) => this.request({
            path: `/api/Admin/OnlineReportReview/HealthIssues`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportReviewAdmin
         * @name AdminOnlineReportReviewPanelsList
         * @request GET:/api/Admin/OnlineReportReview/Panels
         * @secure
         */
        adminOnlineReportReviewPanelsList: (query, params = {}) => this.request({
            path: `/api/Admin/OnlineReportReview/Panels`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportReviewAdmin
         * @name AdminOnlineReportReviewPartialColorsList
         * @request GET:/api/Admin/OnlineReportReview/PartialColors
         * @secure
         */
        adminOnlineReportReviewPartialColorsList: (query, params = {}) => this.request({
            path: `/api/Admin/OnlineReportReview/PartialColors`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportReviewAdmin
         * @name AdminOnlineReportReviewRequiredTestsList
         * @request GET:/api/Admin/OnlineReportReview/RequiredTests
         * @secure
         */
        adminOnlineReportReviewRequiredTestsList: (query, params = {}) => this.request({
            path: `/api/Admin/OnlineReportReview/RequiredTests`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportReviewAdmin
         * @name AdminOnlineReportReviewStatusCreate
         * @request POST:/api/Admin/OnlineReportReview/Status
         * @secure
         */
        adminOnlineReportReviewStatusCreate: (data, params = {}) => this.request({
            path: `/api/Admin/OnlineReportReview/Status`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportReviewAdmin
         * @name AdminOnlineReportReviewSummaryAbilitiesList
         * @request GET:/api/Admin/OnlineReportReview/Summary/Abilities
         * @secure
         */
        adminOnlineReportReviewSummaryAbilitiesList: (query, params = {}) => this.request({
            path: `/api/Admin/OnlineReportReview/Summary/Abilities`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportReviewAdmin
         * @name AdminOnlineReportReviewSummaryColorsList
         * @request GET:/api/Admin/OnlineReportReview/Summary/Colors
         * @secure
         */
        adminOnlineReportReviewSummaryColorsList: (query, params = {}) => this.request({
            path: `/api/Admin/OnlineReportReview/Summary/Colors`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportReviewAdmin
         * @name AdminOnlineReportReviewGeneticVariantsList
         * @request GET:/api/Admin/OnlineReportReview/GeneticVariants
         * @secure
         */
        adminOnlineReportReviewGeneticVariantsList: (query, params = {}) => this.request({
            path: `/api/Admin/OnlineReportReview/GeneticVariants`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OnlineReportReviewAdmin
         * @name AdminOnlineReportReviewSummaryHealthIssuesList
         * @request GET:/api/Admin/OnlineReportReview/Summary/HealthIssues
         * @secure
         */
        adminOnlineReportReviewSummaryHealthIssuesList: (query, params = {}) => this.request({
            path: `/api/Admin/OnlineReportReview/Summary/HealthIssues`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OperatingModeControlAdmin
         * @name OperatingModeControlAdminList
         * @request GET:/api/OperatingModeControlAdmin
         * @secure
         */
        operatingModeControlAdminList: (params = {}) => this.request({
            path: `/api/OperatingModeControlAdmin`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OperatingModeControlUpdateAdmin
         * @name OperatingModeControlUpdateAdminCreate
         * @request POST:/api/OperatingModeControlUpdateAdmin
         * @secure
         */
        operatingModeControlUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/OperatingModeControlUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrderDetails
         * @name OrderDetailsDetail
         * @request GET:/api/OrderDetails/{token}
         * @secure
         */
        orderDetailsDetail: (token, params = {}) => this.request({
            path: `/api/OrderDetails/${token}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrderHorseStatusesDetailsAdmin
         * @name OrderHorseStatusesDetailsAdminList
         * @request GET:/api/OrderHorseStatusesDetailsAdmin
         * @secure
         */
        orderHorseStatusesDetailsAdminList: (query, params = {}) => this.request({
            path: `/api/OrderHorseStatusesDetailsAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrderPackagesAdmin
         * @name OrderPackagesAdminList
         * @request GET:/api/OrderPackagesAdmin
         * @secure
         */
        orderPackagesAdminList: (params = {}) => this.request({
            path: `/api/OrderPackagesAdmin`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrderPaymentsHistory
         * @name OrderPaymentsHistoryList
         * @request GET:/api/OrderPaymentsHistory
         * @secure
         */
        orderPaymentsHistoryList: (query, params = {}) => this.request({
            path: `/api/OrderPaymentsHistory`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrderPaymentSummaryAdmin
         * @name OrderPaymentSummaryAdminCreate
         * @request POST:/api/OrderPaymentSummaryAdmin
         * @secure
         */
        orderPaymentSummaryAdminCreate: (data, params = {}) => this.request({
            path: `/api/OrderPaymentSummaryAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrderReleaseAdmin
         * @name OrderReleaseAdminCreate
         * @request POST:/api/OrderReleaseAdmin
         * @secure
         */
        orderReleaseAdminCreate: (data, params = {}) => this.request({
            path: `/api/OrderReleaseAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrderReportsAdmin
         * @name OrderReportsAdminDetail
         * @request GET:/api/OrderReportsAdmin/{orderId}
         * @secure
         */
        orderReportsAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/OrderReportsAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Orders
         * @name OrderPackagesList
         * @request GET:/api/OrderPackages
         * @secure
         */
        orderPackagesList: (query, params = {}) => this.request({
            path: `/api/OrderPackages`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Orders
         * @name OrdersCheckCouponCreate
         * @request POST:/api/OrdersCheckCoupon
         * @secure
         */
        ordersCheckCouponCreate: (data, params = {}) => this.request({
            path: `/api/OrdersCheckCoupon`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Orders
         * @name OrdersCreateCreate
         * @request POST:/api/OrdersCreate
         * @secure
         */
        ordersCreateCreate: (data, params = {}) => this.request({
            path: `/api/OrdersCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Orders
         * @name OrdersHorseOfUserList
         * @request GET:/api/OrdersHorseOfUser
         * @secure
         */
        ordersHorseOfUserList: (params = {}) => this.request({
            path: `/api/OrdersHorseOfUser`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Orders
         * @name OrdersSummaryCreate
         * @request POST:/api/OrdersSummary
         * @secure
         */
        ordersSummaryCreate: (data, params = {}) => this.request({
            path: `/api/OrdersSummary`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrdersAdmin
         * @name OrdersAdminList
         * @request GET:/api/OrdersAdmin
         * @secure
         */
        ordersAdminList: (query, params = {}) => this.request({
            path: `/api/OrdersAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrdersCreateAdmin
         * @name OrdersCreateAdminCreate
         * @request POST:/api/OrdersCreateAdmin
         * @secure
         */
        ordersCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/OrdersCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrdersDeleteAdmin
         * @name OrdersDeleteAdminDelete
         * @request DELETE:/api/OrdersDeleteAdmin
         * @secure
         */
        ordersDeleteAdminDelete: (data, params = {}) => this.request({
            path: `/api/OrdersDeleteAdmin`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrdersDetailsAdmin
         * @name OrdersDetailsAdminDetail
         * @request GET:/api/OrdersDetailsAdmin/{orderId}
         * @secure
         */
        ordersDetailsAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/OrdersDetailsAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrdersReportAdmin
         * @name OrdersReportAdminList
         * @request GET:/api/OrdersReportAdmin
         * @secure
         */
        ordersReportAdminList: (query, params = {}) => this.request({
            path: `/api/OrdersReportAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrderSubmissionFormAdmin
         * @name OrderSubmissionFormAdminDetail
         * @request GET:/api/OrderSubmissionFormAdmin/{orderId}
         * @secure
         */
        orderSubmissionFormAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/OrderSubmissionFormAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrderSummary
         * @name OrderSummaryDetail
         * @request GET:/api/OrderSummary/{token}
         * @secure
         */
        orderSummaryDetail: (token, params = {}) => this.request({
            path: `/api/OrderSummary/${token}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrganizationAdmin
         * @name OrganizationAdminList
         * @request GET:/api/OrganizationAdmin
         * @secure
         */
        organizationAdminList: (query, params = {}) => this.request({
            path: `/api/OrganizationAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrganizationByNameOrAbbreviationAdmin
         * @name OrganizationByNameOrAbbreviationAdminList
         * @request GET:/api/OrganizationByNameOrAbbreviationAdmin
         * @secure
         */
        organizationByNameOrAbbreviationAdminList: (query, params = {}) => this.request({
            path: `/api/OrganizationByNameOrAbbreviationAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrganizationCreateAdmin
         * @name OrganizationCreateAdminCreate
         * @request POST:/api/OrganizationCreateAdmin
         * @secure
         */
        organizationCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/OrganizationCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrganizationDetailsAdmin
         * @name OrganizationDetailsAdminDetail
         * @request GET:/api/OrganizationDetailsAdmin/{organizationId}
         * @secure
         */
        organizationDetailsAdminDetail: (organizationId, params = {}) => this.request({
            path: `/api/OrganizationDetailsAdmin/${organizationId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrganizationStatusUpdateAdmin
         * @name OrganizationStatusUpdateAdminCreate
         * @request POST:/api/OrganizationStatusUpdateAdmin
         * @secure
         */
        organizationStatusUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/OrganizationStatusUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags OrganizationUpdateAdmin
         * @name OrganizationUpdateAdminUpdate
         * @request PUT:/api/OrganizationUpdateAdmin
         * @secure
         */
        organizationUpdateAdminUpdate: (data, params = {}) => this.request({
            path: `/api/OrganizationUpdateAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackageGroupsTable
         * @name PackageGroupsTableList
         * @request GET:/api/PackageGroupsTable
         * @secure
         */
        packageGroupsTableList: (query, params = {}) => this.request({
            path: `/api/PackageGroupsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackageGroupsTableCreate
         * @name PackageGroupsTableCreateCreate
         * @request POST:/api/PackageGroupsTableCreate
         * @secure
         */
        packageGroupsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/PackageGroupsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackageGroupsTableDelete
         * @name PackageGroupsTableDeleteDelete
         * @request DELETE:/api/PackageGroupsTableDelete
         * @secure
         */
        packageGroupsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/PackageGroupsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackageGroupsTableDetails
         * @name PackageGroupsTableDetailsDetail
         * @request GET:/api/PackageGroupsTableDetails/{entityId}
         * @secure
         */
        packageGroupsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/PackageGroupsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackageGroupsTableUpdate
         * @name PackageGroupsTableUpdateUpdate
         * @request PUT:/api/PackageGroupsTableUpdate
         * @secure
         */
        packageGroupsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/PackageGroupsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackagesBundleUpdateAdmin
         * @name PackagesBundleUpdateAdminPartialUpdate
         * @request PATCH:/api/PackagesBundleUpdateAdmin
         * @secure
         */
        packagesBundleUpdateAdminPartialUpdate: (data, params = {}) => this.request({
            path: `/api/PackagesBundleUpdateAdmin`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackagesReadByFiltersAdmin
         * @name PackagesReadByFiltersAdminCreate
         * @request POST:/api/PackagesReadByFiltersAdmin
         * @secure
         */
        packagesReadByFiltersAdminCreate: (data, params = {}) => this.request({
            path: `/api/PackagesReadByFiltersAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackagesStatusUpdateAdmin
         * @name PackagesStatusUpdateAdminPartialUpdate
         * @request PATCH:/api/PackagesStatusUpdateAdmin
         * @secure
         */
        packagesStatusUpdateAdminPartialUpdate: (data, params = {}) => this.request({
            path: `/api/PackagesStatusUpdateAdmin`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackagesTableCreate
         * @name PackagesTableCreateCreate
         * @request POST:/api/PackagesTableCreate
         * @secure
         */
        packagesTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/PackagesTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackagesTableDelete
         * @name PackagesTableDeleteDelete
         * @request DELETE:/api/PackagesTableDelete
         * @secure
         */
        packagesTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/PackagesTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackagesTableDetails
         * @name PackagesTableDetailsDetail
         * @request GET:/api/PackagesTableDetails/{packageId}
         * @secure
         */
        packagesTableDetailsDetail: (packageId, params = {}) => this.request({
            path: `/api/PackagesTableDetails/${packageId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackagesTableFiltersAdmin
         * @name PackagesTableFiltersAdminList
         * @request GET:/api/PackagesTableFiltersAdmin
         * @secure
         */
        packagesTableFiltersAdminList: (params = {}) => this.request({
            path: `/api/PackagesTableFiltersAdmin`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PackagesTableUpdate
         * @name PackagesTableUpdateUpdate
         * @request PUT:/api/PackagesTableUpdate
         * @secure
         */
        packagesTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/PackagesTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PaidOrdersReportAdmin
         * @name PaidOrdersReportAdminList
         * @request GET:/api/PaidOrdersReportAdmin
         * @secure
         */
        paidOrdersReportAdminList: (query, params = {}) => this.request({
            path: `/api/PaidOrdersReportAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PanelsTable
         * @name PanelsTableList
         * @request GET:/api/PanelsTable
         * @secure
         */
        panelsTableList: (query, params = {}) => this.request({
            path: `/api/PanelsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PanelsTableCreate
         * @name PanelsTableCreateCreate
         * @request POST:/api/PanelsTableCreate
         * @secure
         */
        panelsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/PanelsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PanelsTableDelete
         * @name PanelsTableDeleteDelete
         * @request DELETE:/api/PanelsTableDelete
         * @secure
         */
        panelsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/PanelsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PanelsTableDetails
         * @name PanelsTableDetailsDetail
         * @request GET:/api/PanelsTableDetails/{entityId}
         * @secure
         */
        panelsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/PanelsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PanelsTableUpdate
         * @name PanelsTableUpdateUpdate
         * @request PUT:/api/PanelsTableUpdate
         * @secure
         */
        panelsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/PanelsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PartialColorGroupsTable
         * @name PartialColorGroupsTableList
         * @request GET:/api/PartialColorGroupsTable
         * @secure
         */
        partialColorGroupsTableList: (query, params = {}) => this.request({
            path: `/api/PartialColorGroupsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PartialColorGroupsTableCreate
         * @name PartialColorGroupsTableCreateCreate
         * @request POST:/api/PartialColorGroupsTableCreate
         * @secure
         */
        partialColorGroupsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/PartialColorGroupsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PartialColorGroupsTableDelete
         * @name PartialColorGroupsTableDeleteDelete
         * @request DELETE:/api/PartialColorGroupsTableDelete
         * @secure
         */
        partialColorGroupsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/PartialColorGroupsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PartialColorGroupsTableDetails
         * @name PartialColorGroupsTableDetailsDetail
         * @request GET:/api/PartialColorGroupsTableDetails/{entityId}
         * @secure
         */
        partialColorGroupsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/PartialColorGroupsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PartialColorGroupsTableUpdate
         * @name PartialColorGroupsTableUpdateUpdate
         * @request PUT:/api/PartialColorGroupsTableUpdate
         * @secure
         */
        partialColorGroupsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/PartialColorGroupsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PartialColorsTable
         * @name PartialColorsTableList
         * @request GET:/api/PartialColorsTable
         * @secure
         */
        partialColorsTableList: (query, params = {}) => this.request({
            path: `/api/PartialColorsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PartialColorsTableCreate
         * @name PartialColorsTableCreateCreate
         * @request POST:/api/PartialColorsTableCreate
         * @secure
         */
        partialColorsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/PartialColorsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PartialColorsTableDelete
         * @name PartialColorsTableDeleteDelete
         * @request DELETE:/api/PartialColorsTableDelete
         * @secure
         */
        partialColorsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/PartialColorsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PartialColorsTableDetails
         * @name PartialColorsTableDetailsDetail
         * @request GET:/api/PartialColorsTableDetails/{entityId}
         * @secure
         */
        partialColorsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/PartialColorsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PartialColorsTableUpdate
         * @name PartialColorsTableUpdateUpdate
         * @request PUT:/api/PartialColorsTableUpdate
         * @secure
         */
        partialColorsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/PartialColorsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PaymentAdmin
         * @name PaymentAdminDetail
         * @request GET:/api/PaymentAdmin/{orderId}
         * @secure
         */
        paymentAdminDetail: (orderId, params = {}) => this.request({
            path: `/api/PaymentAdmin/${orderId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PaymentUpdateAdmin
         * @name PaymentUpdateAdminCreate
         * @request POST:/api/PaymentUpdateAdmin
         * @secure
         */
        paymentUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/PaymentUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PayPalOrder
         * @name PayPalOrderList
         * @request GET:/api/PayPalOrder
         * @secure
         */
        payPalOrderList: (query, params = {}) => this.request({
            path: `/api/PayPalOrder`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PayPalWebHook
         * @name PayPalWebHookCreate
         * @request POST:/api/PayPalWebHook
         * @secure
         */
        payPalWebHookCreate: (params = {}) => this.request({
            path: `/api/PayPalWebHook`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Permission
         * @name PermissionList
         * @request GET:/api/Permission
         * @secure
         */
        permissionList: (params = {}) => this.request({
            path: `/api/Permission`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PermissionTable
         * @name PermissionTableList
         * @request GET:/api/PermissionTable
         * @secure
         */
        permissionTableList: (query, params = {}) => this.request({
            path: `/api/PermissionTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PermissionTableCreate
         * @name PermissionTableCreateCreate
         * @request POST:/api/PermissionTableCreate
         * @secure
         */
        permissionTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/PermissionTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PermissionTableDelete
         * @name PermissionTableDeleteDelete
         * @request DELETE:/api/PermissionTableDelete
         * @secure
         */
        permissionTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/PermissionTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PermissionTableDetails
         * @name PermissionTableDetailsDetail
         * @request GET:/api/PermissionTableDetails/{entityId}
         * @secure
         */
        permissionTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/PermissionTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PermissionTableUpdate
         * @name PermissionTableUpdateUpdate
         * @request PUT:/api/PermissionTableUpdate
         * @secure
         */
        permissionTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/PermissionTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PhenotypeGroupImage
         * @name PhenotypeGroupImageDetail
         * @request GET:/api/PhenotypeGroupImage/{entityId}
         * @secure
         */
        phenotypeGroupImageDetail: (entityId, params = {}) => this.request({
            path: `/api/PhenotypeGroupImage/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PhenotypeGroupImageCreate
         * @name PhenotypeGroupImageCreateCreate
         * @request POST:/api/PhenotypeGroupImageCreate
         * @secure
         */
        phenotypeGroupImageCreateCreate: (data, params = {}) => this.request({
            path: `/api/PhenotypeGroupImageCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PhenotypeGroupImageDelete
         * @name PhenotypeGroupImageDeleteDelete
         * @request DELETE:/api/PhenotypeGroupImageDelete
         * @secure
         */
        phenotypeGroupImageDeleteDelete: (data, params = {}) => this.request({
            path: `/api/PhenotypeGroupImageDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PrintableReportPreferences
         * @name PrintableReportPreferencesList
         * @request GET:/api/PrintableReportPreferences
         * @secure
         */
        printableReportPreferencesList: (params = {}) => this.request({
            path: `/api/PrintableReportPreferences`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PrintableReportPreferences
         * @name PrintableReportPreferencesHideCreate
         * @request POST:/api/PrintableReportPreferencesHide
         * @secure
         */
        printableReportPreferencesHideCreate: (data, params = {}) => this.request({
            path: `/api/PrintableReportPreferencesHide`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PrintableReportPreferences
         * @name PrintableReportPreferencesShowCreate
         * @request POST:/api/PrintableReportPreferencesShow
         * @secure
         */
        printableReportPreferencesShowCreate: (data, params = {}) => this.request({
            path: `/api/PrintableReportPreferencesShow`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PrintableReportPreferences
         * @name PrintableReportPreferencesSummaryUpdateUpdate
         * @request PUT:/api/PrintableReportPreferencesSummaryUpdate
         * @secure
         */
        printableReportPreferencesSummaryUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/PrintableReportPreferencesSummaryUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PrioritizedTestSequencesTable
         * @name PrioritizedTestSequencesTableList
         * @request GET:/api/PrioritizedTestSequencesTable
         * @secure
         */
        prioritizedTestSequencesTableList: (query, params = {}) => this.request({
            path: `/api/PrioritizedTestSequencesTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PrioritizedTestSequencesTableCreate
         * @name PrioritizedTestSequencesTableCreateCreate
         * @request POST:/api/PrioritizedTestSequencesTableCreate
         * @secure
         */
        prioritizedTestSequencesTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/PrioritizedTestSequencesTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PrioritizedTestSequencesTableDelete
         * @name PrioritizedTestSequencesTableDeleteDelete
         * @request DELETE:/api/PrioritizedTestSequencesTableDelete
         * @secure
         */
        prioritizedTestSequencesTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/PrioritizedTestSequencesTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PrioritizedTestSequencesTableDetails
         * @name PrioritizedTestSequencesTableDetailsDetail
         * @request GET:/api/PrioritizedTestSequencesTableDetails/{entityId}
         * @secure
         */
        prioritizedTestSequencesTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/PrioritizedTestSequencesTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PrioritizedTestSequencesTableUpdate
         * @name PrioritizedTestSequencesTableUpdateUpdate
         * @request PUT:/api/PrioritizedTestSequencesTableUpdate
         * @secure
         */
        prioritizedTestSequencesTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/PrioritizedTestSequencesTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PurchasableTestAdmin
         * @name PurchasableTestAdminList
         * @request GET:/api/PurchasableTestAdmin
         * @secure
         */
        purchasableTestAdminList: (query, params = {}) => this.request({
            path: `/api/PurchasableTestAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PurchasableTestAdminCreate
         * @name PurchasableTestAdminCreateCreate
         * @request POST:/api/PurchasableTestAdminCreate
         * @secure
         */
        purchasableTestAdminCreateCreate: (data, params = {}) => this.request({
            path: `/api/PurchasableTestAdminCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PurchasableTestAdminDetails
         * @name PurchasableTestAdminDetailsDetail
         * @request GET:/api/PurchasableTestAdminDetails/{testId}
         * @secure
         */
        purchasableTestAdminDetailsDetail: (testId, params = {}) => this.request({
            path: `/api/PurchasableTestAdminDetails/${testId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags PurchasableTestAdminUpdate
         * @name PurchasableTestAdminUpdateUpdate
         * @request PUT:/api/PurchasableTestAdminUpdate
         * @secure
         */
        purchasableTestAdminUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/PurchasableTestAdminUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags PurchasableTestSetStatusAdmin
         * @name PurchasableTestSetStatusAdminPartialUpdate
         * @request PATCH:/api/PurchasableTestSetStatusAdmin
         * @secure
         */
        purchasableTestSetStatusAdminPartialUpdate: (data, params = {}) => this.request({
            path: `/api/PurchasableTestSetStatusAdmin`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags ReportTypesTable
         * @name ReportTypesTableList
         * @request GET:/api/ReportTypesTable
         * @secure
         */
        reportTypesTableList: (query, params = {}) => this.request({
            path: `/api/ReportTypesTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ReportTypesTableCreate
         * @name ReportTypesTableCreateCreate
         * @request POST:/api/ReportTypesTableCreate
         * @secure
         */
        reportTypesTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/ReportTypesTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ReportTypesTableDelete
         * @name ReportTypesTableDeleteDelete
         * @request DELETE:/api/ReportTypesTableDelete
         * @secure
         */
        reportTypesTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/ReportTypesTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ReportTypesTableDetails
         * @name ReportTypesTableDetailsDetail
         * @request GET:/api/ReportTypesTableDetails/{entityId}
         * @secure
         */
        reportTypesTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/ReportTypesTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags ReportTypesTableUpdate
         * @name ReportTypesTableUpdateUpdate
         * @request PUT:/api/ReportTypesTableUpdate
         * @secure
         */
        reportTypesTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/ReportTypesTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags SampleActivateAdmin
         * @name SampleActivateAdminCreate
         * @request POST:/api/SampleActivateAdmin
         * @secure
         */
        sampleActivateAdminCreate: (data, params = {}) => this.request({
            path: `/api/SampleActivateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags SampleAdmin
         * @name SampleAdminDetail
         * @request GET:/api/SampleAdmin/{horseId}
         * @secure
         */
        sampleAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/SampleAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags SampleCreateAdmin
         * @name SampleCreateAdminCreate
         * @request POST:/api/SampleCreateAdmin
         * @secure
         */
        sampleCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/SampleCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags SampleOrderAdmin
         * @name SampleOrderAdminChangeSampleInOrderCreate
         * @request POST:/api/SampleOrderAdmin/ChangeSampleInOrder
         * @secure
         */
        sampleOrderAdminChangeSampleInOrderCreate: (data, params = {}) => this.request({
            path: `/api/SampleOrderAdmin/ChangeSampleInOrder`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags SampleRequestAdmin
         * @name SampleRequestAdminCreate
         * @request POST:/api/SampleRequestAdmin
         * @secure
         */
        sampleRequestAdminCreate: (data, params = {}) => this.request({
            path: `/api/SampleRequestAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags SampleStatusUpdateAdmin
         * @name SampleStatusUpdateAdminCreate
         * @request POST:/api/SampleStatusUpdateAdmin
         * @secure
         */
        sampleStatusUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/SampleStatusUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags SampleUpdateAdmin
         * @name SampleUpdateAdminCreate
         * @request POST:/api/SampleUpdateAdmin
         * @secure
         */
        sampleUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/SampleUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags ShopifyWebHook
         * @name ShopifyWebHookCreate
         * @request POST:/api/ShopifyWebHook
         * @secure
         */
        shopifyWebHookCreate: (params = {}) => this.request({
            path: `/api/ShopifyWebHook`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Short tandem repeat
         * @name ShortTandemRepeatLocusUpdateAdminCreate
         * @request POST:/api/ShortTandemRepeatLocusUpdateAdmin
         * @secure
         */
        shortTandemRepeatLocusUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/ShortTandemRepeatLocusUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Short tandem repeat
         * @name ShortTandemRepeatReportAdminDetail
         * @request GET:/api/ShortTandemRepeatReportAdmin/{horseId}
         * @secure
         */
        shortTandemRepeatReportAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/ShortTandemRepeatReportAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Short tandem repeat
         * @name ShortTandemRepeatReportReviewAdminDetail
         * @request GET:/api/ShortTandemRepeatReportReviewAdmin/{horseId}
         * @secure
         */
        shortTandemRepeatReportReviewAdminDetail: (horseId, params = {}) => this.request({
            path: `/api/ShortTandemRepeatReportReviewAdmin/${horseId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Short tandem repeat
         * @name ShortTandemRepeatReportUploadCreate
         * @request POST:/api/ShortTandemRepeatReportUpload
         * @secure
         */
        shortTandemRepeatReportUploadCreate: (data, params = {}) => this.request({
            path: `/api/ShortTandemRepeatReportUpload`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags SourceCompanyTable
         * @name SourceCompanyTableList
         * @request GET:/api/SourceCompanyTable
         * @secure
         */
        sourceCompanyTableList: (query, params = {}) => this.request({
            path: `/api/SourceCompanyTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags SourceCompanyTableCreate
         * @name SourceCompanyTableCreateCreate
         * @request POST:/api/SourceCompanyTableCreate
         * @secure
         */
        sourceCompanyTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/SourceCompanyTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags SourceCompanyTableDelete
         * @name SourceCompanyTableDeleteDelete
         * @request DELETE:/api/SourceCompanyTableDelete
         * @secure
         */
        sourceCompanyTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/SourceCompanyTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags SourceCompanyTableDetails
         * @name SourceCompanyTableDetailsDetail
         * @request GET:/api/SourceCompanyTableDetails/{entityId}
         * @secure
         */
        sourceCompanyTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/SourceCompanyTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags SourceCompanyTableUpdate
         * @name SourceCompanyTableUpdateUpdate
         * @request PUT:/api/SourceCompanyTableUpdate
         * @secure
         */
        sourceCompanyTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/SourceCompanyTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StaticResourcesAdmin
         * @name StaticResourcesAdminList
         * @request GET:/api/StaticResourcesAdmin
         * @secure
         */
        staticResourcesAdminList: (query, params = {}) => this.request({
            path: `/api/StaticResourcesAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StaticResourcesAdmin
         * @name StaticResourcesCreateAdminCreate
         * @request POST:/api/StaticResourcesCreateAdmin
         * @secure
         */
        staticResourcesCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/StaticResourcesCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StaticResourcesAdmin
         * @name StaticResourcesDeleteAdminDelete
         * @request DELETE:/api/StaticResourcesDeleteAdmin/{id}
         * @secure
         */
        staticResourcesDeleteAdminDelete: (id, params = {}) => this.request({
            path: `/api/StaticResourcesDeleteAdmin/${id}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StaticResourcesAdmin
         * @name StaticResourcesDetailsAdminDetail
         * @request GET:/api/StaticResourcesDetailsAdmin/{id}
         * @secure
         */
        staticResourcesDetailsAdminDetail: (id, params = {}) => this.request({
            path: `/api/StaticResourcesDetailsAdmin/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StaticResourcesAdmin
         * @name StaticResourcesResourceUpdateAdminCreate
         * @request POST:/api/StaticResourcesResourceUpdateAdmin
         * @secure
         */
        staticResourcesResourceUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/StaticResourcesResourceUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StaticResourcesAdmin
         * @name StaticResourcesUpdateAdminUpdate
         * @request PUT:/api/StaticResourcesUpdateAdmin
         * @secure
         */
        staticResourcesUpdateAdminUpdate: (data, params = {}) => this.request({
            path: `/api/StaticResourcesUpdateAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeAssociationOrderPaymentIntent
         * @name StripeAssociationOrderPaymentIntentCreate
         * @request POST:/api/StripeAssociationOrderPaymentIntent
         * @secure
         */
        stripeAssociationOrderPaymentIntentCreate: (data, params = {}) => this.request({
            path: `/api/StripeAssociationOrderPaymentIntent`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeDefaultPaymentMethod
         * @name StripeSubscriptionCustomersDefaultPaymentMethodList
         * @request GET:/api/StripeSubscription/Customers/DefaultPaymentMethod
         * @secure
         */
        stripeSubscriptionCustomersDefaultPaymentMethodList: (params = {}) => this.request({
            path: `/api/StripeSubscription/Customers/DefaultPaymentMethod`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeDefaultPaymentMethodSet
         * @name StripeDefaultPaymentMethodSetCreate
         * @request POST:/api/StripeDefaultPaymentMethodSet
         * @secure
         */
        stripeDefaultPaymentMethodSetCreate: (data, params = {}) => this.request({
            path: `/api/StripeDefaultPaymentMethodSet`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripePaymentIntent
         * @name StripePaymentIntentCreate
         * @request POST:/api/StripePaymentIntent
         * @secure
         */
        stripePaymentIntentCreate: (data, params = {}) => this.request({
            path: `/api/StripePaymentIntent`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeProductAdmin
         * @name StripeProductAdminList
         * @request GET:/api/StripeProductAdmin
         * @secure
         */
        stripeProductAdminList: (query, params = {}) => this.request({
            path: `/api/StripeProductAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeProductCreateAdmin
         * @name StripeProductCreateAdminCreate
         * @request POST:/api/StripeProductCreateAdmin
         * @secure
         */
        stripeProductCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/StripeProductCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeProductDetailsAdmin
         * @name StripeProductDetailsAdminDetail
         * @request GET:/api/StripeProductDetailsAdmin/{productId}
         * @secure
         */
        stripeProductDetailsAdminDetail: (productId, params = {}) => this.request({
            path: `/api/StripeProductDetailsAdmin/${productId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeProductUpdateAdmin
         * @name StripeProductUpdateAdminUpdate
         * @request PUT:/api/StripeProductUpdateAdmin
         * @secure
         */
        stripeProductUpdateAdminUpdate: (data, params = {}) => this.request({
            path: `/api/StripeProductUpdateAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptionAdmin
         * @name StripeSubscriptionAdminList
         * @request GET:/api/StripeSubscriptionAdmin
         * @secure
         */
        stripeSubscriptionAdminList: (query, params = {}) => this.request({
            path: `/api/StripeSubscriptionAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptionCancelAdmin
         * @name StripeSubscriptionCancelAdminCreate
         * @request POST:/api/StripeSubscriptionCancelAdmin
         * @secure
         */
        stripeSubscriptionCancelAdminCreate: (data, params = {}) => this.request({
            path: `/api/StripeSubscriptionCancelAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptionCustomerCreate
         * @name StripeSubscriptionCustomerCreateCreate
         * @request POST:/api/StripeSubscriptionCustomerCreate
         * @secure
         */
        stripeSubscriptionCustomerCreateCreate: (params = {}) => this.request({
            path: `/api/StripeSubscriptionCustomerCreate`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptionCustomerExist
         * @name StripeSubscriptionCustomerExistList
         * @request GET:/api/StripeSubscriptionCustomerExist
         * @secure
         */
        stripeSubscriptionCustomerExistList: (params = {}) => this.request({
            path: `/api/StripeSubscriptionCustomerExist`,
            method: "GET",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptionOverwriteAdmin
         * @name StripeSubscriptionOverwriteAdminCreate
         * @request POST:/api/StripeSubscriptionOverwriteAdmin
         * @secure
         */
        stripeSubscriptionOverwriteAdminCreate: (data, params = {}) => this.request({
            path: `/api/StripeSubscriptionOverwriteAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptions
         * @name StripeScheduledSubscriptionList
         * @request GET:/api/StripeScheduledSubscription
         * @secure
         */
        stripeScheduledSubscriptionList: (params = {}) => this.request({
            path: `/api/StripeScheduledSubscription`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptions
         * @name StripeSubscriptionClientSecretList
         * @request GET:/api/StripeSubscriptionClientSecret
         * @secure
         */
        stripeSubscriptionClientSecretList: (params = {}) => this.request({
            path: `/api/StripeSubscriptionClientSecret`,
            method: "GET",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptions
         * @name StripeSubscriptionList
         * @request GET:/api/StripeSubscription
         * @secure
         */
        stripeSubscriptionList: (params = {}) => this.request({
            path: `/api/StripeSubscription`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptions
         * @name StripeSubscriptionCreateCreate
         * @request POST:/api/StripeSubscriptionCreate
         * @secure
         */
        stripeSubscriptionCreateCreate: (data, params = {}) => this.request({
            path: `/api/StripeSubscriptionCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptions
         * @name StripeSubscriptionInvoiceRetryCreate
         * @request POST:/api/StripeSubscriptionInvoiceRetry
         * @secure
         */
        stripeSubscriptionInvoiceRetryCreate: (data, params = {}) => this.request({
            path: `/api/StripeSubscriptionInvoiceRetry`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptions
         * @name StripeSubscriptionRefreshCreate
         * @request POST:/api/StripeSubscriptionRefresh
         * @secure
         */
        stripeSubscriptionRefreshCreate: (data, params = {}) => this.request({
            path: `/api/StripeSubscriptionRefresh`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeSubscriptions
         * @name StripeSubscriptionRenewCreate
         * @request POST:/api/StripeSubscriptionRenew/{id}
         * @secure
         */
        stripeSubscriptionRenewCreate: (id, params = {}) => this.request({
            path: `/api/StripeSubscriptionRenew/${id}`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeUserProduct
         * @name StripeSubscriptionSubscriptionsChangeProductCreate
         * @request POST:/api/StripeSubscription/Subscriptions/ChangeProduct/{subscriptionProductId}
         * @secure
         */
        stripeSubscriptionSubscriptionsChangeProductCreate: (subscriptionProductId, params = {}) => this.request({
            path: `/api/StripeSubscription/Subscriptions/ChangeProduct/${subscriptionProductId}`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags StripeWebHook
         * @name StripeWebHookCreate
         * @request POST:/api/StripeWebHook
         * @secure
         */
        stripeWebHookCreate: (params = {}) => this.request({
            path: `/api/StripeWebHook`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags SubmissionForm
         * @name OrderSubmissionFormList
         * @request GET:/api/OrderSubmissionForm
         * @secure
         */
        orderSubmissionFormList: (query, params = {}) => this.request({
            path: `/api/OrderSubmissionForm`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags SubmissionForm
         * @name AnimalSubmissionFormList
         * @request GET:/api/AnimalSubmissionForm
         * @secure
         */
        animalSubmissionFormList: (query, params = {}) => this.request({
            path: `/api/AnimalSubmissionForm`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags SubmissionForm
         * @name ActivationCodesSubmissionFormList
         * @request GET:/api/ActivationCodesSubmissionForm
         * @secure
         */
        activationCodesSubmissionFormList: (query, params = {}) => this.request({
            path: `/api/ActivationCodesSubmissionForm`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags SubscriptionCancelation
         * @name StripeSubscriptionCancelDelete
         * @request DELETE:/api/StripeSubscriptionCancel/{id}
         * @secure
         */
        stripeSubscriptionCancelDelete: (id, params = {}) => this.request({
            path: `/api/StripeSubscriptionCancel/${id}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags SubscriptionCancelation
         * @name StripeSubscriptionCancelImmediatelyDelete
         * @request DELETE:/api/StripeSubscriptionCancelImmediately/{id}
         * @secure
         */
        stripeSubscriptionCancelImmediatelyDelete: (id, params = {}) => this.request({
            path: `/api/StripeSubscriptionCancelImmediately/${id}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags SubscriptionCancelation
         * @name StripeSubscriptionScheduledCancelDelete
         * @request DELETE:/api/StripeSubscriptionScheduledCancel/{id}
         * @secure
         */
        stripeSubscriptionScheduledCancelDelete: (id, params = {}) => this.request({
            path: `/api/StripeSubscriptionScheduledCancel/${id}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags TestsTable
         * @name TestsTableList
         * @request GET:/api/TestsTable
         * @secure
         */
        testsTableList: (query, params = {}) => this.request({
            path: `/api/TestsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TestsTableCreate
         * @name TestsTableCreateCreate
         * @request POST:/api/TestsTableCreate
         * @secure
         */
        testsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/TestsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TestsTableDelete
         * @name TestsTableDeleteDelete
         * @request DELETE:/api/TestsTableDelete
         * @secure
         */
        testsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/TestsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TestsTableDetails
         * @name TestsTableDetailsDetail
         * @request GET:/api/TestsTableDetails/{entityId}
         * @secure
         */
        testsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/TestsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TestsTableUpdate
         * @name TestsTableUpdateUpdate
         * @request PUT:/api/TestsTableUpdate
         * @secure
         */
        testsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/TestsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TraitSequencesTable
         * @name TraitSequencesTableList
         * @request GET:/api/TraitSequencesTable
         * @secure
         */
        traitSequencesTableList: (query, params = {}) => this.request({
            path: `/api/TraitSequencesTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TraitSequencesTableCreate
         * @name TraitSequencesTableCreateCreate
         * @request POST:/api/TraitSequencesTableCreate
         * @secure
         */
        traitSequencesTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/TraitSequencesTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TraitSequencesTableDelete
         * @name TraitSequencesTableDeleteDelete
         * @request DELETE:/api/TraitSequencesTableDelete
         * @secure
         */
        traitSequencesTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/TraitSequencesTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TraitSequencesTableDetails
         * @name TraitSequencesTableDetailsDetail
         * @request GET:/api/TraitSequencesTableDetails/{entityId}
         * @secure
         */
        traitSequencesTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/TraitSequencesTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TraitSequencesTableUpdate
         * @name TraitSequencesTableUpdateUpdate
         * @request PUT:/api/TraitSequencesTableUpdate
         * @secure
         */
        traitSequencesTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/TraitSequencesTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TraitsTable
         * @name TraitsTableList
         * @request GET:/api/TraitsTable
         * @secure
         */
        traitsTableList: (query, params = {}) => this.request({
            path: `/api/TraitsTable`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TraitsTableCreate
         * @name TraitsTableCreateCreate
         * @request POST:/api/TraitsTableCreate
         * @secure
         */
        traitsTableCreateCreate: (data, params = {}) => this.request({
            path: `/api/TraitsTableCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TraitsTableDelete
         * @name TraitsTableDeleteDelete
         * @request DELETE:/api/TraitsTableDelete
         * @secure
         */
        traitsTableDeleteDelete: (data, params = {}) => this.request({
            path: `/api/TraitsTableDelete`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TraitsTableDetails
         * @name TraitsTableDetailsDetail
         * @request GET:/api/TraitsTableDetails/{entityId}
         * @secure
         */
        traitsTableDetailsDetail: (entityId, params = {}) => this.request({
            path: `/api/TraitsTableDetails/${entityId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags TraitsTableUpdate
         * @name TraitsTableUpdateUpdate
         * @request PUT:/api/TraitsTableUpdate
         * @secure
         */
        traitsTableUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/TraitsTableUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UpdateInbreedingCoefficientAdmin
         * @name UpdateInbreedingCoefficientAdminUpdate
         * @request PUT:/api/UpdateInbreedingCoefficientAdmin
         * @secure
         */
        updateInbreedingCoefficientAdminUpdate: (data, params = {}) => this.request({
            path: `/api/UpdateInbreedingCoefficientAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserAnimalsAdmin
         * @name UserAnimalsAdminDetail
         * @request GET:/api/UserAnimalsAdmin/{userId}
         * @secure
         */
        userAnimalsAdminDetail: (userId, query, params = {}) => this.request({
            path: `/api/UserAnimalsAdmin/${userId}`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserAvatarDeleteAdmin
         * @name UserAvatarDeleteAdminDelete
         * @request DELETE:/api/UserAvatarDeleteAdmin/{userId}
         * @secure
         */
        userAvatarDeleteAdminDelete: (userId, params = {}) => this.request({
            path: `/api/UserAvatarDeleteAdmin/${userId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserAvatarDeleteUser
         * @name UserAvatarDeleteUserDelete
         * @request DELETE:/api/UserAvatarDeleteUser/{userId}
         * @secure
         */
        userAvatarDeleteUserDelete: (userId, params = {}) => this.request({
            path: `/api/UserAvatarDeleteUser/${userId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserAvatarUpdateAdmin
         * @name UserAvatarUpdateAdminCreate
         * @request POST:/api/UserAvatarUpdateAdmin
         * @secure
         */
        userAvatarUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/UserAvatarUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserAvatarUpdateUser
         * @name UserAvatarUpdateUserCreate
         * @request POST:/api/UserAvatarUpdateUser
         * @secure
         */
        userAvatarUpdateUserCreate: (data, params = {}) => this.request({
            path: `/api/UserAvatarUpdateUser`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserContactRequest
         * @name UserContactRequestCreate
         * @request POST:/api/UserContactRequest
         * @secure
         */
        userContactRequestCreate: (data, params = {}) => this.request({
            path: `/api/UserContactRequest`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserContactRequestAssociation
         * @name UserContactRequestAssociationCreate
         * @request POST:/api/UserContactRequestAssociation
         * @secure
         */
        userContactRequestAssociationCreate: (data, params = {}) => this.request({
            path: `/api/UserContactRequestAssociation`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserDogsAdmin
         * @name UserDogsAdminDetail
         * @request GET:/api/UserDogsAdmin/{userId}
         * @secure
         */
        userDogsAdminDetail: (userId, params = {}) => this.request({
            path: `/api/UserDogsAdmin/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserHorsePackageBucket
         * @name UserHorsePackageBucketClearCreate
         * @request POST:/api/UserHorsePackageBucketClear
         * @secure
         */
        userHorsePackageBucketClearCreate: (params = {}) => this.request({
            path: `/api/UserHorsePackageBucketClear`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserHorsePackageBucket
         * @name UserHorsePackageBucketList
         * @request GET:/api/UserHorsePackageBucket
         * @secure
         */
        userHorsePackageBucketList: (params = {}) => this.request({
            path: `/api/UserHorsePackageBucket`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserHorsePackageBucket
         * @name UserHorsePackageBucketCreateCreate
         * @request POST:/api/UserHorsePackageBucketCreate
         * @secure
         */
        userHorsePackageBucketCreateCreate: (data, params = {}) => this.request({
            path: `/api/UserHorsePackageBucketCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserHorsePackageBucket
         * @name UserHorsePackageBucketDeleteCreate
         * @request POST:/api/UserHorsePackageBucketDelete
         * @secure
         */
        userHorsePackageBucketDeleteCreate: (data, params = {}) => this.request({
            path: `/api/UserHorsePackageBucketDelete`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserListItemRead
         * @name UserListItemReadDetail
         * @request GET:/api/UserListItemRead/{userId}
         * @secure
         */
        userListItemReadDetail: (userId, params = {}) => this.request({
            path: `/api/UserListItemRead/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserNotifications
         * @name UserNotificationCreateCreate
         * @request POST:/api/UserNotificationCreate
         * @secure
         */
        userNotificationCreateCreate: (data, params = {}) => this.request({
            path: `/api/UserNotificationCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserNotifications
         * @name UserNotificationDeleteDelete
         * @request DELETE:/api/UserNotificationDelete/{notificationId}
         * @secure
         */
        userNotificationDeleteDelete: (notificationId, params = {}) => this.request({
            path: `/api/UserNotificationDelete/${notificationId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserNotifications
         * @name UserNotificationHistoryIsReadUpdateAllUpdate
         * @request PUT:/api/UserNotificationHistoryIsReadUpdateAll
         * @secure
         */
        userNotificationHistoryIsReadUpdateAllUpdate: (params = {}) => this.request({
            path: `/api/UserNotificationHistoryIsReadUpdateAll`,
            method: "PUT",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserNotifications
         * @name UserNotificationHistoryIsReadUpdateUpdate
         * @request PUT:/api/UserNotificationHistoryIsReadUpdate
         * @secure
         */
        userNotificationHistoryIsReadUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UserNotificationHistoryIsReadUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserNotifications
         * @name UserNotificationHistoryReadList
         * @request GET:/api/UserNotificationHistoryRead
         * @secure
         */
        userNotificationHistoryReadList: (params = {}) => this.request({
            path: `/api/UserNotificationHistoryRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserNotifications
         * @name UserNotificationReadList
         * @request GET:/api/UserNotificationRead
         * @secure
         */
        userNotificationReadList: (params = {}) => this.request({
            path: `/api/UserNotificationRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserPermissionAdmin
         * @name UserPermissionAdminDetail
         * @request GET:/api/UserPermissionAdmin/{userId}
         * @secure
         */
        userPermissionAdminDetail: (userId, params = {}) => this.request({
            path: `/api/UserPermissionAdmin/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserPermissionUpdateAdmin
         * @name UserPermissionUpdateAdminCreate
         * @request POST:/api/UserPermissionUpdateAdmin
         * @secure
         */
        userPermissionUpdateAdminCreate: (data, params = {}) => this.request({
            path: `/api/UserPermissionUpdateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfile
         * @name UserProfileAnimalsList
         * @request GET:/api/UserProfileAnimals
         * @secure
         */
        userProfileAnimalsList: (query, params = {}) => this.request({
            path: `/api/UserProfileAnimals`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfile
         * @name UserProfileOrdersList
         * @request GET:/api/UserProfileOrders
         * @secure
         */
        userProfileOrdersList: (query, params = {}) => this.request({
            path: `/api/UserProfileOrders`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfileAdmin
         * @name UserProfileAdminDetail
         * @request GET:/api/UserProfileAdmin/{id}
         * @secure
         */
        userProfileAdminDetail: (id, params = {}) => this.request({
            path: `/api/UserProfileAdmin/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfileAnimalsAdmin
         * @name UserProfileAnimalsAdminList
         * @request GET:/api/UserProfileAnimalsAdmin
         * @secure
         */
        userProfileAnimalsAdminList: (query, params = {}) => this.request({
            path: `/api/UserProfileAnimalsAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfileAssociation
         * @name UserProfileAssociationDetail
         * @request GET:/api/UserProfileAssociation/{userId}
         * @secure
         */
        userProfileAssociationDetail: (userId, params = {}) => this.request({
            path: `/api/UserProfileAssociation/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfileHorsesAssociation
         * @name UserProfileHorsesAssociationList
         * @request GET:/api/UserProfileHorsesAssociation
         * @secure
         */
        userProfileHorsesAssociationList: (query, params = {}) => this.request({
            path: `/api/UserProfileHorsesAssociation`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersAddressUpdateUpdate
         * @request PUT:/api/UsersAddressUpdate
         * @secure
         */
        usersAddressUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersAddressUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersAvatarDeleteDelete
         * @request DELETE:/api/UsersAvatarDelete
         * @secure
         */
        usersAvatarDeleteDelete: (params = {}) => this.request({
            path: `/api/UsersAvatarDelete`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersAvatarUpdateUpdate
         * @request PUT:/api/UsersAvatarUpdate
         * @secure
         */
        usersAvatarUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersAvatarUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersCityUpdateUpdate
         * @request PUT:/api/UsersCityUpdate
         * @secure
         */
        usersCityUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersCityUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersCountryUpdateUpdate
         * @request PUT:/api/UsersCountryUpdate
         * @secure
         */
        usersCountryUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersCountryUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersFirstNameUpdateUpdate
         * @request PUT:/api/UsersFirstNameUpdate
         * @secure
         */
        usersFirstNameUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersFirstNameUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersLastNameUpdateUpdate
         * @request PUT:/api/UsersLastNameUpdate
         * @secure
         */
        usersLastNameUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersLastNameUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersLocationUpdateUpdate
         * @request PUT:/api/UsersLocationUpdate
         * @secure
         */
        usersLocationUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersLocationUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersMobileUpdateUpdate
         * @request PUT:/api/UsersMobileUpdate
         * @secure
         */
        usersMobileUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersMobileUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersPhoneUpdateUpdate
         * @request PUT:/api/UsersPhoneUpdate
         * @secure
         */
        usersPhoneUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersPhoneUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersProfileUpdateUpdate
         * @request PUT:/api/UsersProfileUpdate
         * @secure
         */
        usersProfileUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersProfileUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersSalutationUpdateUpdate
         * @request PUT:/api/UsersSalutationUpdate
         * @secure
         */
        usersSalutationUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersSalutationUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersStateUpdateUpdate
         * @request PUT:/api/UsersStateUpdate
         * @secure
         */
        usersStateUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersStateUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserProfiles
         * @name UsersZipUpdateUpdate
         * @request PUT:/api/UsersZipUpdate
         * @secure
         */
        usersZipUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersZipUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserRegistration
         * @name ConfirmEmailPartialUpdate
         * @request PATCH:/api/ConfirmEmail
         * @secure
         */
        confirmEmailPartialUpdate: (data, params = {}) => this.request({
            path: `/api/ConfirmEmail`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserRegistration
         * @name MailConfirmationPartialUpdate
         * @request PATCH:/api/MailConfirmation
         * @secure
         */
        mailConfirmationPartialUpdate: (data, params = {}) => this.request({
            path: `/api/MailConfirmation`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserRegistration
         * @name RegistrationCreate
         * @request POST:/api/Registration
         * @secure
         */
        registrationCreate: (data, params = {}) => this.request({
            path: `/api/Registration`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Users
         * @name UsersContactInformationList
         * @request GET:/api/UsersContactInformation
         * @secure
         */
        usersContactInformationList: (params = {}) => this.request({
            path: `/api/UsersContactInformation`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Users
         * @name UsersContactInformationUpdateUpdate
         * @request PUT:/api/UsersContactInformationUpdate
         * @secure
         */
        usersContactInformationUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersContactInformationUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Users
         * @name UsersCurrentList
         * @request GET:/api/UsersCurrent
         * @secure
         */
        usersCurrentList: (params = {}) => this.request({
            path: `/api/UsersCurrent`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Users
         * @name UsersGeneralAgreementUpdateCreate
         * @request POST:/api/UsersGeneralAgreementUpdate
         * @secure
         */
        usersGeneralAgreementUpdateCreate: (data, params = {}) => this.request({
            path: `/api/UsersGeneralAgreementUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Users
         * @name UsersGlobalPrivacySettingsList
         * @request GET:/api/UsersGlobalPrivacySettings
         * @secure
         */
        usersGlobalPrivacySettingsList: (params = {}) => this.request({
            path: `/api/UsersGlobalPrivacySettings`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Users
         * @name UsersGlobalPrivacySettingsUpdateCreate
         * @request POST:/api/UsersGlobalPrivacySettingsUpdate
         * @secure
         */
        usersGlobalPrivacySettingsUpdateCreate: (data, params = {}) => this.request({
            path: `/api/UsersGlobalPrivacySettingsUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags Users
         * @name UsersPasswordUpdateCreate
         * @request POST:/api/UsersPasswordUpdate
         * @secure
         */
        usersPasswordUpdateCreate: (data, params = {}) => this.request({
            path: `/api/UsersPasswordUpdate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Users
         * @name UsersPrivacySettingsList
         * @request GET:/api/UsersPrivacySettings
         * @secure
         */
        usersPrivacySettingsList: (params = {}) => this.request({
            path: `/api/UsersPrivacySettings`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags Users
         * @name UsersProfileDetail
         * @request GET:/api/UsersProfile/{id}
         * @secure
         */
        usersProfileDetail: (id, params = {}) => this.request({
            path: `/api/UsersProfile/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersAdmin
         * @name UsersAdminList
         * @request GET:/api/UsersAdmin
         * @secure
         */
        usersAdminList: (query, params = {}) => this.request({
            path: `/api/UsersAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersCreateAdmin
         * @name UsersCreateAdminCreate
         * @request POST:/api/UsersCreateAdmin
         * @secure
         */
        usersCreateAdminCreate: (data, params = {}) => this.request({
            path: `/api/UsersCreateAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersDeleteAdmin
         * @name UsersDeleteAdminDelete
         * @request DELETE:/api/UsersDeleteAdmin
         * @secure
         */
        usersDeleteAdminDelete: (data, params = {}) => this.request({
            path: `/api/UsersDeleteAdmin`,
            method: "DELETE",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersDetailsAdmin
         * @name UsersDetailsAdminDetail
         * @request GET:/api/UsersDetailsAdmin/{userId}
         * @secure
         */
        usersDetailsAdminDetail: (userId, params = {}) => this.request({
            path: `/api/UsersDetailsAdmin/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersDetailsInfoAdmin
         * @name UsersDetailsInfoAdminDetail
         * @request GET:/api/UsersDetailsInfoAdmin/{userId}
         * @secure
         */
        usersDetailsInfoAdminDetail: (userId, params = {}) => this.request({
            path: `/api/UsersDetailsInfoAdmin/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersEmailUpdateAdmin
         * @name UsersEmailUpdateAdminPartialUpdate
         * @request PATCH:/api/UsersEmailUpdateAdmin
         * @secure
         */
        usersEmailUpdateAdminPartialUpdate: (data, params = {}) => this.request({
            path: `/api/UsersEmailUpdateAdmin`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersPasswordUpdateAdmin
         * @name UsersPasswordUpdateAdminPartialUpdate
         * @request PATCH:/api/UsersPasswordUpdateAdmin
         * @secure
         */
        usersPasswordUpdateAdminPartialUpdate: (data, params = {}) => this.request({
            path: `/api/UsersPasswordUpdateAdmin`,
            method: "PATCH",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersPrivacy
         * @name UsersEmailPrivacyUpdateUpdate
         * @request PUT:/api/UsersEmailPrivacyUpdate
         * @secure
         */
        usersEmailPrivacyUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersEmailPrivacyUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersPrivacy
         * @name UsersLocationPrivacyUpdateUpdate
         * @request PUT:/api/UsersLocationPrivacyUpdate
         * @secure
         */
        usersLocationPrivacyUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersLocationPrivacyUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersPrivacy
         * @name UsersPhonePrivacyUpdateUpdate
         * @request PUT:/api/UsersPhonePrivacyUpdate
         * @secure
         */
        usersPhonePrivacyUpdateUpdate: (data, params = {}) => this.request({
            path: `/api/UsersPhonePrivacyUpdate`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersRestoreAdmin
         * @name UsersRestoreAdminCreate
         * @request POST:/api/UsersRestoreAdmin
         * @secure
         */
        usersRestoreAdminCreate: (data, params = {}) => this.request({
            path: `/api/UsersRestoreAdmin`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UsersUpdateAdmin
         * @name UsersUpdateAdminUpdate
         * @request PUT:/api/UsersUpdateAdmin
         * @secure
         */
        usersUpdateAdminUpdate: (data, params = {}) => this.request({
            path: `/api/UsersUpdateAdmin`,
            method: "PUT",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChatLeaveDuty
         * @name UserToAdminChatLeaveDutyCreate
         * @request POST:/api/UserToAdminChatLeaveDuty
         * @secure
         */
        userToAdminChatLeaveDutyCreate: (params = {}) => this.request({
            path: `/api/UserToAdminChatLeaveDuty`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChats
         * @name UserToAdminChatDetailsReadAdminDetail
         * @request GET:/api/UserToAdminChatDetailsReadAdmin/{userId}
         * @secure
         */
        userToAdminChatDetailsReadAdminDetail: (userId, params = {}) => this.request({
            path: `/api/UserToAdminChatDetailsReadAdmin/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChats
         * @name UserToAdminChatMessagesReadAdminList
         * @request GET:/api/UserToAdminChatMessagesReadAdmin
         * @secure
         */
        userToAdminChatMessagesReadAdminList: (query, params = {}) => this.request({
            path: `/api/UserToAdminChatMessagesReadAdmin`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChats
         * @name UserToAdminChatDetailsReadList
         * @request GET:/api/UserToAdminChatDetailsRead
         * @secure
         */
        userToAdminChatDetailsReadList: (params = {}) => this.request({
            path: `/api/UserToAdminChatDetailsRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChats
         * @name UserToAdminChatIsOnlineList
         * @request GET:/api/UserToAdminChatIsOnline
         * @secure
         */
        userToAdminChatIsOnlineList: (params = {}) => this.request({
            path: `/api/UserToAdminChatIsOnline`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChats
         * @name UserToAdminChatMessagesReadList
         * @request GET:/api/UserToAdminChatMessagesRead
         * @secure
         */
        userToAdminChatMessagesReadList: (query, params = {}) => this.request({
            path: `/api/UserToAdminChatMessagesRead`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChats
         * @name UserToAdminChatsCreateCreate
         * @request POST:/api/UserToAdminChatsCreate
         * @secure
         */
        userToAdminChatsCreateCreate: (params = {}) => this.request({
            path: `/api/UserToAdminChatsCreate`,
            method: "POST",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChats
         * @name UserToAdminChatsStatusReadList
         * @request GET:/api/UserToAdminChatsStatusRead
         * @secure
         */
        userToAdminChatsStatusReadList: (params = {}) => this.request({
            path: `/api/UserToAdminChatsStatusRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChatsRead
         * @name UserToAdminChatsReadCreate
         * @request POST:/api/UserToAdminChatsRead
         * @secure
         */
        userToAdminChatsReadCreate: (data, params = {}) => this.request({
            path: `/api/UserToAdminChatsRead`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChatsTableFiltersAdmin
         * @name UserToAdminChatsTableFiltersAdminList
         * @request GET:/api/UserToAdminChatsTableFiltersAdmin
         * @secure
         */
        userToAdminChatsTableFiltersAdminList: (params = {}) => this.request({
            path: `/api/UserToAdminChatsTableFiltersAdmin`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChatSubscribeToMessages
         * @name UserToAdminChatSubscribeToMessagesCreate
         * @request POST:/api/UserToAdminChatSubscribeToMessages
         * @secure
         */
        userToAdminChatSubscribeToMessagesCreate: (data, params = {}) => this.request({
            path: `/api/UserToAdminChatSubscribeToMessages`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChatTakeDuty
         * @name UserToAdminChatTakeDutyCreate
         * @request POST:/api/UserToAdminChatTakeDuty/{userId}
         * @secure
         */
        userToAdminChatTakeDutyCreate: (userId, params = {}) => this.request({
            path: `/api/UserToAdminChatTakeDuty/${userId}`,
            method: "POST",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToAdminChatUnsubscribeFromMessages
         * @name UserToAdminChatUnsubscribeFromMessagesCreate
         * @request POST:/api/UserToAdminChatUnsubscribeFromMessages
         * @secure
         */
        userToAdminChatUnsubscribeFromMessagesCreate: (data, params = {}) => this.request({
            path: `/api/UserToAdminChatUnsubscribeFromMessages`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name ReceivedUserToUserChatsRequestsReadList
         * @request GET:/api/ReceivedUserToUserChatsRequestsRead
         * @secure
         */
        receivedUserToUserChatsRequestsReadList: (params = {}) => this.request({
            path: `/api/ReceivedUserToUserChatsRequestsRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name RejectedUserToUserChatInitiatedRequestsReadList
         * @request GET:/api/RejectedUserToUserChatInitiatedRequestsRead
         * @secure
         */
        rejectedUserToUserChatInitiatedRequestsReadList: (params = {}) => this.request({
            path: `/api/RejectedUserToUserChatInitiatedRequestsRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name RejectedUserToUserChatRequestsReadList
         * @request GET:/api/RejectedUserToUserChatRequestsRead
         * @secure
         */
        rejectedUserToUserChatRequestsReadList: (params = {}) => this.request({
            path: `/api/RejectedUserToUserChatRequestsRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatMessagesDeleteDelete
         * @request DELETE:/api/UserToUserChatMessagesDelete/{messageId}
         * @secure
         */
        userToUserChatMessagesDeleteDelete: (messageId, params = {}) => this.request({
            path: `/api/UserToUserChatMessagesDelete/${messageId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatMessagesReadList
         * @request GET:/api/UserToUserChatMessagesRead
         * @secure
         */
        userToUserChatMessagesReadList: (query, params = {}) => this.request({
            path: `/api/UserToUserChatMessagesRead`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatRequestDetailsReadDetail
         * @request GET:/api/UserToUserChatRequestDetailsRead/{userId}
         * @secure
         */
        userToUserChatRequestDetailsReadDetail: (userId, params = {}) => this.request({
            path: `/api/UserToUserChatRequestDetailsRead/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatRequestsAcceptCreate
         * @request POST:/api/UserToUserChatRequestsAccept
         * @secure
         */
        userToUserChatRequestsAcceptCreate: (data, params = {}) => this.request({
            path: `/api/UserToUserChatRequestsAccept`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatRequestsCloseCreate
         * @request POST:/api/UserToUserChatRequestsClose
         * @secure
         */
        userToUserChatRequestsCloseCreate: (data, params = {}) => this.request({
            path: `/api/UserToUserChatRequestsClose`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatRequestsCreateCreate
         * @request POST:/api/UserToUserChatRequestsCreate
         * @secure
         */
        userToUserChatRequestsCreateCreate: (data, params = {}) => this.request({
            path: `/api/UserToUserChatRequestsCreate`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatRequestsHideRejectedCreate
         * @request POST:/api/UserToUserChatRequestsHideRejected
         * @secure
         */
        userToUserChatRequestsHideRejectedCreate: (data, params = {}) => this.request({
            path: `/api/UserToUserChatRequestsHideRejected`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatRequestsReadList
         * @request GET:/api/UserToUserChatRequestsRead
         * @secure
         */
        userToUserChatRequestsReadList: (params = {}) => this.request({
            path: `/api/UserToUserChatRequestsRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatRequestsRejectCancellationCreate
         * @request POST:/api/UserToUserChatRequestsRejectCancellation
         * @secure
         */
        userToUserChatRequestsRejectCancellationCreate: (data, params = {}) => this.request({
            path: `/api/UserToUserChatRequestsRejectCancellation`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatRequestsRejectCreate
         * @request POST:/api/UserToUserChatRequestsReject
         * @secure
         */
        userToUserChatRequestsRejectCreate: (data, params = {}) => this.request({
            path: `/api/UserToUserChatRequestsReject`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatsDeleteDelete
         * @request DELETE:/api/UserToUserChatsDelete/{chatId}
         * @secure
         */
        userToUserChatsDeleteDelete: (chatId, params = {}) => this.request({
            path: `/api/UserToUserChatsDelete/${chatId}`,
            method: "DELETE",
            secure: true,
            ...params,
        }),
        /**
         * No description
         *
         * @tags UserToUserChats
         * @name UserToUserChatsReadList
         * @request GET:/api/UserToUserChatsRead
         * @secure
         */
        userToUserChatsReadList: (params = {}) => this.request({
            path: `/api/UserToUserChatsRead`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
    };
    healthz = {
        /**
         * No description
         *
         * @tags Service
         * @name HealthzList
         * @request GET:/healthz
         * @secure
         */
        healthzList: (params = {}) => this.request({
            path: `/healthz`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
    };
    animals = {
        /**
         * No description
         *
         * @tags SharedAnimalProfile
         * @name SharedProfileDetail
         * @request GET:/animals/{animalId}/shared-profile
         * @secure
         */
        sharedProfileDetail: (animalId, params = {}) => this.request({
            path: `/animals/${animalId}/shared-profile`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        }),
    };
}
