import React, { useState } from "react";
import NavDogLogoIcon from "../../../assets/icons/NavDogLogoIcon.png";
import { cn } from "@edx/react-common";
import { Link, useRouter } from "@tanstack/react-router";
import {
  NavAlertIcon,
  NavBackIcon,
  NavBuildDogIcon,
  NavDashboardIcon,
  NavFindDogIcon,
  NavHelpIcon,
  NavHubIcon,
  NavLogOutIcon,
  NavProfileIcon,
} from "../../Icons/NavIcons";
import { XMarkIcon } from "@heroicons/react/24/outline";

export interface AppLayoutLeftMenuNavigationProps {}

interface AppLayoutLeftMenuProps extends AppLayoutLeftMenuNavigationProps {
  onClose: () => void;
  isMobile: boolean;
}

export function AppLayoutLeftMenu({
  onClose,
  isMobile,
}: AppLayoutLeftMenuProps) {
  const { history } = useRouter();
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickBack = history.length ? () => history.back() : undefined;

  const handleMouseEnter = () => !isMobile && setIsExpanded(true);
  const handleMouseLeave = () => !isMobile && setIsExpanded(false);

  const menuWidth = isMobile
    ? "w-full"
    : isExpanded
      ? "w-[228px]"
      : "w-[126px]";

  return (
    <div
      className={`h-full shadow-slate-500 shadow-lg flex flex-col gap-6 overflow-y-auto transition-all duration-300 bg-white ${menuWidth}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex flex-col gap-4 p-1 py-2 m-2 justify-center items-center rounded ">
        <img
          src={NavDogLogoIcon}
          className="w-[64px] h-[43px]"
          alt="dog logo"
        />
      </div>

      {isMobile && (
        <button className="absolute top-4 right-4 p-2" onClick={onClose}>
          <XMarkIcon className="size-7" />
        </button>
      )}

      {onClickBack && (
        <div className="px-[38px]">
          <div
            className="cursor-pointer flex items-center gap-[16px] text-[15px] text-[#244653] leading-[18px] font-bold border-b-2 border-gray-500 pb-5 hover:text-brand hover:font-bold"
            onClick={onClickBack}
          >
            <div className="mx-auto">
              <NavBackIcon className="w-7 h-7" />
            </div>
            {(isExpanded || isMobile) && (
              <div className="truncate flex-1 uppercase">Back</div>
            )}
          </div>
        </div>
      )}

      <div className="flex-1 flex flex-col gap-12 px-[38px] pt-6 text-[15px] leading-[18px] uppercase">
        {[
          {
            to: "/app/dashboard",
            icon: <NavDashboardIcon className="w-6 h-4" />,
            title: "Dashboard",
          },
          {
            to: "/app/animals/dogs",
            icon: <NavHubIcon className="w-6 h-6" />,
            title: "Hub",
          },
          // {
          //   to: "/app/dna",
          //   icon: <NavBuildDogIcon className="w-6 h-6" />,
          //   title: "Build a Dog",
          // },
          // {
          //   to: "/app/search",
          //   icon: <NavFindDogIcon className="w-6 h-6" />,
          //   title: "Find a Dog",
          // },
          // {
          //   to: "/app/account/notifications",
          //   icon: <NavAlertIcon className="w-6 h-6" />,
          //   title: "Alerts",
          // },
          {
            to: "/app/account/profile",
            icon: <NavProfileIcon className="w-6 h-6" />,
            title: "Profile",
          },
          // {
          //   to: "/app/help",
          //   icon: <NavHelpIcon className="w-6 h-6" />,
          //   title: "Help",
          // },
          {
            to: "/auth/logout",
            icon: <NavLogOutIcon className="w-6 h-6" />,
            title: "Log Out",
          },
        ].map((item, index) => (
          <Link
            key={index}
            activeProps={{
              className: "!text-brand !font-bold",
            }}
            className={cn(
              "text-accent font-semibold cursor-pointer flex items-center gap-5 min-w-0 hover:font-bold w-full",
            )}
            to={item.to}
            onClick={isMobile ? onClose : undefined}
          >
            <div className="mx-auto">{item.icon}</div>
            {(isExpanded || isMobile) && (
              <div className="truncate flex-1">{item.title}</div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
}
