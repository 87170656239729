import { createFileRoute, Outlet } from '@tanstack/react-router'
import { PublicViewProvider } from '../../contexts/PublicViewContext'
import { useAuthState } from '../../features/auth/state/auth-state'
import { edxApi } from '../../services/edx-api'

// Your wrapper component
function PublicRouteWrapper() {
  const authState = useAuthState()
  // We populate the api auth header in case this is a logged in user
  edxApi.setAuthHeader({ token: authState.authToken })

  return (
    <PublicViewProvider isPublicView>
      <Outlet />
    </PublicViewProvider>
  )
}

export const Route = createFileRoute('/public')({
  component: PublicRouteWrapper,
})
