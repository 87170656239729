import React, { PropsWithChildren, useState } from "react";
import { useElementDimensions } from "@edx/react-common";
import { AppLayoutLeftMenu } from "./components/AppLayoutLeftMenu";
import NavDogLogoIcon from "../../assets/icons/NavDogLogoIcon.png";
import { MenuCollapsedIcon } from "../Icons/Icons";

interface AppLayoutWrapperProps extends PropsWithChildren {}

export function AppLayoutWrapper({ children }: AppLayoutWrapperProps) {
  const { ref } = useElementDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="h-screen flex flex-col w-screen">
      {/* Mobile Header */}
      <header className="md:hidden flex items-center justify-between px-4 h-[56px] bg-white shadow-md relative">
        <button className="p-2" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <MenuCollapsedIcon className="w-9 h-5" />
        </button>
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <img src={NavDogLogoIcon} alt="Logo" className="w-[64px] h-[43px]" />
        </div>
      </header>

      <div className="flex flex-col flex-1 overflow-hidden">
        {isMenuOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-30"
            onClick={() => setIsMenuOpen(false)}
          />
        )}

        <div
          className={`fixed top-0 left-0 h-screen w-full z-40 transition-transform duration-300 ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } md:w-auto md:translate-x-0 md:z-10`}
          ref={ref as any}
        >
          <AppLayoutLeftMenu
            onClose={() => setIsMenuOpen(false)}
            isMobile={isMenuOpen}
          />
        </div>

        <div className="flex-1 overflow-auto bg-surface md:pl-[126px] relative">
          {children}
        </div>
      </div>
    </div>
  );
}
