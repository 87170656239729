import React from "react";
import {
  createRootRoute,
  Outlet,
} from "@tanstack/react-router";
import { AppLayoutWrapper } from "../../components/Layouts/AppLayoutWrapper";
import {Auth} from "../../features/auth/shared";

export const Route = createRootRoute({
  component: () => {
    return (
      <Auth.AuthWrapper>
        <AppLayoutWrapper>
          <Outlet />
        </AppLayoutWrapper>
      </Auth.AuthWrapper>
    );
  },
});
