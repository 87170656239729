import { edxApi } from "../../../services/edx-api";
import { EdxApiTypes } from "@edx/api";
import dayjs from "dayjs";

export const AuthApi = {
  forgotPassword: async (params: { email: string, source: EdxApiTypes.SourceApplication }) => {
    return edxApi.api
      .forgotPasswordCreate({
        email: params.email,
        sourceApplication: params.source,
      })
      .then(({ data }) => data);
  },
  getCurrentUser: async (params: { authToken: string }) => {
    return edxApi.api
      .usersCurrentList({
        headers: {
          Authorization: `Bearer ${params.authToken}`,
        },
      })
      .then(({ data }) => data);
  },
  login: async (params: { email: string; password: string }) => {
    return edxApi.api
      .authenticationCreate({
        userName: params.email,
        password: params.password,
      })
      .then(({ data }) => data);
  },
  logout: async (params: { authToken: string }) => {
    return undefined;
  },
  register: async (params: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    salutation: EdxApiTypes.Salutation;
  }) => {
    return edxApi.api
      .registrationCreate({
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
        mobilePhone: params.mobilePhone,
        password: params.password,
        salutation: params.salutation,
        passwordConfirm: params.password,
        source: EdxApiTypes.SourceApplication.DGX,
      })
      .then(({ data }) => data);
  },
  /**
   * Resend the account verification code/link to the user's email.
   */
  resendVerificationCode: async (params: { email: string }) => {
    return edxApi.api
      .mailConfirmationPartialUpdate({
        email: params.email,
      })
      .then(({ data }) => data);
  },
  setNewPassword: async (params: {
    email: string;
    code: string;
    password: string;
  }) => {
    return edxApi.api
      .resetPasswordCreate({
        code: params.code,
        email: params.email,
        password: params.password,
        passwordConfirm: params.password,
      })
      .then(({ data }) => data);
  },
  /**
   * Mark the user account/email as "verified" the account using the code sent to the user's email.
   */
  verifyAccount: async (params: { code: string; userId: string }) => {
    return edxApi.api.confirmEmailPartialUpdate({
      code: params.code,
      userId: params.userId,
    });
  },
  /**
   * Verifies the auth token and refresh token. If the auth token is expired, a new auth token is generated using
   * the refresh token.
   */
  verifyAndRefreshAuthToken: async (params: {
    authToken: string;
    authTokenExpire: string;
    refreshToken: string;
  }): Promise<{
    newAuthToken?: EdxApiTypes.AuthenticationResponse;
    status: "valid" | "expired";
  }> => {
    if (dayjs(params.authTokenExpire).isBefore(dayjs())) {
      return {
        status: "expired",
      };
    }

    // if expire is more than 10min in the future, return valid, no need to refresh
    if (dayjs(params.authTokenExpire).diff(dayjs(), "minute") > 10) {
      return {
        status: "valid",
      };
    }

    return edxApi.api
      .refreshTokenCreate({
        tokenRefresh: params.refreshToken,
      })
      .then(({ data }) => ({
        newAuthToken: data,
        status: "valid",
      }));
  },
};
