import React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { HorseProfileScreen } from '../../../../features/animals/screens/horses/HorseProfileScreen'

export const Route = createFileRoute('/app/animals/horses/profile/$id')({
  component: () => {
    const { id } = Route.useParams()

    return <HorseProfileScreen animalId={+id} />
  },
})
