import React from "react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { VerifyAccountScreen } from "../../features/auth/screens/VerifyAccountScreen";

type VerifyAccountSearch = {
  email: string;
};

export const Route = createFileRoute("/auth/verify-account")({
  component: () => {
    const navigate = useNavigate();
    const search = Route.useSearch();

    if (!search.email) {
      throw navigate({ to: "/auth" });
    }

    return <VerifyAccountScreen email={search.email} />;
  },
  validateSearch: (search: VerifyAccountSearch) => {
    return {
      email: search.email ?? "",
    };
  },
});
