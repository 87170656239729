import { Api } from "./services/api";
import * as EdxApiTypes from "./services/api";
import HORSE_BREED_LIST from "./fixtures/breed-list.json";
import HORSE_COAT_COLOR_LIST from "./fixtures/color-list.json";
import HORSE_DISCIPLINE_LIST from "./fixtures/discipline-list.json";
import HORSE_MARKING_LIST from "./fixtures/marking-list.json";
export const API_FIXTURES = {
    HORSE_BREED_LIST,
    HORSE_COAT_COLOR_LIST,
    HORSE_DISCIPLINE_LIST,
    HORSE_MARKING_LIST,
};
function createApiInstance(params) {
    const { api, instance, animals, healthz } = new Api(params);
    // this is necessary because the API is incorrectly documented for swagger and returns "data.data"
    instance.interceptors.response.use((res) => {
        return {
            ...res,
            // unwrap data.data
            data: res.data.data || res.data,
            pagination: res.data.pagination,
        };
    }, (error) => {
        return Promise.reject(error);
    });
    return {
        animals,
        healthz,
        api,
        instance,
    };
}
/**
 * The EdX API class
 */
export class EdxApi {
    animals;
    api;
    healthz;
    instance;
    constructor(params) {
        const { api, instance, animals, healthz } = createApiInstance(params);
        this.animals = animals;
        this.api = api;
        this.healthz = healthz;
        this.instance = instance;
    }
    /**
     * Set the Authorization header
     *
     * @param token
     */
    setAuthHeader(params) {
        this.instance.defaults.headers.common["Authorization"] =
            `Bearer ${params.token}`;
    }
    /**
     * Set a header for all requests to use
     *
     * @param params
     */
    setHeader(params) {
        this.instance.defaults.headers.common[params.key] = params.value;
    }
}
// Export the API Typings
export { EdxApiTypes };
