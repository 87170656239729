import React from "react";
import EtalonLogo from "../../../assets/etalon_logo_universal.png";

interface AuthLayoutWrapperProps extends React.PropsWithChildren<{}> {}

export function AuthLayoutWrapper({ children }: AuthLayoutWrapperProps) {
  return (
    <div className="w-full lg:grid lg:grid-cols-2 h-screen">
      <div className={"flex items-center w-full h-full justify-center p-8"}>
        <div className="max-w-[400px] w-full">{children}</div>
      </div>

      <div className={"hidden lg:block bg-edxred-500"}>
        <div className="flex items-center justify-center h-full">
          <img
            className="max-w-[500px] min-w-[200px]"
            src={EtalonLogo}
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
}
