export const Environment = {

    BASE_API_URL: import.meta.env.VITE_APP_BASE_API_URL ?? 'https://api.test.etalondiagnostics.com',
    BASE_WEB_URL: import.meta.env.VITE_APP_BASE_WEB_URL ?? 'https://localhost:3000',
    COOKIE_DOMAINS: (import.meta.env.VITE_COOKIE_DOMAINS?.split(',') ?? ['localhost']) as string[],
    FULLSTORY_ORG_ID: import.meta.env.VITE_FULLSTORY_ORG_ID ?? '17HZ9P',
    STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ?? 'https://a75d3e5fb9b9b75e6294ad7e09b06952@o4506792561213440.ingest.us.sentry.io/4508814279573505',
    RESULTS_PREFIX_URL: import.meta.env.VITE_RESULTS_PREFIX_URL ?? '',
    IMAGES_BASE_URL: import.meta.env.VITE_APP_IMAGES_BASE_URL ?? 'https://assets.test.etalondiagnostics.com',
    MAPBOX_TOKEN: 'pk.eyJ1IjoiY3JvYmluc29uZXRhbG9uZHgiLCJhIjoiY203eHczZWk5MDNieDJpcHRia2VzZ2Z5MiJ9.Tn9OYcStXNFslcWXlC2sSQ'
}

// export const Environment = {
//
//     BASE_API_URL: import.meta.env.VITE_APP_BASE_API_URL ?? 'http://localhost:3000',
//     BASE_WEB_URL: import.meta.env.VITE_APP_BASE_WEB_URL ?? 'https://localhost:3000',
//     COOKIE_DOMAINS: (import.meta.env.VITE_COOKIE_DOMAINS?.split(',') ?? ['localhost']) as string[],
//     FULLSTORY_ORG_ID: import.meta.env.VITE_FULLSTORY_ORG_ID ?? '17HZ9P',
//     STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ?? 'https://a75d3e5fb9b9b75e6294ad7e09b06952@o4506792561213440.ingest.us.sentry.io/4508814279573505',
//     RESULTS_PREFIX_URL: import.meta.env.VITE_RESULTS_PREFIX_URL ?? '',
//     IMAGES_BASE_URL: import.meta.env.VITE_APP_IMAGES_BASE_URL ?? 'https://assets.accounts.etalondiagnostics.com',
// }
