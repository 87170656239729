import { create } from "zustand";
import { AuthPersistenceService } from "../services/auth-persistence";
import { deepEqual } from "@tanstack/react-router";
import { EdxApiTypes } from "@edx/api";
import {queryClient} from "../../../entry";

export type AuthStateUser = {
  id: number;
  salutation?: EdxApiTypes.Salutation;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  mobile?: string;
  avatar?: EdxApiTypes.ImageResponse;
  userAddress?: EdxApiTypes.AddressResponse;
  isAgreement: boolean;
  location?: EdxApiTypes.GeoPointResponse;
  isVip?: boolean;
};

export type AuthState = {
  __reset: () => void;
  authToken: string;
  authTokenExpiresAt: string;
  /** If the User has previously authenticated */
  hasAuthenticated: boolean;
  email: string;
  reconcileAuthStateWithPersistenceService: () => { userHasChanged: boolean };
  refreshToken: string;
  setAuth: (params: {
    authToken: string;
    authTokenExpiresAt: string;
    email: string;
    refreshToken: string;
    user: AuthStateUser;
  }) => void;
  setAuthTokenAfterRefresh: (params: {
    authToken: string;
    authTokenExpiresAt: string;
    refreshToken: string;
  }) => void;
  setHasAuthenticated: (hasAuthenticated: boolean) => void;
  setUser: (user: AuthStateUser) => void;
  setUserIsAgreement: (userIsAgreement: boolean) => void;
  user: AuthStateUser;
};

const DEFAULT_AUTH_STATE = {
  authToken: "",
  authTokenExpiresAt: "",
  hasAuthenticated: false,
  email: "",
  refreshToken: "",
  user: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    isAgreement: false,
  },
};

export const useAuthState = create<AuthState>((set) => ({
  __reset: () => {
    queryClient.clear();
    set(structuredClone(DEFAULT_AUTH_STATE));
  },

  ...((AuthPersistenceService.get(
    "edxv2_auth_state",
  ) as typeof DEFAULT_AUTH_STATE) || structuredClone(DEFAULT_AUTH_STATE)),

  hasAuthenticated:
    (AuthPersistenceService.get("has_authenticated") as boolean) || false,

  /**
   * This action will get the persisted auth state and check it against the current auth state.
   * The reason this is necessary is because the user could have logged in on another tab or device.
   */
  reconcileAuthStateWithPersistenceService: () => {
    const persistedAuthState = AuthPersistenceService.get(
      "edxv2_auth_state",
    ) as typeof DEFAULT_AUTH_STATE;

    // compare persistedAuthState with current state
    const currentState = useAuthState.getState();

    if (!deepEqual(persistedAuthState.authToken, currentState.authToken)) {
      if (persistedAuthState.user.id === DEFAULT_AUTH_STATE.user.id) {
        // this is the case if the user logged out in another tab
        currentState.__reset();
        return { userHasChanged: false };
      }

      currentState.setAuth({
        authToken: persistedAuthState.authToken,
        authTokenExpiresAt: persistedAuthState.authTokenExpiresAt,
        email: persistedAuthState.email,
        refreshToken: persistedAuthState.refreshToken,
        user: persistedAuthState.user,
      });

      if (persistedAuthState.user.id !== currentState.user.id) {
        return { userHasChanged: true };
      }

      return { userHasChanged: false };
    }

    return { userHasChanged: false };
  },

  setAuth: ({ authToken, authTokenExpiresAt, email, refreshToken, user }) => {
    if (authToken) set({ hasAuthenticated: true });

    return set({
      authToken,
      authTokenExpiresAt,
      email,
      refreshToken,
      user,
    });
  },

  setAuthTokenAfterRefresh: ({ authToken, authTokenExpiresAt, refreshToken }) =>
    set({ authToken, authTokenExpiresAt, refreshToken }),

  setHasAuthenticated: (hasAuthenticated) => set({ hasAuthenticated }),

  setUser: (user: AuthStateUser) => set({ user }),

  setUserIsAgreement: (userIsAgreement) =>
    set({
      user: {
        ...useAuthState.getState().user,
        isAgreement: userIsAgreement,
      },
    }),
}));

useAuthState.subscribe((state) => {
  AuthPersistenceService.set("edxv2_auth_state", state);
  AuthPersistenceService.set("has_authenticated", state.hasAuthenticated);
});

export function AuthState() {
  return useAuthState.getState();
}
