import React from "react";
import { createFileRoute } from "@tanstack/react-router";
import { TextSearch } from "../../../../components/Form/TextSearch";
import { Layout } from "../../../../components/Layouts/Common";

export const Route = createFileRoute("/app/animals/horses/bah-result")({
  component: BahResult,
});

function BahResult() {
  return (
    <>
      <Layout.Section>
        <Layout.SectionHeading>
          <Layout.SectionTitle>Build-A-Horse Results</Layout.SectionTitle>

          <div className="flex-row-centered space-x-4">
            <TextSearch className="w-56" placeholder="Search animals..." />
          </div>
        </Layout.SectionHeading>
      </Layout.Section>
    </>
  );
}
