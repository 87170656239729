import { useMemo } from "react";
import { AuthStateUser, useAuthState } from "../state/auth-state";

export function useCurrentUser(): AuthStateUser & {
  fullName: string;
  initials: string;
} {
  const { user } = useAuthState();

  const fullName = useMemo(() => {
    return [
      user.salutation === "Unknown" ? "" : user.salutation,
      user.firstName,
      user.lastName,
    ]
      .filter(Boolean)
      .join(" ");
  }, [user.salutation, user.firstName, user.lastName]);

  const initials = useMemo(() => {
    return (
      `${user.firstName?.[0] || ""}${user.lastName?.[0] || ""}`.toUpperCase() ||
      "--"
    );
  }, [user.firstName, user.lastName]);

  return {
    ...user,
    fullName,
    initials,
  };
}
