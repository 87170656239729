import React from "react";
import { createFileRoute } from "@tanstack/react-router";
import { VerifyAccountWithCodeScreen } from "../../features/auth/screens/VerifyAccountWithCodeScreen";

type VerifyAccountSearch = {
  code: string;
  email: string;
  userId: string;
};

/**
 * This route is used by the link that is emailed to a user to verify their account.
 * It must contain the url search params `code`, `email`, and `userId`.
 */
export const Route = createFileRoute("/auth/verify-account-with-code")({
  component: () => {
    const search = Route.useSearch();

    return (
      <VerifyAccountWithCodeScreen
        code={search.code}
        email={search.email}
        userId={search.userId}
      />
    );
  },

  validateSearch: (search: VerifyAccountSearch) => {
    return {
      code: search.code ?? "",
      email: search.email ?? "",
      userId: search.userId ?? "",
    };
  },
});
