import React, { useEffect } from "react";
import logo from "../../../assets/etalon_logo_universal.png";
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Form,
  toast,
  LoadingSpinner,
} from "@edx/react-common";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import {AuthStateUser, useAuthState} from "../state/auth-state";
import { AuthApi } from "../services/auth-api";

const formSchema = z.object({
  password: z.string().min(6, {
    message: "Password must be at least 6 characters long",
  }),
  username: z.string().email(),
});

interface LoginFormProps {
  onAuthenticated: () => void;
  onClickForgotPassword: () => void;
  onClickRegister: () => void;
  onEmailVerificationRequired: (email: string) => void;
}

export const LoginForm = ({
  onAuthenticated,
  onClickForgotPassword,
  onClickRegister,
  onEmailVerificationRequired,
}: LoginFormProps) => {
  const {
    authToken,
    authTokenExpiresAt,
    email,
    hasAuthenticated,
    refreshToken,
    setAuth,
    setAuthTokenAfterRefresh,
    __reset,
  } = useAuthState();
  const [checkingExistingAuthToken, setCheckingExistingAuthToken] =
    useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      username: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      const data = await AuthApi.login({
        email: values.username,
        password: values.password,
      });

      if (!data.tokenAccess || !data.tokenRefresh || !data.expirationAccess) {
        throw new Error("Invalid response from server");
      }

      const currentUserRepsonse = await AuthApi.getCurrentUser({
        authToken: data.tokenAccess,
      });

      if (!currentUserRepsonse.id) {
        throw new Error(
          "Invalid Current User response from server - no user.id for authenticated user",
        );
      }

      setAuth({
        authToken: data.tokenAccess,
        authTokenExpiresAt: new Date(data.expirationAccess).toISOString(),
        email: values.username,
        refreshToken: data.tokenRefresh,
        user: currentUserRepsonse as AuthStateUser,
      });

      onAuthenticated();
    } catch (e: unknown) {
      console.error(e);

      toast({
        title: "Login Failed",
        description: "Check your email and password and try again.",
        duration: 3000,
      });
    }
  }

  const verifyExistingAuthToken = async () => {
    if (!authToken && !authTokenExpiresAt && !refreshToken) {
      return;
    }

    setCheckingExistingAuthToken(true);

    try {
      const result = await AuthApi.verifyAndRefreshAuthToken({
        authToken,
        authTokenExpire: authTokenExpiresAt,
        refreshToken,
      });

      if (result.status === "valid") {
        if (
          result.newAuthToken?.tokenAccess &&
          result.newAuthToken?.tokenRefresh &&
          result.newAuthToken?.expirationAccess
        ) {
          setAuthTokenAfterRefresh({
            authToken: result.newAuthToken.tokenAccess,
            authTokenExpiresAt: result.newAuthToken.expirationAccess,
            refreshToken: result.newAuthToken.tokenRefresh,
          });
        }

        onAuthenticated();
      } else {
        __reset();
      }
    } catch (e: unknown) {
      console.error(e);

      /**
       * @note: the server will respond with an error if the email is not confirmed when attempting to refresh an
       * authToken with a refresh token
       */
      if (
        // @ts-expect-error
        e?.response?.data?.errors?.[0]?.errorCode === "EmailNotConfirmed"
      ) {
        toast({
          title: "Email not confirmed",
          description: "Please confirm your email before continuing.",
          duration: 3000,
        });

        AuthApi.resendVerificationCode({ email }).catch(() => {
          console.warn("Failed to resend verification code");
        });

        onEmailVerificationRequired(email);
      }

      __reset();
    } finally {
      setCheckingExistingAuthToken(false);
    }
  };

  useEffect(() => {
    // on mount, if we have an auth token, verify it
    if (authToken) {
      verifyExistingAuthToken();
    }
  }, []);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card className="relative overflow-hidden">
          {(form.formState.isSubmitting || checkingExistingAuthToken) && (
            <LoadingSpinner overlay />
          )}
          <CardHeader className="space-y-6 text-center">
            <div className="flex justify-center w-full">
              <div className="lg:hidden max-w-[200px]">
                <img src={logo} alt="Etalon" />
              </div>
            </div>
            <CardTitle className="text-3xl font-bold">
              {hasAuthenticated ? "Welcome back" : "Welcome"}
            </CardTitle>
            <CardDescription>
              Enter your email and password to access your account.
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="me@example.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <div className="flex justify-between w-full">
                      <span>Password</span>
                      <span
                        className="text-xs font-semibold text-gray-500 hover:underline"
                        onClick={onClickForgotPassword}
                      >
                        Forgot password?
                      </span>
                    </div>
                  </FormLabel>
                  <div className="relative">
                    <FormControl>
                      <Input
                        type={showPassword ? "text" : "password"}
                        {...field}
                      />
                    </FormControl>

                    {showPassword ? (
                      <FaEyeSlash
                        className="absolute right-2 top-2.5 cursor-pointer"
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <FaEye
                        className="absolute right-2 top-2.5 cursor-pointer"
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter>
            <Button
              disabled={form.formState.isSubmitting}
              type="submit"
              className={"w-full"}
            >
              Sign in
            </Button>
          </CardFooter>
        </Card>
        <div className="mt-4 text-center text-sm">
          Don&apos;t have an account?{" "}
          <span
            className="font-semibold text-gray-900 hover:underline cursor-pointer"
            onClick={onClickRegister}
          >
            Create New Account
          </span>
        </div>
      </form>
    </Form>
  );
};

const a = {
  authToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiY3JvYmluc29uQGV0YWxvbmR4LmNvbSIsIlVzZXJJZCI6IjYyMDI0IiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiVXNlciIsIm5iZiI6MTczNDAzMzIzMSwiZXhwIjoxNzM0MDM4NjMxLCJpc3MiOiJodHRwczovL2FwaS50ZXN0LmV0YWxvbmRpYWdub3N0aWNzLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXBpLnRlc3QuZXRhbG9uZGlhZ25vc3RpY3MuY29tIn0.X_656-zIFmlLjHN9PjMjABK4L_67fA0hdiRNRPHP4TA",
  authTokenExpiresAt: "2024-12-12T20:00:51.000Z",
  hasAuthenticated: true,
  email: "crobinson@etalondx.com",
  refreshToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiY3JvYmluc29uQGV0YWxvbmR4LmNvbSIsIlVzZXJJZCI6IjYyMDI0IiwibmJmIjoxNzM0MDMzMjMxLCJleHAiOjE3MzQwNDQwMzEsImlzcyI6Imh0dHBzOi8vYXBpLnRlc3QuZXRhbG9uZGlhZ25vc3RpY3MuY29tIiwiYXVkIjoiaHR0cHM6Ly9hcGkudGVzdC5ldGFsb25kaWFnbm9zdGljcy5jb20ifQ.NbJVi4HlJ9q30BP4Ap8YAi4koEL9v63BJhpyI9KGabA",
  userId: 62024,
  userIsAgreement: true,
};
