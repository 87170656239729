import React, { useEffect, useLayoutEffect, useState } from "react";
import { useAuthState } from "../state/auth-state";
import { LoginForm } from "./LoginForm";
import { useNavigate } from "@tanstack/react-router";
import { AuthLayoutWrapper } from "./AuthLayoutWrapper";
import { AuthApi } from "../services/auth-api";
import { LoadingSpinner, toast } from "@edx/react-common";
import { edxApi } from "../../../services/edx-api";
import { useDocumentVisibility, usePrevious } from "ahooks";
import { TosAgreementDialog } from "./TosAgreementDialog";

interface AuthWrapperProps {
  children: React.ReactNode;
}

/**
 * The primary purpose of the AuthWrapper component is to allow the browser to preserve the URL state while allowing the user
 * to login and continue their session.
 *
 * The secondary purpose of the AuthWrapper component is to verify the user's auth token and refresh when necessary.
 */
export function AuthWrapper({ children }: AuthWrapperProps) {
  const authState = useAuthState();
  const navigate = useNavigate();
  const [checkingExistingAuthToken, setCheckingExistingAuthToken] =
    useState(false);

  edxApi.setAuthHeader({ token: authState.authToken });

  const verifyExistingAuthToken = async (opts?: {
    // Verify in the background without setting the loading state. This is used when getting a refresh token in the background
    // when the user has a long running browser session
    verifyInBackground?: boolean;
  }) => {
    if (
      !authState.authToken &&
      !authState.authTokenExpiresAt &&
      !authState.refreshToken
    ) {
      authState.__reset();
      return;
    }

    if (!opts?.verifyInBackground) {
      // dont set the loading state if the call is to verify in the "background" to not disrupt the user's view
      setCheckingExistingAuthToken(true);
    }

    try {
      const result = await AuthApi.verifyAndRefreshAuthToken({
        authToken: authState.authToken,
        authTokenExpire: authState.authTokenExpiresAt,
        refreshToken: authState.refreshToken,
      });

      if (result.status === "valid") {
        if (
          result.newAuthToken?.tokenAccess &&
          result.newAuthToken?.tokenRefresh &&
          result.newAuthToken?.expirationAccess
        ) {
          authState.setAuthTokenAfterRefresh({
            authToken: result.newAuthToken.tokenAccess,
            authTokenExpiresAt: result.newAuthToken.expirationAccess,
            refreshToken: result.newAuthToken.tokenRefresh,
          });
        }
      }
    } catch (e: unknown) {
      console.error(e);

      /**
       * @note: the server will respond with an error if the email is not confirmed when attempting to refresh an
       * authToken with a refresh token
       */
      if (
        // @ts-expect-error
        e?.response?.data?.errors?.[0]?.errorCode === "EmailNotConfirmed"
      ) {
        toast({
          title: "Email not confirmed",
          description: "Please confirm your email before continuing.",
          duration: 3000,
        });

        AuthApi.resendVerificationCode({ email: authState.email }).catch(() => {
          console.warn("Failed to resend verification code");
        });

        navigate({
          to: "/auth/verify-account",
          search: { email: authState.email },
        });
      }
    } finally {
      setCheckingExistingAuthToken(false);
    }
  };

  const documentVisibility = useDocumentVisibility();
  const previousDocumentVisibility = usePrevious(documentVisibility);

  useEffect(() => {
    // we need to call reconcileAuthStateWithPersistenceService when the document is visible again
    // because the user could have logged in on another tab as a different user
    if (
      documentVisibility === "visible" &&
      previousDocumentVisibility === "hidden"
    ) {
      const { userHasChanged } =
        authState.reconcileAuthStateWithPersistenceService();

      if (userHasChanged) {
        toast({
          title: "Session expired",
          description: "A different user was logged in on another tab.",
          duration: 3000,
        });

        navigate({ to: "/" });
      }
    }
  }, [documentVisibility]);

  useLayoutEffect(() => {
    if (authState.authToken) {
      verifyExistingAuthToken();
    }

    const handlerId = edxApi.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          toast({
            title: "Session expired",
            description: "Please log in again.",
            duration: 3000,
          });

          authState.__reset();
        }

        return Promise.reject(error);
      },
    );

    return () => {
      // remove the interceptor when the component is unmounted (in dev env useEffect mounts twice by React intentionally)
      edxApi.instance.interceptors.response.eject(handlerId);
    };
  }, []);

  useEffect(() => {
    if (authState.authToken) {
      // Check for a new token every 10 minutes
      const i = setInterval(() => {
        verifyExistingAuthToken({ verifyInBackground: true });
      }, 60_000 * 10);

      return () => clearInterval(i);
    }
  }, [authState.authToken]);

  if (!authState.authToken) {
    return (
      <AuthLayoutWrapper>
        <LoginForm
          onAuthenticated={() => null}
          onClickForgotPassword={() => {
            navigate({ to: "/auth/forgot-password" });
          }}
          onClickRegister={() => {
            navigate({ to: "/auth/register" });
          }}
          onEmailVerificationRequired={() =>
            navigate({
              to: "/auth/verify-account",
              search: { email: authState.email },
            })
          }
        />
      </AuthLayoutWrapper>
    );
  }

  if (checkingExistingAuthToken) {
    return (
      <div className="w-screen h-screen flex flex-1 flex-col items-center justify-center">
        <LoadingSpinner size={50} />
      </div>
    );
  }

  if (!authState.user.isAgreement) {
    return (
      <>
        <TosAgreementDialog
          onClickAcceptAgreement={({ name }) => {
            edxApi.api
              .usersGeneralAgreementUpdateCreate({
                isAgree: true,
                sign: name,
              })
              .catch(() => {
                // todo: log to sentry
                console.warn("Failed to update user agreement");
              });

            authState.setUserIsAgreement(true);
          }}
          onClickLogout={authState.__reset}
        />
        {children}
      </>
    );
  }

  return children;
}
