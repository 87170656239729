import * as React from "react";
import ReactDOM from "react-dom/client";
import {
  ErrorComponent,
  RouterProvider,
  createRouter,
} from "@tanstack/react-router";
import { LoadingSpinner } from "@edx/react-common";
import { routeTree } from "./routeTree.gen";
import "./styles/index.css";
import {
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from "@tanstack/react-query";
import { Button, Toaster } from "@edx/react-common";
import { ErrorBoundary } from "react-error-boundary";
import { init as initFullStory } from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import { Environment } from "../config/Environment";

const router = createRouter({
  routeTree,
  defaultPendingComponent: () => <LoadingSpinner overlay size={52} />,
  defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
  defaultPreload: "intent",
  scrollRestoration: true,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

if (import.meta.env.MODE !== "development") {
  Sentry.init({
    dsn: "https://a75d3e5fb9b9b75e6294ad7e09b06952@o4506792561213440.ingest.us.sentry.io/4508814279573505",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  initFullStory({
    orgId: Environment.FULLSTORY_ORG_ID || "0000",
    cookieDomain: window.location.hostname,
  });
}

export const queryClient = new QueryClient({});

export function App() {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={({ resetErrorBoundary }) => (
        <div>
          There was an error!
          <Button onClick={() => resetErrorBoundary()}>Try again</Button>
        </div>
      )}
    >
      <QueryClientProvider client={queryClient}>
        <RouterProvider
          router={router}
          defaultPreload="intent"
          defaultPendingMs={0}
          defaultPendingMinMs={0}
        />

        <Toaster />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
