import React from "react";
import { createFileRoute } from "@tanstack/react-router";
import {
  SetNewPasswordScreen,
  SetNewPasswordScreenProps,
} from "../../features/auth/screens/SetNewPasswordScreen";

export const Route = createFileRoute("/auth/set-new-password")({
  component: () => {
    const search = Route.useSearch();

    return <SetNewPasswordScreen code={search.code} email={search.email} />;
  },

  validateSearch: (search: SetNewPasswordScreenProps) => {
    return {
      code: search.code ?? "",
      email: search.email ?? "",
    };
  },
});
