import { LoadingSpinner } from '@edx/react-common'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { useMemo } from 'react'
import { AnimalProfileEmptyState } from '../../../../../features/animals/components/AnimalProfileEmptyState'
import { AnimalProfileProvider } from '../../../../../features/animals/contexts/DogProfileContext'
import { useAnimalProfile } from '../../../../../features/animals/hooks/use-animal-profile'
import { useDogDiagnosticsReport } from '../../../../../features/animals/hooks/use-dog-diagnostics-report'
import { useDogMedia } from '../../../../../features/animals/hooks/use-dog-media'
import { useDogOrderInfo } from '../../../../../features/animals/hooks/use-dog-order-info'
import {
  DOG_PROFILE_SCREEN_TABS,
  DogProfileScreen,
} from '../../../../../features/animals/screens/dogs/DogProfileScreen'

export const Route = createFileRoute('/app/animals/dogs/profile/$id/$tab')({
  component: () => {
    const { id, tab } = Route.useParams()
    const { navigate } = useRouter()

    const dogProfile = useAnimalProfile(+id)
    const dogMedia = useDogMedia(+id)
    const diagnosticsReport = useDogDiagnosticsReport(+id)
    const orderInfo = useDogOrderInfo(+id)

    const emptyContent = useMemo(() => {
      if (!Object.keys(diagnosticsReport.data ?? {}).length) {
        return <AnimalProfileEmptyState description={orderInfo.orderDescription} orderStatus={orderInfo.orderStatus} />
      }
    }, [orderInfo, diagnosticsReport])

    if (dogProfile.error && !dogProfile.isLoading) {
      throw dogProfile.error
    }

    if (!dogProfile.data) {
      return <LoadingSpinner overlay size={52} />
    }

    if (DOG_PROFILE_SCREEN_TABS.indexOf(tab as any) === -1) {
      return navigate({
        to: '/app/animals/dogs/profile/$id/$tab',
        params: { id: id, tab: 'profile' },
        replace: true,
      })
    }

    return (
      <AnimalProfileProvider
        animalId={+id}
        animalMedia={dogMedia.data}
        animalProfileInfo={dogProfile.data}
        diagnosticsReport={diagnosticsReport.data}
        emptyDiagnosticReportContent={emptyContent}
      >
        <DogProfileScreen animalId={+id} tab={tab as any} />
      </AnimalProfileProvider>
    )
  },
})
