import Cookies from "js-cookie";
import { Environment } from "../../../../config/Environment";

const KEYS = [
  "edxv2_auth_state",
  // boolean to indicate if the visitor has logged in as user before
  "has_authenticated",
];

export const AuthPersistenceService = {
  get: <T>(key: (typeof KEYS)[number]): T | undefined => {
    const value = localStorage.getItem(key);

    try {
      if (value) {
        return JSON.parse(value) as T;
      } else {
        // try to get the value from the cookie
        const cookieValue = Cookies.get(key);

        if (cookieValue) {
          return JSON.parse(cookieValue) as T;
        } else {
          return undefined;
        }
      }
    } catch (e: unknown) {
      if (value) {
        return value as T;
      } else {
        return undefined;
      }
    }
  },

  set: (key: (typeof KEYS)[number], value: unknown): void => {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));

      Environment.COOKIE_DOMAINS.forEach((domain) => {
        try {
          Cookies.set(key, JSON.stringify(value), {
            domain,
            sameSite: "lax",
          });
        } catch (e: unknown) {
          console.error("Failed to set cookie", e);
        }
      });
    } else {
      localStorage.setItem(key, value as string);
    }
  },
};
