import { EdxApiTypes } from '@edx/api'
import { Button, LoadingSpinner } from '@edx/react-common'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { isAxiosError } from 'axios'
import { useMemo } from 'react'
import logo from '../../../assets/etalon_logo_universal.png'
import { AnimalProfileProvider } from '../../../features/animals/contexts/DogProfileContext'
import { useToggleAnimalPrivacy } from '../../../features/animals/hooks/use-toggle-animal-privacy'
import { AnimalsQueryKeys } from '../../../features/animals/query-keys'
import { DogPublicProfileScreen } from '../../../features/animals/screens/public/animal-profile/dog/public'
import { useAuthState } from '../../../features/auth/state/auth-state'
import { edxApi } from '../../../services/edx-api'
export const Route = createFileRoute('/public/animal-profile/dog/$id')({
  component: () => {
    const { user } = useAuthState()
    const { id } = Route.useParams()
    const navigate = useNavigate()

    const { data, error, isFetching } = useQuery({
      queryKey: [AnimalsQueryKeys.AnimalProfile, +id],
      queryFn: () => edxApi.animals.sharedProfileDetail(+id),
    })

    const { toggleProfilePrivacyLoading, togglePublicProfile } = useToggleAnimalPrivacy(+id, data?.data)

    const emptyContent = useMemo(() => {
      if (!Object.keys(data?.data?.diagnosticsReportData || {}).length) {
        return (
          <div className="flex flex-col gap-base items-center w-full py-xxl">
            <div className="text-brand text-lg font-semibold">{`We've`} got something special in store for you.</div>
            <div className="text-surface-stronger font-semibold">
              And we {`can't`} wait for you to see it! Please check back soon.
            </div>
          </div>
        )
      }
    }, [data])

    if (isAxiosError(error)) {
      throw navigate({ to: '/public/not-found' })
    }

    if (!data?.data && isFetching) {
      return <LoadingSpinner overlay size={52} />
    }

    if (!data?.data) {
      throw navigate({ to: '/public/not-found' })
    }

    if (data.data.profileType !== EdxApiTypes.AnimalProfileType.Public) {
      return (
        <div className="flex flex-col gap-base items-center w-full py-xxl bg-surface h-screen">
          <img className="max-w-[300px]" src={logo} alt="Etalon" />
          <div className="flex flex-col gap-base items-center w-full py-xxl">
            <div className="text-brand text-lg font-semibold">This Animal Profile is Private</div>
            {user?.id === data.data.owner?.id ? (
              <div className="flex flex-col gap-base items-center w-full">
                <div className="text-surface-stronger font-semibold max-w-md text-center">
                  You can change this animal profile to be public for sharing with others:
                </div>
                <Button disabled={toggleProfilePrivacyLoading} onClick={togglePublicProfile}>
                  Set Profile to Public
                </Button>
              </div>
            ) : (
              <div className="text-surface-stronger font-semibold">Please check back soon.</div>
            )}
          </div>
        </div>
      )
    }

    return (
      <AnimalProfileProvider
        animalId={+id}
        animalMedia={data?.data?.horseMedia ?? []}
        animalProfileInfo={data?.data}
        diagnosticsReport={data?.data?.diagnosticsReportData ?? undefined}
        emptyDiagnosticReportContent={emptyContent}
      >
        <div className="h-screen w-screen overflow-scroll">
          <DogPublicProfileScreen />
        </div>
      </AnimalProfileProvider>
    )
  },
})
