import { createFileRoute, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  component: function Index() {
    const navigate = useNavigate();
    throw navigate({ to: "/auth" });
  },
});


