import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useLayoutEffect } from 'react'

export const Route = createFileRoute('/app/')({
  component: Index,
})

function Index() {
  const navigate = useNavigate()

  useLayoutEffect(() => {
    navigate({ to: '/app/dashboard' })
  }, [])

  return null
}
