/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PubliclayoutImport } from './routes/public/__layout'
import { Route as ApplayoutImport } from './routes/app/__layout'
import { Route as IndexImport } from './routes/index'
import { Route as AuthIndexImport } from './routes/auth/index'
import { Route as AppIndexImport } from './routes/app/index'
import { Route as PublicNotFoundImport } from './routes/public/not-found'
import { Route as AuthVerifyAccountWithCodeImport } from './routes/auth/verify-account-with-code'
import { Route as AuthVerifyAccountImport } from './routes/auth/verify-account'
import { Route as AuthSetNewPasswordImport } from './routes/auth/set-new-password'
import { Route as AuthRegisterImport } from './routes/auth/register'
import { Route as AuthLogoutImport } from './routes/auth/logout'
import { Route as AuthForgotPasswordImport } from './routes/auth/forgot-password'
import { Route as AppSearchImport } from './routes/app/search'
import { Route as AppDashboardImport } from './routes/app/dashboard'
import { Route as AppMarketplaceIndexImport } from './routes/app/marketplace/index'
import { Route as AppHelpIndexImport } from './routes/app/help/index'
import { Route as AppDnaIndexImport } from './routes/app/dna/index'
import { Route as AppCommunitiesIndexImport } from './routes/app/communities/index'
import { Route as AppAnimalsIndexImport } from './routes/app/animals/index'
import { Route as AppActivateTestIndexImport } from './routes/app/activate-test/index'
import { Route as AppCommunitiesStartACommunityImport } from './routes/app/communities/start-a-community'
import { Route as AppAccountSubscriptionsImport } from './routes/app/account/subscriptions'
import { Route as AppAccountProfileImport } from './routes/app/account/profile'
import { Route as AppAccountPrivacySettingsImport } from './routes/app/account/privacy-settings'
import { Route as AppAccountOrderHistoryImport } from './routes/app/account/order-history'
import { Route as AppAccountNotificationsImport } from './routes/app/account/notifications'
import { Route as AppAccountNotificationSettingsImport } from './routes/app/account/notification-settings'
import { Route as AppMarketplaceDnaTestsIndexImport } from './routes/app/marketplace/dna-tests/index'
import { Route as AppAnimalsHorsesIndexImport } from './routes/app/animals/horses/index'
import { Route as AppAnimalsDogsIndexImport } from './routes/app/animals/dogs/index'
import { Route as PublicAnimalProfileDogIdImport } from './routes/public/animal-profile/dog.$id'
import { Route as AppAnimalsHorsesFahImport } from './routes/app/animals/horses/fah'
import { Route as AppAnimalsHorsesCreateImport } from './routes/app/animals/horses/create'
import { Route as AppAnimalsHorsesBahResultImport } from './routes/app/animals/horses/bah-result'
import { Route as AppAnimalsHorsesBahImport } from './routes/app/animals/horses/bah'
import { Route as AppAnimalsDogsFadImport } from './routes/app/animals/dogs/fad'
import { Route as AppAnimalsDogsCreateImport } from './routes/app/animals/dogs/create'
import { Route as AppAnimalsDogsBadResultImport } from './routes/app/animals/dogs/bad-result'
import { Route as AppAnimalsDogsBadImport } from './routes/app/animals/dogs/bad'
import { Route as AppAnimalsAnimalIdImport } from './routes/app/animals/animal.$id'
import { Route as AppAnimalsHorsesProfileIdImport } from './routes/app/animals/horses/profile.$id'
import { Route as AppAccountOrdersViewOrderIdImport } from './routes/app/account/orders/view-order.$id'
import { Route as AppAnimalsDogsProfileIdTabImport } from './routes/app/animals/dogs/profile/$id.$tab'

// Create/Update Routes

const PubliclayoutRoute = PubliclayoutImport.update({
  id: '/public',
  path: '/public',
  getParentRoute: () => rootRoute,
} as any)

const ApplayoutRoute = ApplayoutImport.update({
  id: '/app',
  path: '/app',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/auth/',
  path: '/auth/',
  getParentRoute: () => rootRoute,
} as any)

const AppIndexRoute = AppIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ApplayoutRoute,
} as any)

const PublicNotFoundRoute = PublicNotFoundImport.update({
  id: '/not-found',
  path: '/not-found',
  getParentRoute: () => PubliclayoutRoute,
} as any)

const AuthVerifyAccountWithCodeRoute = AuthVerifyAccountWithCodeImport.update({
  id: '/auth/verify-account-with-code',
  path: '/auth/verify-account-with-code',
  getParentRoute: () => rootRoute,
} as any)

const AuthVerifyAccountRoute = AuthVerifyAccountImport.update({
  id: '/auth/verify-account',
  path: '/auth/verify-account',
  getParentRoute: () => rootRoute,
} as any)

const AuthSetNewPasswordRoute = AuthSetNewPasswordImport.update({
  id: '/auth/set-new-password',
  path: '/auth/set-new-password',
  getParentRoute: () => rootRoute,
} as any)

const AuthRegisterRoute = AuthRegisterImport.update({
  id: '/auth/register',
  path: '/auth/register',
  getParentRoute: () => rootRoute,
} as any)

const AuthLogoutRoute = AuthLogoutImport.update({
  id: '/auth/logout',
  path: '/auth/logout',
  getParentRoute: () => rootRoute,
} as any)

const AuthForgotPasswordRoute = AuthForgotPasswordImport.update({
  id: '/auth/forgot-password',
  path: '/auth/forgot-password',
  getParentRoute: () => rootRoute,
} as any)

const AppSearchRoute = AppSearchImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppDashboardRoute = AppDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppMarketplaceIndexRoute = AppMarketplaceIndexImport.update({
  id: '/marketplace/',
  path: '/marketplace/',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppHelpIndexRoute = AppHelpIndexImport.update({
  id: '/help/',
  path: '/help/',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppDnaIndexRoute = AppDnaIndexImport.update({
  id: '/dna/',
  path: '/dna/',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppCommunitiesIndexRoute = AppCommunitiesIndexImport.update({
  id: '/communities/',
  path: '/communities/',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAnimalsIndexRoute = AppAnimalsIndexImport.update({
  id: '/animals/',
  path: '/animals/',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppActivateTestIndexRoute = AppActivateTestIndexImport.update({
  id: '/activate-test/',
  path: '/activate-test/',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppCommunitiesStartACommunityRoute =
  AppCommunitiesStartACommunityImport.update({
    id: '/communities/start-a-community',
    path: '/communities/start-a-community',
    getParentRoute: () => ApplayoutRoute,
  } as any)

const AppAccountSubscriptionsRoute = AppAccountSubscriptionsImport.update({
  id: '/account/subscriptions',
  path: '/account/subscriptions',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAccountProfileRoute = AppAccountProfileImport.update({
  id: '/account/profile',
  path: '/account/profile',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAccountPrivacySettingsRoute = AppAccountPrivacySettingsImport.update({
  id: '/account/privacy-settings',
  path: '/account/privacy-settings',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAccountOrderHistoryRoute = AppAccountOrderHistoryImport.update({
  id: '/account/order-history',
  path: '/account/order-history',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAccountNotificationsRoute = AppAccountNotificationsImport.update({
  id: '/account/notifications',
  path: '/account/notifications',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAccountNotificationSettingsRoute =
  AppAccountNotificationSettingsImport.update({
    id: '/account/notification-settings',
    path: '/account/notification-settings',
    getParentRoute: () => ApplayoutRoute,
  } as any)

const AppMarketplaceDnaTestsIndexRoute =
  AppMarketplaceDnaTestsIndexImport.update({
    id: '/marketplace/dna-tests/',
    path: '/marketplace/dna-tests/',
    getParentRoute: () => ApplayoutRoute,
  } as any)

const AppAnimalsHorsesIndexRoute = AppAnimalsHorsesIndexImport.update({
  id: '/animals/horses/',
  path: '/animals/horses/',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAnimalsDogsIndexRoute = AppAnimalsDogsIndexImport.update({
  id: '/animals/dogs/',
  path: '/animals/dogs/',
  getParentRoute: () => ApplayoutRoute,
} as any)

const PublicAnimalProfileDogIdRoute = PublicAnimalProfileDogIdImport.update({
  id: '/animal-profile/dog/$id',
  path: '/animal-profile/dog/$id',
  getParentRoute: () => PubliclayoutRoute,
} as any)

const AppAnimalsHorsesFahRoute = AppAnimalsHorsesFahImport.update({
  id: '/animals/horses/fah',
  path: '/animals/horses/fah',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAnimalsHorsesCreateRoute = AppAnimalsHorsesCreateImport.update({
  id: '/animals/horses/create',
  path: '/animals/horses/create',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAnimalsHorsesBahResultRoute = AppAnimalsHorsesBahResultImport.update({
  id: '/animals/horses/bah-result',
  path: '/animals/horses/bah-result',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAnimalsHorsesBahRoute = AppAnimalsHorsesBahImport.update({
  id: '/animals/horses/bah',
  path: '/animals/horses/bah',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAnimalsDogsFadRoute = AppAnimalsDogsFadImport.update({
  id: '/animals/dogs/fad',
  path: '/animals/dogs/fad',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAnimalsDogsCreateRoute = AppAnimalsDogsCreateImport.update({
  id: '/animals/dogs/create',
  path: '/animals/dogs/create',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAnimalsDogsBadResultRoute = AppAnimalsDogsBadResultImport.update({
  id: '/animals/dogs/bad-result',
  path: '/animals/dogs/bad-result',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAnimalsDogsBadRoute = AppAnimalsDogsBadImport.update({
  id: '/animals/dogs/bad',
  path: '/animals/dogs/bad',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAnimalsAnimalIdRoute = AppAnimalsAnimalIdImport.update({
  id: '/animals/animal/$id',
  path: '/animals/animal/$id',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAnimalsHorsesProfileIdRoute = AppAnimalsHorsesProfileIdImport.update({
  id: '/animals/horses/profile/$id',
  path: '/animals/horses/profile/$id',
  getParentRoute: () => ApplayoutRoute,
} as any)

const AppAccountOrdersViewOrderIdRoute =
  AppAccountOrdersViewOrderIdImport.update({
    id: '/account/orders/view-order/$id',
    path: '/account/orders/view-order/$id',
    getParentRoute: () => ApplayoutRoute,
  } as any)

const AppAnimalsDogsProfileIdTabRoute = AppAnimalsDogsProfileIdTabImport.update(
  {
    id: '/animals/dogs/profile/$id/$tab',
    path: '/animals/dogs/profile/$id/$tab',
    getParentRoute: () => ApplayoutRoute,
  } as any,
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/app': {
      id: '/app'
      path: '/app'
      fullPath: '/app'
      preLoaderRoute: typeof ApplayoutImport
      parentRoute: typeof rootRoute
    }
    '/public': {
      id: '/public'
      path: '/public'
      fullPath: '/public'
      preLoaderRoute: typeof PubliclayoutImport
      parentRoute: typeof rootRoute
    }
    '/app/dashboard': {
      id: '/app/dashboard'
      path: '/dashboard'
      fullPath: '/app/dashboard'
      preLoaderRoute: typeof AppDashboardImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/search': {
      id: '/app/search'
      path: '/search'
      fullPath: '/app/search'
      preLoaderRoute: typeof AppSearchImport
      parentRoute: typeof ApplayoutImport
    }
    '/auth/forgot-password': {
      id: '/auth/forgot-password'
      path: '/auth/forgot-password'
      fullPath: '/auth/forgot-password'
      preLoaderRoute: typeof AuthForgotPasswordImport
      parentRoute: typeof rootRoute
    }
    '/auth/logout': {
      id: '/auth/logout'
      path: '/auth/logout'
      fullPath: '/auth/logout'
      preLoaderRoute: typeof AuthLogoutImport
      parentRoute: typeof rootRoute
    }
    '/auth/register': {
      id: '/auth/register'
      path: '/auth/register'
      fullPath: '/auth/register'
      preLoaderRoute: typeof AuthRegisterImport
      parentRoute: typeof rootRoute
    }
    '/auth/set-new-password': {
      id: '/auth/set-new-password'
      path: '/auth/set-new-password'
      fullPath: '/auth/set-new-password'
      preLoaderRoute: typeof AuthSetNewPasswordImport
      parentRoute: typeof rootRoute
    }
    '/auth/verify-account': {
      id: '/auth/verify-account'
      path: '/auth/verify-account'
      fullPath: '/auth/verify-account'
      preLoaderRoute: typeof AuthVerifyAccountImport
      parentRoute: typeof rootRoute
    }
    '/auth/verify-account-with-code': {
      id: '/auth/verify-account-with-code'
      path: '/auth/verify-account-with-code'
      fullPath: '/auth/verify-account-with-code'
      preLoaderRoute: typeof AuthVerifyAccountWithCodeImport
      parentRoute: typeof rootRoute
    }
    '/public/not-found': {
      id: '/public/not-found'
      path: '/not-found'
      fullPath: '/public/not-found'
      preLoaderRoute: typeof PublicNotFoundImport
      parentRoute: typeof PubliclayoutImport
    }
    '/app/': {
      id: '/app/'
      path: '/'
      fullPath: '/app/'
      preLoaderRoute: typeof AppIndexImport
      parentRoute: typeof ApplayoutImport
    }
    '/auth/': {
      id: '/auth/'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof rootRoute
    }
    '/app/account/notification-settings': {
      id: '/app/account/notification-settings'
      path: '/account/notification-settings'
      fullPath: '/app/account/notification-settings'
      preLoaderRoute: typeof AppAccountNotificationSettingsImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/account/notifications': {
      id: '/app/account/notifications'
      path: '/account/notifications'
      fullPath: '/app/account/notifications'
      preLoaderRoute: typeof AppAccountNotificationsImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/account/order-history': {
      id: '/app/account/order-history'
      path: '/account/order-history'
      fullPath: '/app/account/order-history'
      preLoaderRoute: typeof AppAccountOrderHistoryImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/account/privacy-settings': {
      id: '/app/account/privacy-settings'
      path: '/account/privacy-settings'
      fullPath: '/app/account/privacy-settings'
      preLoaderRoute: typeof AppAccountPrivacySettingsImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/account/profile': {
      id: '/app/account/profile'
      path: '/account/profile'
      fullPath: '/app/account/profile'
      preLoaderRoute: typeof AppAccountProfileImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/account/subscriptions': {
      id: '/app/account/subscriptions'
      path: '/account/subscriptions'
      fullPath: '/app/account/subscriptions'
      preLoaderRoute: typeof AppAccountSubscriptionsImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/communities/start-a-community': {
      id: '/app/communities/start-a-community'
      path: '/communities/start-a-community'
      fullPath: '/app/communities/start-a-community'
      preLoaderRoute: typeof AppCommunitiesStartACommunityImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/activate-test/': {
      id: '/app/activate-test/'
      path: '/activate-test'
      fullPath: '/app/activate-test'
      preLoaderRoute: typeof AppActivateTestIndexImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/': {
      id: '/app/animals/'
      path: '/animals'
      fullPath: '/app/animals'
      preLoaderRoute: typeof AppAnimalsIndexImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/communities/': {
      id: '/app/communities/'
      path: '/communities'
      fullPath: '/app/communities'
      preLoaderRoute: typeof AppCommunitiesIndexImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/dna/': {
      id: '/app/dna/'
      path: '/dna'
      fullPath: '/app/dna'
      preLoaderRoute: typeof AppDnaIndexImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/help/': {
      id: '/app/help/'
      path: '/help'
      fullPath: '/app/help'
      preLoaderRoute: typeof AppHelpIndexImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/marketplace/': {
      id: '/app/marketplace/'
      path: '/marketplace'
      fullPath: '/app/marketplace'
      preLoaderRoute: typeof AppMarketplaceIndexImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/animal/$id': {
      id: '/app/animals/animal/$id'
      path: '/animals/animal/$id'
      fullPath: '/app/animals/animal/$id'
      preLoaderRoute: typeof AppAnimalsAnimalIdImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/dogs/bad': {
      id: '/app/animals/dogs/bad'
      path: '/animals/dogs/bad'
      fullPath: '/app/animals/dogs/bad'
      preLoaderRoute: typeof AppAnimalsDogsBadImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/dogs/bad-result': {
      id: '/app/animals/dogs/bad-result'
      path: '/animals/dogs/bad-result'
      fullPath: '/app/animals/dogs/bad-result'
      preLoaderRoute: typeof AppAnimalsDogsBadResultImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/dogs/create': {
      id: '/app/animals/dogs/create'
      path: '/animals/dogs/create'
      fullPath: '/app/animals/dogs/create'
      preLoaderRoute: typeof AppAnimalsDogsCreateImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/dogs/fad': {
      id: '/app/animals/dogs/fad'
      path: '/animals/dogs/fad'
      fullPath: '/app/animals/dogs/fad'
      preLoaderRoute: typeof AppAnimalsDogsFadImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/horses/bah': {
      id: '/app/animals/horses/bah'
      path: '/animals/horses/bah'
      fullPath: '/app/animals/horses/bah'
      preLoaderRoute: typeof AppAnimalsHorsesBahImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/horses/bah-result': {
      id: '/app/animals/horses/bah-result'
      path: '/animals/horses/bah-result'
      fullPath: '/app/animals/horses/bah-result'
      preLoaderRoute: typeof AppAnimalsHorsesBahResultImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/horses/create': {
      id: '/app/animals/horses/create'
      path: '/animals/horses/create'
      fullPath: '/app/animals/horses/create'
      preLoaderRoute: typeof AppAnimalsHorsesCreateImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/horses/fah': {
      id: '/app/animals/horses/fah'
      path: '/animals/horses/fah'
      fullPath: '/app/animals/horses/fah'
      preLoaderRoute: typeof AppAnimalsHorsesFahImport
      parentRoute: typeof ApplayoutImport
    }
    '/public/animal-profile/dog/$id': {
      id: '/public/animal-profile/dog/$id'
      path: '/animal-profile/dog/$id'
      fullPath: '/public/animal-profile/dog/$id'
      preLoaderRoute: typeof PublicAnimalProfileDogIdImport
      parentRoute: typeof PubliclayoutImport
    }
    '/app/animals/dogs/': {
      id: '/app/animals/dogs/'
      path: '/animals/dogs'
      fullPath: '/app/animals/dogs'
      preLoaderRoute: typeof AppAnimalsDogsIndexImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/horses/': {
      id: '/app/animals/horses/'
      path: '/animals/horses'
      fullPath: '/app/animals/horses'
      preLoaderRoute: typeof AppAnimalsHorsesIndexImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/marketplace/dna-tests/': {
      id: '/app/marketplace/dna-tests/'
      path: '/marketplace/dna-tests'
      fullPath: '/app/marketplace/dna-tests'
      preLoaderRoute: typeof AppMarketplaceDnaTestsIndexImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/account/orders/view-order/$id': {
      id: '/app/account/orders/view-order/$id'
      path: '/account/orders/view-order/$id'
      fullPath: '/app/account/orders/view-order/$id'
      preLoaderRoute: typeof AppAccountOrdersViewOrderIdImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/horses/profile/$id': {
      id: '/app/animals/horses/profile/$id'
      path: '/animals/horses/profile/$id'
      fullPath: '/app/animals/horses/profile/$id'
      preLoaderRoute: typeof AppAnimalsHorsesProfileIdImport
      parentRoute: typeof ApplayoutImport
    }
    '/app/animals/dogs/profile/$id/$tab': {
      id: '/app/animals/dogs/profile/$id/$tab'
      path: '/animals/dogs/profile/$id/$tab'
      fullPath: '/app/animals/dogs/profile/$id/$tab'
      preLoaderRoute: typeof AppAnimalsDogsProfileIdTabImport
      parentRoute: typeof ApplayoutImport
    }
  }
}

// Create and export the route tree

interface ApplayoutRouteChildren {
  AppDashboardRoute: typeof AppDashboardRoute
  AppSearchRoute: typeof AppSearchRoute
  AppIndexRoute: typeof AppIndexRoute
  AppAccountNotificationSettingsRoute: typeof AppAccountNotificationSettingsRoute
  AppAccountNotificationsRoute: typeof AppAccountNotificationsRoute
  AppAccountOrderHistoryRoute: typeof AppAccountOrderHistoryRoute
  AppAccountPrivacySettingsRoute: typeof AppAccountPrivacySettingsRoute
  AppAccountProfileRoute: typeof AppAccountProfileRoute
  AppAccountSubscriptionsRoute: typeof AppAccountSubscriptionsRoute
  AppCommunitiesStartACommunityRoute: typeof AppCommunitiesStartACommunityRoute
  AppActivateTestIndexRoute: typeof AppActivateTestIndexRoute
  AppAnimalsIndexRoute: typeof AppAnimalsIndexRoute
  AppCommunitiesIndexRoute: typeof AppCommunitiesIndexRoute
  AppDnaIndexRoute: typeof AppDnaIndexRoute
  AppHelpIndexRoute: typeof AppHelpIndexRoute
  AppMarketplaceIndexRoute: typeof AppMarketplaceIndexRoute
  AppAnimalsAnimalIdRoute: typeof AppAnimalsAnimalIdRoute
  AppAnimalsDogsBadRoute: typeof AppAnimalsDogsBadRoute
  AppAnimalsDogsBadResultRoute: typeof AppAnimalsDogsBadResultRoute
  AppAnimalsDogsCreateRoute: typeof AppAnimalsDogsCreateRoute
  AppAnimalsDogsFadRoute: typeof AppAnimalsDogsFadRoute
  AppAnimalsHorsesBahRoute: typeof AppAnimalsHorsesBahRoute
  AppAnimalsHorsesBahResultRoute: typeof AppAnimalsHorsesBahResultRoute
  AppAnimalsHorsesCreateRoute: typeof AppAnimalsHorsesCreateRoute
  AppAnimalsHorsesFahRoute: typeof AppAnimalsHorsesFahRoute
  AppAnimalsDogsIndexRoute: typeof AppAnimalsDogsIndexRoute
  AppAnimalsHorsesIndexRoute: typeof AppAnimalsHorsesIndexRoute
  AppMarketplaceDnaTestsIndexRoute: typeof AppMarketplaceDnaTestsIndexRoute
  AppAccountOrdersViewOrderIdRoute: typeof AppAccountOrdersViewOrderIdRoute
  AppAnimalsHorsesProfileIdRoute: typeof AppAnimalsHorsesProfileIdRoute
  AppAnimalsDogsProfileIdTabRoute: typeof AppAnimalsDogsProfileIdTabRoute
}

const ApplayoutRouteChildren: ApplayoutRouteChildren = {
  AppDashboardRoute: AppDashboardRoute,
  AppSearchRoute: AppSearchRoute,
  AppIndexRoute: AppIndexRoute,
  AppAccountNotificationSettingsRoute: AppAccountNotificationSettingsRoute,
  AppAccountNotificationsRoute: AppAccountNotificationsRoute,
  AppAccountOrderHistoryRoute: AppAccountOrderHistoryRoute,
  AppAccountPrivacySettingsRoute: AppAccountPrivacySettingsRoute,
  AppAccountProfileRoute: AppAccountProfileRoute,
  AppAccountSubscriptionsRoute: AppAccountSubscriptionsRoute,
  AppCommunitiesStartACommunityRoute: AppCommunitiesStartACommunityRoute,
  AppActivateTestIndexRoute: AppActivateTestIndexRoute,
  AppAnimalsIndexRoute: AppAnimalsIndexRoute,
  AppCommunitiesIndexRoute: AppCommunitiesIndexRoute,
  AppDnaIndexRoute: AppDnaIndexRoute,
  AppHelpIndexRoute: AppHelpIndexRoute,
  AppMarketplaceIndexRoute: AppMarketplaceIndexRoute,
  AppAnimalsAnimalIdRoute: AppAnimalsAnimalIdRoute,
  AppAnimalsDogsBadRoute: AppAnimalsDogsBadRoute,
  AppAnimalsDogsBadResultRoute: AppAnimalsDogsBadResultRoute,
  AppAnimalsDogsCreateRoute: AppAnimalsDogsCreateRoute,
  AppAnimalsDogsFadRoute: AppAnimalsDogsFadRoute,
  AppAnimalsHorsesBahRoute: AppAnimalsHorsesBahRoute,
  AppAnimalsHorsesBahResultRoute: AppAnimalsHorsesBahResultRoute,
  AppAnimalsHorsesCreateRoute: AppAnimalsHorsesCreateRoute,
  AppAnimalsHorsesFahRoute: AppAnimalsHorsesFahRoute,
  AppAnimalsDogsIndexRoute: AppAnimalsDogsIndexRoute,
  AppAnimalsHorsesIndexRoute: AppAnimalsHorsesIndexRoute,
  AppMarketplaceDnaTestsIndexRoute: AppMarketplaceDnaTestsIndexRoute,
  AppAccountOrdersViewOrderIdRoute: AppAccountOrdersViewOrderIdRoute,
  AppAnimalsHorsesProfileIdRoute: AppAnimalsHorsesProfileIdRoute,
  AppAnimalsDogsProfileIdTabRoute: AppAnimalsDogsProfileIdTabRoute,
}

const ApplayoutRouteWithChildren = ApplayoutRoute._addFileChildren(
  ApplayoutRouteChildren,
)

interface PubliclayoutRouteChildren {
  PublicNotFoundRoute: typeof PublicNotFoundRoute
  PublicAnimalProfileDogIdRoute: typeof PublicAnimalProfileDogIdRoute
}

const PubliclayoutRouteChildren: PubliclayoutRouteChildren = {
  PublicNotFoundRoute: PublicNotFoundRoute,
  PublicAnimalProfileDogIdRoute: PublicAnimalProfileDogIdRoute,
}

const PubliclayoutRouteWithChildren = PubliclayoutRoute._addFileChildren(
  PubliclayoutRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/app': typeof ApplayoutRouteWithChildren
  '/public': typeof PubliclayoutRouteWithChildren
  '/app/dashboard': typeof AppDashboardRoute
  '/app/search': typeof AppSearchRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/logout': typeof AuthLogoutRoute
  '/auth/register': typeof AuthRegisterRoute
  '/auth/set-new-password': typeof AuthSetNewPasswordRoute
  '/auth/verify-account': typeof AuthVerifyAccountRoute
  '/auth/verify-account-with-code': typeof AuthVerifyAccountWithCodeRoute
  '/public/not-found': typeof PublicNotFoundRoute
  '/app/': typeof AppIndexRoute
  '/auth': typeof AuthIndexRoute
  '/app/account/notification-settings': typeof AppAccountNotificationSettingsRoute
  '/app/account/notifications': typeof AppAccountNotificationsRoute
  '/app/account/order-history': typeof AppAccountOrderHistoryRoute
  '/app/account/privacy-settings': typeof AppAccountPrivacySettingsRoute
  '/app/account/profile': typeof AppAccountProfileRoute
  '/app/account/subscriptions': typeof AppAccountSubscriptionsRoute
  '/app/communities/start-a-community': typeof AppCommunitiesStartACommunityRoute
  '/app/activate-test': typeof AppActivateTestIndexRoute
  '/app/animals': typeof AppAnimalsIndexRoute
  '/app/communities': typeof AppCommunitiesIndexRoute
  '/app/dna': typeof AppDnaIndexRoute
  '/app/help': typeof AppHelpIndexRoute
  '/app/marketplace': typeof AppMarketplaceIndexRoute
  '/app/animals/animal/$id': typeof AppAnimalsAnimalIdRoute
  '/app/animals/dogs/bad': typeof AppAnimalsDogsBadRoute
  '/app/animals/dogs/bad-result': typeof AppAnimalsDogsBadResultRoute
  '/app/animals/dogs/create': typeof AppAnimalsDogsCreateRoute
  '/app/animals/dogs/fad': typeof AppAnimalsDogsFadRoute
  '/app/animals/horses/bah': typeof AppAnimalsHorsesBahRoute
  '/app/animals/horses/bah-result': typeof AppAnimalsHorsesBahResultRoute
  '/app/animals/horses/create': typeof AppAnimalsHorsesCreateRoute
  '/app/animals/horses/fah': typeof AppAnimalsHorsesFahRoute
  '/public/animal-profile/dog/$id': typeof PublicAnimalProfileDogIdRoute
  '/app/animals/dogs': typeof AppAnimalsDogsIndexRoute
  '/app/animals/horses': typeof AppAnimalsHorsesIndexRoute
  '/app/marketplace/dna-tests': typeof AppMarketplaceDnaTestsIndexRoute
  '/app/account/orders/view-order/$id': typeof AppAccountOrdersViewOrderIdRoute
  '/app/animals/horses/profile/$id': typeof AppAnimalsHorsesProfileIdRoute
  '/app/animals/dogs/profile/$id/$tab': typeof AppAnimalsDogsProfileIdTabRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/public': typeof PubliclayoutRouteWithChildren
  '/app/dashboard': typeof AppDashboardRoute
  '/app/search': typeof AppSearchRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/logout': typeof AuthLogoutRoute
  '/auth/register': typeof AuthRegisterRoute
  '/auth/set-new-password': typeof AuthSetNewPasswordRoute
  '/auth/verify-account': typeof AuthVerifyAccountRoute
  '/auth/verify-account-with-code': typeof AuthVerifyAccountWithCodeRoute
  '/public/not-found': typeof PublicNotFoundRoute
  '/app': typeof AppIndexRoute
  '/auth': typeof AuthIndexRoute
  '/app/account/notification-settings': typeof AppAccountNotificationSettingsRoute
  '/app/account/notifications': typeof AppAccountNotificationsRoute
  '/app/account/order-history': typeof AppAccountOrderHistoryRoute
  '/app/account/privacy-settings': typeof AppAccountPrivacySettingsRoute
  '/app/account/profile': typeof AppAccountProfileRoute
  '/app/account/subscriptions': typeof AppAccountSubscriptionsRoute
  '/app/communities/start-a-community': typeof AppCommunitiesStartACommunityRoute
  '/app/activate-test': typeof AppActivateTestIndexRoute
  '/app/animals': typeof AppAnimalsIndexRoute
  '/app/communities': typeof AppCommunitiesIndexRoute
  '/app/dna': typeof AppDnaIndexRoute
  '/app/help': typeof AppHelpIndexRoute
  '/app/marketplace': typeof AppMarketplaceIndexRoute
  '/app/animals/animal/$id': typeof AppAnimalsAnimalIdRoute
  '/app/animals/dogs/bad': typeof AppAnimalsDogsBadRoute
  '/app/animals/dogs/bad-result': typeof AppAnimalsDogsBadResultRoute
  '/app/animals/dogs/create': typeof AppAnimalsDogsCreateRoute
  '/app/animals/dogs/fad': typeof AppAnimalsDogsFadRoute
  '/app/animals/horses/bah': typeof AppAnimalsHorsesBahRoute
  '/app/animals/horses/bah-result': typeof AppAnimalsHorsesBahResultRoute
  '/app/animals/horses/create': typeof AppAnimalsHorsesCreateRoute
  '/app/animals/horses/fah': typeof AppAnimalsHorsesFahRoute
  '/public/animal-profile/dog/$id': typeof PublicAnimalProfileDogIdRoute
  '/app/animals/dogs': typeof AppAnimalsDogsIndexRoute
  '/app/animals/horses': typeof AppAnimalsHorsesIndexRoute
  '/app/marketplace/dna-tests': typeof AppMarketplaceDnaTestsIndexRoute
  '/app/account/orders/view-order/$id': typeof AppAccountOrdersViewOrderIdRoute
  '/app/animals/horses/profile/$id': typeof AppAnimalsHorsesProfileIdRoute
  '/app/animals/dogs/profile/$id/$tab': typeof AppAnimalsDogsProfileIdTabRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/app': typeof ApplayoutRouteWithChildren
  '/public': typeof PubliclayoutRouteWithChildren
  '/app/dashboard': typeof AppDashboardRoute
  '/app/search': typeof AppSearchRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/logout': typeof AuthLogoutRoute
  '/auth/register': typeof AuthRegisterRoute
  '/auth/set-new-password': typeof AuthSetNewPasswordRoute
  '/auth/verify-account': typeof AuthVerifyAccountRoute
  '/auth/verify-account-with-code': typeof AuthVerifyAccountWithCodeRoute
  '/public/not-found': typeof PublicNotFoundRoute
  '/app/': typeof AppIndexRoute
  '/auth/': typeof AuthIndexRoute
  '/app/account/notification-settings': typeof AppAccountNotificationSettingsRoute
  '/app/account/notifications': typeof AppAccountNotificationsRoute
  '/app/account/order-history': typeof AppAccountOrderHistoryRoute
  '/app/account/privacy-settings': typeof AppAccountPrivacySettingsRoute
  '/app/account/profile': typeof AppAccountProfileRoute
  '/app/account/subscriptions': typeof AppAccountSubscriptionsRoute
  '/app/communities/start-a-community': typeof AppCommunitiesStartACommunityRoute
  '/app/activate-test/': typeof AppActivateTestIndexRoute
  '/app/animals/': typeof AppAnimalsIndexRoute
  '/app/communities/': typeof AppCommunitiesIndexRoute
  '/app/dna/': typeof AppDnaIndexRoute
  '/app/help/': typeof AppHelpIndexRoute
  '/app/marketplace/': typeof AppMarketplaceIndexRoute
  '/app/animals/animal/$id': typeof AppAnimalsAnimalIdRoute
  '/app/animals/dogs/bad': typeof AppAnimalsDogsBadRoute
  '/app/animals/dogs/bad-result': typeof AppAnimalsDogsBadResultRoute
  '/app/animals/dogs/create': typeof AppAnimalsDogsCreateRoute
  '/app/animals/dogs/fad': typeof AppAnimalsDogsFadRoute
  '/app/animals/horses/bah': typeof AppAnimalsHorsesBahRoute
  '/app/animals/horses/bah-result': typeof AppAnimalsHorsesBahResultRoute
  '/app/animals/horses/create': typeof AppAnimalsHorsesCreateRoute
  '/app/animals/horses/fah': typeof AppAnimalsHorsesFahRoute
  '/public/animal-profile/dog/$id': typeof PublicAnimalProfileDogIdRoute
  '/app/animals/dogs/': typeof AppAnimalsDogsIndexRoute
  '/app/animals/horses/': typeof AppAnimalsHorsesIndexRoute
  '/app/marketplace/dna-tests/': typeof AppMarketplaceDnaTestsIndexRoute
  '/app/account/orders/view-order/$id': typeof AppAccountOrdersViewOrderIdRoute
  '/app/animals/horses/profile/$id': typeof AppAnimalsHorsesProfileIdRoute
  '/app/animals/dogs/profile/$id/$tab': typeof AppAnimalsDogsProfileIdTabRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/app'
    | '/public'
    | '/app/dashboard'
    | '/app/search'
    | '/auth/forgot-password'
    | '/auth/logout'
    | '/auth/register'
    | '/auth/set-new-password'
    | '/auth/verify-account'
    | '/auth/verify-account-with-code'
    | '/public/not-found'
    | '/app/'
    | '/auth'
    | '/app/account/notification-settings'
    | '/app/account/notifications'
    | '/app/account/order-history'
    | '/app/account/privacy-settings'
    | '/app/account/profile'
    | '/app/account/subscriptions'
    | '/app/communities/start-a-community'
    | '/app/activate-test'
    | '/app/animals'
    | '/app/communities'
    | '/app/dna'
    | '/app/help'
    | '/app/marketplace'
    | '/app/animals/animal/$id'
    | '/app/animals/dogs/bad'
    | '/app/animals/dogs/bad-result'
    | '/app/animals/dogs/create'
    | '/app/animals/dogs/fad'
    | '/app/animals/horses/bah'
    | '/app/animals/horses/bah-result'
    | '/app/animals/horses/create'
    | '/app/animals/horses/fah'
    | '/public/animal-profile/dog/$id'
    | '/app/animals/dogs'
    | '/app/animals/horses'
    | '/app/marketplace/dna-tests'
    | '/app/account/orders/view-order/$id'
    | '/app/animals/horses/profile/$id'
    | '/app/animals/dogs/profile/$id/$tab'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/public'
    | '/app/dashboard'
    | '/app/search'
    | '/auth/forgot-password'
    | '/auth/logout'
    | '/auth/register'
    | '/auth/set-new-password'
    | '/auth/verify-account'
    | '/auth/verify-account-with-code'
    | '/public/not-found'
    | '/app'
    | '/auth'
    | '/app/account/notification-settings'
    | '/app/account/notifications'
    | '/app/account/order-history'
    | '/app/account/privacy-settings'
    | '/app/account/profile'
    | '/app/account/subscriptions'
    | '/app/communities/start-a-community'
    | '/app/activate-test'
    | '/app/animals'
    | '/app/communities'
    | '/app/dna'
    | '/app/help'
    | '/app/marketplace'
    | '/app/animals/animal/$id'
    | '/app/animals/dogs/bad'
    | '/app/animals/dogs/bad-result'
    | '/app/animals/dogs/create'
    | '/app/animals/dogs/fad'
    | '/app/animals/horses/bah'
    | '/app/animals/horses/bah-result'
    | '/app/animals/horses/create'
    | '/app/animals/horses/fah'
    | '/public/animal-profile/dog/$id'
    | '/app/animals/dogs'
    | '/app/animals/horses'
    | '/app/marketplace/dna-tests'
    | '/app/account/orders/view-order/$id'
    | '/app/animals/horses/profile/$id'
    | '/app/animals/dogs/profile/$id/$tab'
  id:
    | '__root__'
    | '/'
    | '/app'
    | '/public'
    | '/app/dashboard'
    | '/app/search'
    | '/auth/forgot-password'
    | '/auth/logout'
    | '/auth/register'
    | '/auth/set-new-password'
    | '/auth/verify-account'
    | '/auth/verify-account-with-code'
    | '/public/not-found'
    | '/app/'
    | '/auth/'
    | '/app/account/notification-settings'
    | '/app/account/notifications'
    | '/app/account/order-history'
    | '/app/account/privacy-settings'
    | '/app/account/profile'
    | '/app/account/subscriptions'
    | '/app/communities/start-a-community'
    | '/app/activate-test/'
    | '/app/animals/'
    | '/app/communities/'
    | '/app/dna/'
    | '/app/help/'
    | '/app/marketplace/'
    | '/app/animals/animal/$id'
    | '/app/animals/dogs/bad'
    | '/app/animals/dogs/bad-result'
    | '/app/animals/dogs/create'
    | '/app/animals/dogs/fad'
    | '/app/animals/horses/bah'
    | '/app/animals/horses/bah-result'
    | '/app/animals/horses/create'
    | '/app/animals/horses/fah'
    | '/public/animal-profile/dog/$id'
    | '/app/animals/dogs/'
    | '/app/animals/horses/'
    | '/app/marketplace/dna-tests/'
    | '/app/account/orders/view-order/$id'
    | '/app/animals/horses/profile/$id'
    | '/app/animals/dogs/profile/$id/$tab'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  ApplayoutRoute: typeof ApplayoutRouteWithChildren
  PubliclayoutRoute: typeof PubliclayoutRouteWithChildren
  AuthForgotPasswordRoute: typeof AuthForgotPasswordRoute
  AuthLogoutRoute: typeof AuthLogoutRoute
  AuthRegisterRoute: typeof AuthRegisterRoute
  AuthSetNewPasswordRoute: typeof AuthSetNewPasswordRoute
  AuthVerifyAccountRoute: typeof AuthVerifyAccountRoute
  AuthVerifyAccountWithCodeRoute: typeof AuthVerifyAccountWithCodeRoute
  AuthIndexRoute: typeof AuthIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ApplayoutRoute: ApplayoutRouteWithChildren,
  PubliclayoutRoute: PubliclayoutRouteWithChildren,
  AuthForgotPasswordRoute: AuthForgotPasswordRoute,
  AuthLogoutRoute: AuthLogoutRoute,
  AuthRegisterRoute: AuthRegisterRoute,
  AuthSetNewPasswordRoute: AuthSetNewPasswordRoute,
  AuthVerifyAccountRoute: AuthVerifyAccountRoute,
  AuthVerifyAccountWithCodeRoute: AuthVerifyAccountWithCodeRoute,
  AuthIndexRoute: AuthIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/app",
        "/public",
        "/auth/forgot-password",
        "/auth/logout",
        "/auth/register",
        "/auth/set-new-password",
        "/auth/verify-account",
        "/auth/verify-account-with-code",
        "/auth/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/app": {
      "filePath": "app/__layout.tsx",
      "children": [
        "/app/dashboard",
        "/app/search",
        "/app/",
        "/app/account/notification-settings",
        "/app/account/notifications",
        "/app/account/order-history",
        "/app/account/privacy-settings",
        "/app/account/profile",
        "/app/account/subscriptions",
        "/app/communities/start-a-community",
        "/app/activate-test/",
        "/app/animals/",
        "/app/communities/",
        "/app/dna/",
        "/app/help/",
        "/app/marketplace/",
        "/app/animals/animal/$id",
        "/app/animals/dogs/bad",
        "/app/animals/dogs/bad-result",
        "/app/animals/dogs/create",
        "/app/animals/dogs/fad",
        "/app/animals/horses/bah",
        "/app/animals/horses/bah-result",
        "/app/animals/horses/create",
        "/app/animals/horses/fah",
        "/app/animals/dogs/",
        "/app/animals/horses/",
        "/app/marketplace/dna-tests/",
        "/app/account/orders/view-order/$id",
        "/app/animals/horses/profile/$id",
        "/app/animals/dogs/profile/$id/$tab"
      ]
    },
    "/public": {
      "filePath": "public/__layout.tsx",
      "children": [
        "/public/not-found",
        "/public/animal-profile/dog/$id"
      ]
    },
    "/app/dashboard": {
      "filePath": "app/dashboard.tsx",
      "parent": "/app"
    },
    "/app/search": {
      "filePath": "app/search.tsx",
      "parent": "/app"
    },
    "/auth/forgot-password": {
      "filePath": "auth/forgot-password.tsx"
    },
    "/auth/logout": {
      "filePath": "auth/logout.tsx"
    },
    "/auth/register": {
      "filePath": "auth/register.tsx"
    },
    "/auth/set-new-password": {
      "filePath": "auth/set-new-password.tsx"
    },
    "/auth/verify-account": {
      "filePath": "auth/verify-account.tsx"
    },
    "/auth/verify-account-with-code": {
      "filePath": "auth/verify-account-with-code.tsx"
    },
    "/public/not-found": {
      "filePath": "public/not-found.tsx",
      "parent": "/public"
    },
    "/app/": {
      "filePath": "app/index.tsx",
      "parent": "/app"
    },
    "/auth/": {
      "filePath": "auth/index.tsx"
    },
    "/app/account/notification-settings": {
      "filePath": "app/account/notification-settings.tsx",
      "parent": "/app"
    },
    "/app/account/notifications": {
      "filePath": "app/account/notifications.tsx",
      "parent": "/app"
    },
    "/app/account/order-history": {
      "filePath": "app/account/order-history.tsx",
      "parent": "/app"
    },
    "/app/account/privacy-settings": {
      "filePath": "app/account/privacy-settings.tsx",
      "parent": "/app"
    },
    "/app/account/profile": {
      "filePath": "app/account/profile.tsx",
      "parent": "/app"
    },
    "/app/account/subscriptions": {
      "filePath": "app/account/subscriptions.tsx",
      "parent": "/app"
    },
    "/app/communities/start-a-community": {
      "filePath": "app/communities/start-a-community.tsx",
      "parent": "/app"
    },
    "/app/activate-test/": {
      "filePath": "app/activate-test/index.tsx",
      "parent": "/app"
    },
    "/app/animals/": {
      "filePath": "app/animals/index.tsx",
      "parent": "/app"
    },
    "/app/communities/": {
      "filePath": "app/communities/index.tsx",
      "parent": "/app"
    },
    "/app/dna/": {
      "filePath": "app/dna/index.tsx",
      "parent": "/app"
    },
    "/app/help/": {
      "filePath": "app/help/index.tsx",
      "parent": "/app"
    },
    "/app/marketplace/": {
      "filePath": "app/marketplace/index.tsx",
      "parent": "/app"
    },
    "/app/animals/animal/$id": {
      "filePath": "app/animals/animal.$id.tsx",
      "parent": "/app"
    },
    "/app/animals/dogs/bad": {
      "filePath": "app/animals/dogs/bad.tsx",
      "parent": "/app"
    },
    "/app/animals/dogs/bad-result": {
      "filePath": "app/animals/dogs/bad-result.tsx",
      "parent": "/app"
    },
    "/app/animals/dogs/create": {
      "filePath": "app/animals/dogs/create.tsx",
      "parent": "/app"
    },
    "/app/animals/dogs/fad": {
      "filePath": "app/animals/dogs/fad.tsx",
      "parent": "/app"
    },
    "/app/animals/horses/bah": {
      "filePath": "app/animals/horses/bah.tsx",
      "parent": "/app"
    },
    "/app/animals/horses/bah-result": {
      "filePath": "app/animals/horses/bah-result.tsx",
      "parent": "/app"
    },
    "/app/animals/horses/create": {
      "filePath": "app/animals/horses/create.tsx",
      "parent": "/app"
    },
    "/app/animals/horses/fah": {
      "filePath": "app/animals/horses/fah.tsx",
      "parent": "/app"
    },
    "/public/animal-profile/dog/$id": {
      "filePath": "public/animal-profile/dog.$id.tsx",
      "parent": "/public"
    },
    "/app/animals/dogs/": {
      "filePath": "app/animals/dogs/index.tsx",
      "parent": "/app"
    },
    "/app/animals/horses/": {
      "filePath": "app/animals/horses/index.tsx",
      "parent": "/app"
    },
    "/app/marketplace/dna-tests/": {
      "filePath": "app/marketplace/dna-tests/index.tsx",
      "parent": "/app"
    },
    "/app/account/orders/view-order/$id": {
      "filePath": "app/account/orders/view-order.$id.tsx",
      "parent": "/app"
    },
    "/app/animals/horses/profile/$id": {
      "filePath": "app/animals/horses/profile.$id.tsx",
      "parent": "/app"
    },
    "/app/animals/dogs/profile/$id/$tab": {
      "filePath": "app/animals/dogs/profile/$id.$tab.tsx",
      "parent": "/app"
    }
  }
}
ROUTE_MANIFEST_END */
