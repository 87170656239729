import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useAuthState } from "../../features/auth/state/auth-state";

/**
 * This is a utility route to navigate to for de-authenticating the user.
 */
export const Route = createFileRoute("/auth/logout")({
  component: () => {
    const navigate = useNavigate();
    const { __reset } = useAuthState();
    __reset();
    throw navigate({ to: "/auth" });
  },
});
